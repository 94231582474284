import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import CargoFinishInfoView from './CargoFinishInfoView.js';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

//{stringUtil.language(214)}
 export default class OwnerSettleSummaryActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            targetType:-1,
            targetId:-1,
            sort:0,//0时间，1批号
            startTime:arithUtil.getCurrDateTime(1),
            endTime:arithUtil.getCurrDateTime(1)
        },
        cargoSummSettleDoAll:[],
        targetsAll:[],
        infoShowPop:false,addShowPopIndex:0,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.initData();
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAll = successResponse.data;
                for(let i=0;i<ownersAll.length;i++){
                    this.state.targetsAll.push({targetType:0,targetId:ownersAll[i].id,targetName:ownersAll[i].noteName});
                }
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let workersAll = successResponse.data;
                        for(let i=0;i<workersAll.length;i++){
                            this.state.targetsAll.push({targetType:2,targetId:workersAll[i].id,targetName:workersAll[i].noteName});
                        }
                        stringUtil.httpRequest(urlUtil.BASIC_CARGO_SELF_LINE_URL,null,['key','get',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                let linesAll = successResponse.data;
                                this.state.targetsAll.push({targetType:1,targetId:0,targetName:stringUtil.language(426)+'_'+stringUtil.language(870)});
                                for(let i=0;i<linesAll.length;i++){
                                    this.state.targetsAll.push({targetType:1,targetId:linesAll[i].id,targetName:stringUtil.language(426)+'_'+linesAll[i].name});
                                }
                                this.state.targetsAll.unshift({targetType:2,targetId:0,targetName:stringUtil.language(431)});
                                this.state.targetsAll.unshift({targetType:-1,targetId:-1,targetName:stringUtil.language(425)});
                                this.setState({targetsAll:this.state.targetsAll});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_CARGO_SUMM_SETTLE_NEW_URL,{
            startDate:this.state.searchs.startTime,
            endDate:this.state.searchs.endTime,
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId,
            sort:this.state.searchs.sort,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({cargoSummSettleDoAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(855)}</span>
                            <Select showSearch style={{width:200,height:35}} onChange={(event)=>{
                                this.state.searchs.targetId = JSON.parse(event).targetId;
                                this.state.searchs.targetType = JSON.parse(event).targetType;
                                this.state.searchs.targetName = JSON.parse(event).targetName;
                                this.initData();
                            }}>{/* <option style={{display:'none'}}></option> */}
                            {this.state.targetsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.targetName}</option>)
                            })}
                            </Select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:50}}>{stringUtil.language(904)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.startTime)} onChange={(date) => {
                                this.state.searchs.startTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.endTime)} onChange={(date) => {
                                this.state.searchs.endTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <div style={{justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.state.searchs.sort=0;//时间
                                this.initData();
                            }}>{!this.state.searchs.sort?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1310)}</i>:
                            <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1310)}</i>}</div>
                            <div style={{justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                                this.state.searchs.sort=1;//批次
                                this.initData();
                            }}>{this.state.searchs.sort==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1311)}</i>:
                            <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1311)}</i>}</div>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="12"><b><font size="3">${this.state.searchs.targetId!=-1?this.state.searchs.targetName:stringUtil.language(425)}${stringUtil.language(540)}（${this.state.searchs.startTime}${stringUtil.language(1174)}${this.state.searchs.endTime}）</font></b></td></tr>
                            <tr><td align="center"><b><font size="3">${stringUtil.language(1067)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1049)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1084)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1022)}【${this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(945)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(541)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(946)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(542)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(543)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(544)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(545)}</font></b></td></tr>`);
                            let totalInfo = {saleNumberTotal:0,saleWeightTotal:0,moneyTotalTotal:0,rebateMoneyTotal:0,entrustMoneyTotal:0,disburdenMoneyTotal:0,discountMoneyTotal:0,deductMoneyTotal:0,balanceTotal:0,diffGoodsMoney:0,ownerDeductGoodsMoney:0};
                            for(let key=0;key<(this.state.cargoSummSettleDoAll?this.state.cargoSummSettleDoAll:[]).length;key++){let item=this.state.cargoSummSettleDoAll[key];
                                let moneyTotal = (item.saleGoodsMoney).sub(item.diffGoodsMoney).sub(item.ownerDeductGoodsMoney);
                                for(let i=0;i<item.saleCosts.length;i++){
                                    if(item.saleCosts[i].payee==0){
                                        moneyTotal = moneyTotal.add(item.saleCosts[i].money);
                                    } 
                                }
                                let deductMoney = 0;
                                for(let i=0;i<item.expends.length;i++){
                                    deductMoney = deductMoney.add(item.expends[i].money);
                                }
                                totalInfo = {
                                    saleNumberTotal:(this.state.totalInfo.saleNumberTotal).add(item.saleNumber),
                                    saleWeightTotal:(this.state.totalInfo.saleWeightTotal).add(item.saleWeight),
                                    moneyTotalTotal:(this.state.totalInfo.moneyTotalTotal).add(moneyTotal),
                                    rebateMoneyTotal:(this.state.totalInfo.rebateMoneyTotal).add(item.rebateMoney),
                                    entrustMoneyTotal:(this.state.totalInfo.entrustMoneyTotal).add(item.entrustMoney),
                                    disburdenMoneyTotal:(this.state.totalInfo.disburdenMoneyTotal).add(item.disburdenMoney),
                                    discountMoneyTotal:(this.state.totalInfo.discountMoneyTotal).add(item.discountMoney),
                                    deductMoneyTotal:(this.state.totalInfo.deductMoneyTotal).add(deductMoney),
                                    diffGoodsMoney:(this.state.totalInfo.diffGoodsMoney).add(item.diffGoodsMoney),
                                    ownerDeductGoodsMoney:(this.state.totalInfo.ownerDeductGoodsMoney).add(item.ownerDeductGoodsMoney),
                                    balanceTotal:(this.state.totalInfo.balanceTotal).add(item.balance)
                                }
                                resArr.push(`<tr><td align="left"><font size="3">${arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</font></td>
                                <td align="left"><font size="3">${item.ownerName?item.ownerName:'--'}</font></td>
                                <td align="left"><font size="3">${item.cargoNo}</font></td>
                                <td align="right"><font size="3">${item.saleNumber}</font></td>
                                <td align="right"><font size="3">${arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</font></td>
                                <td align="right"><font size="3">${arithUtil.fenToYuan(moneyTotal)}</font></td>
                                <td align="right"><font size="3">${arithUtil.fenToYuan(item.rebateMoney)}</font></td>
                                <td align="right"><font size="3">${arithUtil.fenToYuan(item.entrustMoney)}</font></td>
                                <td align="right"><font size="3">${arithUtil.fenToYuan(item.disburdenMoney)}</font></td>
                                <td align="right"><font size="3">${arithUtil.fenToYuan(item.discountMoney)}</font></td>
                                <td align="left"><font size="3">${arithUtil.fenToYuan(deductMoney)}</font></td>
                                <td align="left"><font size="3">${arithUtil.fenToYuan(item.balance)}</font></td></tr>`);
                            }
                            resArr.push(`<tr><td align="left" colspan="3"><b><font size="3">${stringUtil.language(890)}</font></b></td>
                            <td align="right"><b><font size="3">${totalInfo.saleNumberTotal}</font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.keToWeight(totalInfo.saleWeightTotal,this.state.weightMode)}</font></b></td>
                            <td align="left"><b><font size="3">${arithUtil.fenToYuan(totalInfo.moneyTotalTotal)}</font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(totalInfo.rebateMoneyTotal)}</font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(totalInfo.entrustMoneyTotal)}</font></b></td>
                            <td align="left"><b><font size="3">${arithUtil.fenToYuan(totalInfo.disburdenMoneyTotal)}</font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(totalInfo.discountMoneyTotal)}</font></b></td>
                            <td align="left"><b><font size="3">${arithUtil.fenToYuan(totalInfo.deductMoneyTotal)}</font></b></td>
                            <td align="left"><b><font size="3">${arithUtil.fenToYuan(totalInfo.balanceTotal)}</font></b></td></tr>`);
                            otherUtil.createExcel(resArr.join(''),(this.state.searchs.targetId!=-1?this.state.searchs.targetName:stringUtil.language(425))+stringUtil.language(540)+'（'+this.state.searchs.startTime+stringUtil.language(1174)+this.state.searchs.endTime+'）');
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>【{this.state.searchs.targetName?this.state.searchs.targetName:stringUtil.language(425)}】{stringUtil.language(214)}</span>
                        <span style={{fontSize:17}}>{this.state.searchs.startTime+' '+stringUtil.language(1174)+' ' +this.state.searchs.endTime}</span>
                    </div>:''}
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'11%'})}>{stringUtil.language(1067)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'15%'})}>{stringUtil.language(1049)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'11%'})}>{stringUtil.language(1084)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1021)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1022)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(945)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(541)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(946)}</span>
                        <span style={styles.tableTitle}>{JSON.parse(localStorage.getItem("currentStallItem")).disburdenText}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(543)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(544)}</span>
                        {/* <span style={styles.tableTitle}>{stringUtil.language(538)}</span> */}
                        <span style={styles.tableTitle}>{stringUtil.language(545)}</span>
                    </div>
                    {(()=>{
                        this.state.totalInfo = {
                            saleNumberTotal:0,saleWeightTotal:0,moneyTotalTotal:0,rebateMoneyTotal:0,ownerDeductGoodsMoney:0,
                            entrustMoneyTotal:0,disburdenMoneyTotal:0,discountMoneyTotal:0,deductMoneyTotal:0,balanceTotal:0,diffGoodsMoney:0
                        }
                    })()}
                    {this.state.cargoSummSettleDoAll.map((item,key)=>{
                        let moneyTotal = (item.saleGoodsMoney).sub(item.diffGoodsMoney).sub(item.ownerDeductGoodsMoney);
                        for(let i=0;i<item.saleCosts.length;i++){
                            if(item.saleCosts[i].payee==0){
                                moneyTotal = moneyTotal.add(item.saleCosts[i].money);
                            } 
                        }
                        let deductMoney = 0;
                        for(let i=0;i<item.expends.length;i++){
                            deductMoney = deductMoney.add(item.expends[i].money);
                        }
                        this.state.totalInfo = {
                            saleNumberTotal:(this.state.totalInfo.saleNumberTotal).add(item.saleNumber),
                            saleWeightTotal:(this.state.totalInfo.saleWeightTotal).add(item.saleWeight),
                            moneyTotalTotal:(this.state.totalInfo.moneyTotalTotal).add(moneyTotal),
                            rebateMoneyTotal:(this.state.totalInfo.rebateMoneyTotal).add(item.rebateMoney),
                            entrustMoneyTotal:(this.state.totalInfo.entrustMoneyTotal).add(item.entrustMoney),
                            disburdenMoneyTotal:(this.state.totalInfo.disburdenMoneyTotal).add(item.disburdenMoney),
                            discountMoneyTotal:(this.state.totalInfo.discountMoneyTotal).add(item.discountMoney),
                            deductMoneyTotal:(this.state.totalInfo.deductMoneyTotal).add(deductMoney),
                            diffGoodsMoney:(this.state.totalInfo.diffGoodsMoney).add(item.diffGoodsMoney),
                            ownerDeductGoodsMoney:(this.state.totalInfo.ownerDeductGoodsMoney).add(item.ownerDeductGoodsMoney),
                            balanceTotal:(this.state.totalInfo.balanceTotal).add(item.balance)
                        }
                        return(<div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                            this.state.itemTemp={id:item.cargoId};
                            this.setState({addShowPopIndex:this.state.addShowPopIndex+1,infoShowPop:!this.state.infoShowPop});
                        }}><span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%',textAlign:'left'})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',textAlign:'left',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'})}>{item.ownerName?item.ownerName:'--'}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%',textAlign:'left'})}>{item.cargoNo}</span>
                            <span style={styles.tableTd}>{item.saleNumber}</span>
                            <span style={styles.tableTd}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(moneyTotal)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.rebateMoney)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.entrustMoney)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.disburdenMoney)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.discountMoney)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(deductMoney)}</span>
                            {/* <span style={styles.tableTd}>{arithUtil.fenToYuan(item.diffGoodsMoney)}</span> */}
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.balance)}</span>
                        </div>)
                    })}
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'37%',color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{stringUtil.language(890)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{this.state.totalInfo.saleNumberTotal}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.keToWeight(this.state.totalInfo.saleWeightTotal,this.state.weightMode)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.moneyTotalTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.rebateMoneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.entrustMoneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.disburdenMoneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.discountMoneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.deductMoneyTotal)}</span>
                        {/* <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.diffGoodsMoney)}</span> */}
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.balanceTotal)}</span>
                    </div>
                </div>
            </div>
            <MaskModal title={stringUtil.language(419)} visible={this.state.infoShowPop} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{width:'100%',height:'100%',overflow:'auto'}} key={this.state.addShowPopIndex}>
                  <CargoFinishInfoView cargoItem={this.state.itemTemp} context={this}/>
                </div>} closeBnTopEvents={() => {this.setState({infoShowPop:false})}}/>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    tableTitle:{
        width:'11%',
        height:arithUtil.pxToDp(30),
        fontSize:12,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    tableTd:{
        width:'11%',
        height:arithUtil.pxToDp(30),
        fontSize:12,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};