import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import * as printUtil from '../utils/printUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import CustomerAddActivityView from './CustomerAddActivityView';

 export default class WholesaleUpdateActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        wholesaleInsertVoItem:{
            totalNumber:0,
            totalWeight:0,
            totalGoodsMoney:0,
            totalOtherMoneyTotal:0,
            advanceMoney:0,
            costs:[],
            commodities:[],
            describe:'',
            money:0
        },
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        workerHabitInfo:{},
        customerItemAll:[],
        warehouseCommodityItemAll:[],
        warehouseCommodityItemData:[],
        commodityTypesAll:[],
        wholesaleCargosData:[],
        customerAddShowPop:false,addShowPopIndex:0,
        costsShowPop:false,
        vegEditShow:false,
        currEditProps:'number',
        keySearch:'',//{stringUtil.language(1063)}{stringUtil.language(1034)}关键字
        currShopCommodityTypeItem:{id:-1,name:stringUtil.language(1097)},
        currCargoItem:{id:-1,cargoMode:0,targetName:stringUtil.language(517),cargoNo:stringUtil.language(571)},
        currWholesaleCommodityVo:{},//当前正在{stringUtil.language(1092)}的待{stringUtil.language(1037)}{stringUtil.language(1063)}
        currWholesaleCommodityVoKey:-1,
        keyTagVal:-1,
      };
      this.state.wholesaleInsertVoItem = (this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage).wholesaleInfoItemInfo;
      for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
            let commoditiesData = this.state.wholesaleInsertVoItem.commodities[i];
          for(let j=0;j<commoditiesData.costs.length;j++){
              for(let k=0;k<commoditiesData.commodityCosts.length;k++){
                if (commoditiesData.costs[j].costClauseId==commoditiesData.commodityCosts[k].costClauseId) {
                    if(this.state.wholesaleInsertVoItem.commodities[i].costs[j].isEdit){
                        this.state.wholesaleInsertVoItem.commodities[i].costs[j].moneyScal = this.state.wholesaleInsertVoItem.commodities[i].costs[j].money;
                    }else{this.state.wholesaleInsertVoItem.commodities[i].costs[j].moneyScal = commoditiesData.commodityCosts[k].money;}
                }
              }
          }
      }
      for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
            let costsItem = this.state.wholesaleInsertVoItem.costs[i];
            this.state.wholesaleInsertVoItem.costs[i] = {
                costClauseCalMode:costsItem.calMode,
                costClauseId:costsItem.costClauseId,
                costClauseName:costsItem.costClauseName,
                costClausePayee:costsItem.payee,
                moneyScal:0,
                money:costsItem.money
            };
      }
      this.state.isPrintTag=this.state.wholesaleInsertVoItem.customerType==1?0:(localStorage.getItem("printShareInfo")&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?1:0);
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workerHabitInfo = successResponse.data;
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initCustomers();
        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.commodityTypesAll= successResponse.data;
                let allItem = {id:-1,name:stringUtil.language(1097)};
                this.state.commodityTypesAll.unshift(allItem);
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:-1,currPage:0,pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownerCargosResultObj= successResponse.data;
                this.state.wholesaleCargosData = ownerCargosResultObj.items;
                this.state.wholesaleCargosData.unshift({id:-1,cargoMode:0,targetName:stringUtil.language(517),cargoNo:stringUtil.language(571)});
                for(let i=0;i<this.state.wholesaleCargosData.length;i++){
                    if(this.state.wholesaleInsertVoItem.cargoId==this.state.wholesaleCargosData[i].id){
                        this.state.currCargoItem = JSON.parse(JSON.stringify(this.state.wholesaleCargosData[i]));
                        this.warehouseCommodityItemSearch();
                        break;
                    }
                }
                this.setState({wholesaleCargosData: this.state.wholesaleCargosData});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehouseCommodityItemAll= successResponse.data;
                this.warehouseCommodityItemSearch();
                this.setState({warehouseCommodityItemAll:this.state.warehouseCommodityItemAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SALE_COST_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let resultVal = JSON.parse(JSON.stringify(successResponse.data));
                resultVal.forEach((item, index) => {
                    for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                        if (this.state.wholesaleInsertVoItem.costs[i].costClauseId==item.costClauseId) {
                            this.state.wholesaleInsertVoItem.costs[i].moneyScal = item.money;
                        }
                    }
                })
                this.setState({wholesaleInsertVoItem: this.state.wholesaleInsertVoItem});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initCustomers(){
        stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let customersData = successResponse.data;
                this.state.customerItemAll = [];
                for(let i=0;i<customersData.length;i++){
                    if (customersData[i].isDel==0) {
                        this.state.customerItemAll.push(customersData[i]);
                    }
                }
                this.searchCustomers();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchCustomers(){
        this.state.customerItemDatas = [];
        if (this.state.keySearch!=null&&this.state.keySearch!='') {
            for(let i=0;i<this.state.customerItemAll.length;i++){
                if ((this.state.customerItemAll[i].noteName).indexOf(this.state.keySearch)!=-1) {
                    this.state.customerItemDatas.push(this.state.customerItemAll[i]);
                }
            }
        }else{this.state.customerItemDatas = this.state.customerItemAll;}
        this.setState({customerItemDatas:this.state.customerItemDatas});
    }
    warehouseCommodityItemSearch(){
        this.state.warehouseCommodityItemData = [];
        for(let i=0;i<this.state.warehouseCommodityItemAll.length;i++){
            let commodityItem = this.state.warehouseCommodityItemAll[i];
            if (this.state.currCargoItem.id!=-1 && commodityItem.cargoId!=this.state.currCargoItem.id) continue;
            if (this.state.currShopCommodityTypeItem.id!=-1 && commodityItem.commodityTypeId!=this.state.currShopCommodityTypeItem.id) continue;
            let pys=stringUtil.makePy((commodityItem.commodityName).trim());let isPinYinHava=0;
            if(pys&&pys.length>0)for(let j=0;j<pys.length;j++){
                if(pys[j].indexOf((this.state.keySearch?this.state.keySearch:'').trim().toUpperCase())!=-1){
                    isPinYinHava=1;break;
                }
            }
            if (commodityItem.commodityName.indexOf(this.state.keySearch)==-1&&(commodityItem.commodityCode?commodityItem.commodityCode:'').indexOf(this.state.keySearch)==-1&&!isPinYinHava) continue;
            this.state.warehouseCommodityItemData.push(commodityItem);
        }
    }

    render(){
        this.state.wholesaleInsertVoItem.totalNumber = 0;
        this.state.wholesaleInsertVoItem.totalWeight = 0;
        this.state.wholesaleInsertVoItem.totalGoodsMoney = 0;
        this.state.wholesaleInsertVoItem.totalOtherMoneyTotal = 0;
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <div style={{height:'6%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',backgroundColor:'#ECECEC'}}>
                <TabTopActivity viewHeight='100%' viewWidth='50%' context={this} navigationStacks={this.props.navigationStacks} isBackPrevPage={1}/>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingRight:arithUtil.pxToDp(15)}}>
                    <span style={{height:'100%',color:'#D2855D',textAlign:'right',textAlignVertical:'center'}}>{arithUtil.getCurrDateTime()}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>{stringUtil.language(892)}{stringUtil.subStr(this.state.wholesaleInsertVoItem.warehouseName,15)}</span>
                </div>
            </div>
            <div style={{height:'94%',display:'flex',flexDirection:'row',backgroundColor:'#FFF'}}>
                <div style={{width:'35%',backgroundColor:'#FFF',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                    <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:20,fontWeight:'bold'}}>{this.state.wholesaleInsertVoItem.customerName}</span>
                            <span style={{color:'#F00',fontSize:12}}>  {this.state.wholesaleInsertVoItem.customerWarning!=0?stringUtil.language(1066)+arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.customerQuota)+'，'+stringUtil.language(1164)+arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.customerArrears):''}</span>
                        </div>
                        <span style={{height:'40px',color:'#2B8D53',fontSize:20,lineHeight:'40px',textDecorationLine:'underline',textDecorationColor:'#2B8D53',cursor:'pointer'}} onClick={()=>{ this.setState({selectCustomerShowPop: true}); }}>{stringUtil.language(1014)}</span>
                    </div>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                        <div style={{width:'82.5%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'37%',color:'#A9A9A9'})}>{stringUtil.language(518)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1021)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1022)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%',color:'#A9A9A9'})}>{stringUtil.language(1050)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1068)}</span>
                        </div>
                        <div style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',height:'100%',color:'#A9A9A9'})}>{stringUtil.language(1069)}</div>
                    </div>
                    <div style={{width:'100%',height:'57%',overflow:'auto'}}>
                        {this.state.wholesaleInsertVoItem.commodities!=null?this.state.wholesaleInsertVoItem.commodities.map((item,key)=>{
                            let otherMoneyTotal = 0;
                            for(let i=0;i<item.costs.length;i++){
                                let costsItem = item.costs[i];
                                if (!costsItem.isEdit&&!costsItem.calMode) {//stringUtil.language(868)
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.number).mul(costsItem.moneyScal).round();
                                }else if (!costsItem.isEdit&&costsItem.calMode==1) {//stringUtil.language(869)
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (arithUtil.keToWeight(item.weight,0)).mul(costsItem.moneyScal).roundDouble();
                                }else if (!costsItem.isEdit&&costsItem.calMode==2) {//stringUtil.language(396)
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = (item.goodsMoney).mul(costsItem.moneyScal).mul(0.01).round();
                                }else{
                                    this.state.wholesaleInsertVoItem.commodities[key].costs[i].money = parseFloat(costsItem.moneyScal).round();
                                }
                                otherMoneyTotal = otherMoneyTotal.add(costsItem.money);
                            }
                            this.state.wholesaleInsertVoItem.totalNumber = (this.state.wholesaleInsertVoItem.totalNumber).add(item.number);
                            this.state.wholesaleInsertVoItem.totalWeight = (this.state.wholesaleInsertVoItem.totalWeight).add(item.weight);
                            this.state.wholesaleInsertVoItem.totalGoodsMoney = (this.state.wholesaleInsertVoItem.totalGoodsMoney).add(item.goodsMoney).round();
                            this.state.wholesaleInsertVoItem.totalOtherMoneyTotal = (this.state.wholesaleInsertVoItem.totalOtherMoneyTotal).add(otherMoneyTotal).round();
                            return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <div style={{width:'82.5%',height:'100%',cursor:'pointer'}} onClick={()=>{
                                    if(item.supplementaryCommodity){ToastBox.error(stringUtil.language(83));return;}
                                    this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(item));
                                    this.state.currWholesaleCommodityVo.totalWeight = arithUtil.keToWeight(this.state.currWholesaleCommodityVo.totalWeight,this.state.weightMode);
                                    this.state.currWholesaleCommodityVo.skinWeight = arithUtil.keToWeight(this.state.currWholesaleCommodityVo.skinWeight,this.state.weightMode);
                                    if(this.state.currWholesaleCommodityVo.mode==1){
                                        this.state.currWholesaleCommodityVo.unitPrice = arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.unitPrice);
                                        this.state.currWholesaleCommodityVo.ownerUnitPrice = arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.ownerUnitPrice);
                                        this.state.currWholesaleCommodityVo.totalUnitPrice = arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.totalUnitPrice);
                                    }else{this.state.currWholesaleCommodityVo.unitPrice = arithUtil.unitPriceTo(arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.unitPrice),0,this.state.weightMode);
                                        this.state.currWholesaleCommodityVo.ownerUnitPrice = arithUtil.unitPriceTo(arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.ownerUnitPrice),0,this.state.weightMode);
                                    }
                                    this.state.currWholesaleCommodityVoKey = key;
                                    this.state.currWholesaleCommodityVo.weight=arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,this.state.weightMode);
                                    this.setState({vegEditShow:true});
                                }}><div style={{height:arithUtil.pxToDp(25),display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'37%',fontSize:14,fontWeight:'bold',textAlign:'left',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'})}>{key+1}.{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?'('+item.commodityCode+')':''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',fontSize:14,fontWeight:'bold'})}>{item.mode==2?item.slaveNumber:item.number}</span>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',fontSize:14,fontWeight:'bold'})}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                        {!item.supplementaryCommodity&&item.mode==0?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%'})}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)}</span>:''}
                                        {!item.supplementaryCommodity&&item.mode?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%'})}>{arithUtil.fenToYuan(item.unitPrice,2)}</span>:''}
                                        {item.supplementaryCommodity?<span style={{width:'14%',color:'#F00',fontSize:12,textAlign:'right'}}>{stringUtil.language(1169)}</span>:''}
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',fontSize:14,fontWeight:'bold'})}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                                    </div>
                                    <div style={{height:arithUtil.pxToDp(15),display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'52%',color:'#52A056',fontSize:10,textAlign:'left'})}>{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,5):''):stringUtil.subStr(item.targetName,5)}/{item.cargoNo}</span>
                                        {item.mode==0?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'51%',color:'#52A056',fontSize:10,textAlign:'left'})}>{arithUtil.keToWeight(item.totalWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.skinWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.reduceWeight,this.state.weightMode)}</span>:
                                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'31%',color:'#52A056',fontSize:10,textAlign:'left'})}>{item.number+'X'+arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>}
                                        <span style={{width:'17%',color:'#52A056',fontSize:10,textAlign:'right'}}>{item.buyerCode?item.buyerCode:''}</span>
                                    </div>
                                </div>
                                <div style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',height:'100%',cursor:'pointer'})} onClick={()=>{
                                    this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(item));
                                    this.setState({vegEditShow:false,costsShowPop:true});
                                }}>{arithUtil.fenToYuan(otherMoneyTotal)}</div>
                                <i className="iconfontIndexCss" style={{width:'7.5%',height:'100%',fontSize:15,color:'#F00',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.wholesaleInsertVoItem.commodities.splice(key, 1);
                                    this.state.vegEditShow = false;
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }}>&#xe612;</i>
                            </div>)
                        }):''}
                    </div>
                    <div style={{width:'100%',height:'36%'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'35.5%',color:'#D2855D',textAlign:'left'})}>{stringUtil.language(890)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13.5%',color:'#D2855D'})}>{this.state.wholesaleInsertVoItem.totalNumber}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.keToWeight(this.state.wholesaleInsertVoItem.totalWeight,this.state.weightMode)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%',color:'#D2855D'})}></span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.totalGoodsMoney)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#D2855D'})}>{arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.totalOtherMoneyTotal)}</span>
                        </div>
                        <div style={{height:'28%',display:'flex',flexDirection:'row'}}>
                            <div style={{width:'70%',height:'100%'}}>
                                {(this.state.wholesaleInsertVoItem.costs!=null&&this.state.wholesaleInsertVoItem.costs.length!=0)?<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                                    {this.state.wholesaleInsertVoItem.costs.map((item,key)=>{
                                        if (item.costClauseCalMode==0) {//stringUtil.language(868)
                                            this.state.wholesaleInsertVoItem.costs[key].money = (this.state.wholesaleInsertVoItem.totalNumber).mul(item.moneyScal).round();
                                        }else if (item.costClauseCalMode==1) {//stringUtil.language(869)
                                            this.state.wholesaleInsertVoItem.costs[key].money = (arithUtil.keToWeight(this.state.wholesaleInsertVoItem.totalWeight,0)).mul(item.moneyScal).roundDouble();
                                        }else if (item.costClauseCalMode==2) {//stringUtil.language(396)
                                            this.state.wholesaleInsertVoItem.costs[key].money = (this.state.wholesaleInsertVoItem.totalGoodsMoney).mul(item.moneyScal).mul(0.01).round();
                                        }else{
                                            this.state.wholesaleInsertVoItem.costs[key].money = parseFloat(item.moneyScal).round();
                                        }
                                        return(<div style={{width:'45%',height:30,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:2}} key={key}>
                                            <span style={{color:'#808080',fontSize:16}}>{stringUtil.subStr(item.costClauseName,5)}：</span>
                                            {item.costClauseCalMode==0?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*{stringUtil.language(1021)}*/}</span></div>:
                                            item.costClauseCalMode==1?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*{stringUtil.language(1022)}*/}</span></div>:
                                            item.costClauseCalMode==2?<div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(355)*/}</span></div>:
                                            <div style={{width:'40%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <span style={{width:'100%',fontSize:16}}>{arithUtil.fenToYuan(item.money)/*stringUtil.language(1029)*/}</span></div>}
                                        </div>);
                                    })}
                                </div>
                                </div>:<div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}><span style={{color:'#DCDCDC'}}>{stringUtil.language(52)}</span></div>}
                            </div>
                            <div style={{width:'30%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                {(()=>{this.state.yakuangxinxi={number:0,money:0};
                                    for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                                        if(this.state.wholesaleInsertVoItem.commodities[i].systemCommodityId==-1){
                                            this.state.yakuangxinxi.number=this.state.yakuangxinxi.number+this.state.wholesaleInsertVoItem.commodities[i].number;
                                            this.state.yakuangxinxi.money=(this.state.yakuangxinxi.money).add(this.state.wholesaleInsertVoItem.commodities[i].goodsMoney);
                                        }
                                    }
                                })()}
                                {this.state.yakuangxinxi.number?<span style={{fontSize:14}}>{stringUtil.language(1157)}:{this.state.yakuangxinxi.number}个,{arithUtil.fenToYuan(this.state.yakuangxinxi.money)}{stringUtil.currency(1)}</span>:''}
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{stringUtil.language(1161)}:</span><input style={arithUtil.stylesAdd(styles.textInput,{width:'50px',height:25})} type='number' defaultValue={this.state.wholesaleInsertVoItem.advanceMoney?arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.advanceMoney):''} placeholder='0' onChange={(event)=>{
                                        this.state.wholesaleInsertVoItem.advanceMoney = event.target.value?arithUtil.yuanToFen(event.target.value):0;
                                        this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{stringUtil.language(858)}</span>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(170),height:arithUtil.pxToDp(25),fontSize:10,padding:0,borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',textAlign:'left'})} defaultValue={this.state.wholesaleInsertVoItem.describe} placeholder={stringUtil.language(12)} onChange={(event)=>{
                                    this.state.wholesaleInsertVoItem.describe = event.target.value;
                                    this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                }}/>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(7)}}>
                                <span style={{fontSize:16}}>{stringUtil.language(880)}</span>
                                {(()=>{
                                    let totalGoodsMoneyVal = this.state.wholesaleInsertVoItem.totalGoodsMoney;
                                    let totalOtherMoneyTotalVal = this.state.wholesaleInsertVoItem.totalOtherMoneyTotal;
                                    let advanceMoneyVal = this.state.wholesaleInsertVoItem.advanceMoney;
                                    let costsMoneyVal = 0;
                                    if ((this.state.wholesaleInsertVoItem.costs!=null&&this.state.wholesaleInsertVoItem.costs.length)>0) {
                                        for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                                            let costsItemMoney = this.state.wholesaleInsertVoItem.costs[i].money;
                                            costsMoneyVal = costsMoneyVal.add(costsItemMoney);
                                        }
                                    }
                                    this.state.wholesaleInsertVoItem.money = totalGoodsMoneyVal.add(totalOtherMoneyTotalVal).add(advanceMoneyVal).add(costsMoneyVal).round();
                                })()}
                                <span style={{color:'#F00',fontSize:23,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesaleInsertVoItem.money)}</span>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:arithUtil.pxToDp(5)}}>
                            <span style={{width:arithUtil.pxToDp(130),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#2B8D53',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    if (this.state.wholesaleInsertVoItem.commodities.length==0) {
                                        ToastBox.error(stringUtil.language(190));return;
                                    }
                                    for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                                        let commodityItem = this.state.wholesaleInsertVoItem.commodities[i];
                                        if (commodityItem.unitPrice==0) {
                                            ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1011)+'！');return;
                                        }
                                        if (commodityItem.mode==0&&commodityItem.weight<=0) {
                                            ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(1012)+'！');return;
                                        }
                                        if (commodityItem.mode==1&&commodityItem.number<=0) {
                                            ToastBox.error(stringUtil.language(1010)+'“'+commodityItem.commodityName+'”'+stringUtil.language(560));return;
                                        }
                                    }
                                    let wholesaleInsertVo = {costs:[],commodities:[]};
                                    wholesaleInsertVo.customerId = this.state.wholesaleInsertVoItem.customerId;
                                    wholesaleInsertVo.advanceMoney = this.state.wholesaleInsertVoItem.advanceMoney;
                                    wholesaleInsertVo.money = this.state.wholesaleInsertVoItem.money;
                                    wholesaleInsertVo.describe = this.state.wholesaleInsertVoItem.describe;
                                    for(let i=0;i<this.state.wholesaleInsertVoItem.costs.length;i++){
                                        let costsTemp = {money:0};
                                        costsTemp.costClauseId = this.state.wholesaleInsertVoItem.costs[i].costClauseId;
                                        costsTemp.money = this.state.wholesaleInsertVoItem.costs[i].money;
                                        wholesaleInsertVo.costs.push(costsTemp);
                                    }
                                    for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                                        let commodityTemp = {costs:[]};
                                        commodityTemp.supplementaryCommodity = this.state.wholesaleInsertVoItem.commodities[i].supplementaryCommodity?1:0;
                                        commodityTemp.cargoId = this.state.wholesaleInsertVoItem.commodities[i].cargoId;
                                        commodityTemp.buyerCode = this.state.wholesaleInsertVoItem.commodities[i].buyerCode;
                                        commodityTemp.targetType = this.state.wholesaleInsertVoItem.commodities[i].targetType;
                                        commodityTemp.targetId = this.state.wholesaleInsertVoItem.commodities[i].targetId;
                                        commodityTemp.commodityId = this.state.wholesaleInsertVoItem.commodities[i].commodityId;
                                        commodityTemp.cargoCommodityId = this.state.wholesaleInsertVoItem.commodities[i].cargoCommodityId;
                                        commodityTemp.standard = this.state.wholesaleInsertVoItem.commodities[i].standard;
                                        commodityTemp.number = this.state.wholesaleInsertVoItem.commodities[i].number;
                                        commodityTemp.totalWeight = this.state.wholesaleInsertVoItem.commodities[i].totalWeight;
                                        commodityTemp.skinWeight = this.state.wholesaleInsertVoItem.commodities[i].skinWeight;
                                        commodityTemp.reduceWeight = this.state.wholesaleInsertVoItem.commodities[i].reduceWeight;
                                        commodityTemp.weight = this.state.wholesaleInsertVoItem.commodities[i].weight;
                                        commodityTemp.unitPrice = this.state.wholesaleInsertVoItem.commodities[i].unitPrice;
                                        commodityTemp.ownerUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice?this.state.wholesaleInsertVoItem.commodities[i].ownerUnitPrice:0;
                                        commodityTemp.totalUnitPrice = this.state.wholesaleInsertVoItem.commodities[i].totalUnitPrice;
                                        commodityTemp.mode = this.state.wholesaleInsertVoItem.commodities[i].mode;
                                        commodityTemp.goodsMoney = this.state.wholesaleInsertVoItem.commodities[i].goodsMoney;
                                        if (this.state.wholesaleInsertVoItem.commodities[i].costs==null)this.state.wholesaleInsertVoItem.commodities[i].costs=[];
                                        for(let j=0;j<this.state.wholesaleInsertVoItem.commodities[i].costs.length;j++){
                                            let costsSource = JSON.parse(JSON.stringify(this.state.wholesaleInsertVoItem.commodities[i].costs[j]));
                                            commodityTemp.costs.push({
                                                costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                                                isEdit:costsSource.isEdit?1:0,
                                                money:costsSource.money!=null?costsSource.money:0,
                                            });
                                        }
                                        wholesaleInsertVo.commodities.push(commodityTemp);
                                    }
                                    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+this.state.wholesaleInsertVoItem.id,wholesaleInsertVo,['json','put',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            if(localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).printUpdateWholesale&&this.state.isPrintTag!=1)stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+this.state.wholesaleInsertVoItem.id,null,['key','get',1],(successResponse)=>{
                                                if (successResponse.status==200) {
                                                    let printWholesaleInfo = successResponse.data;
                                                    if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||this.state.wholesaleInsertVoItem.customerType!=1) printWholesaleInfo.billMoney=0;
                                                    printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));
                                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                            ToastBox.success(stringUtil.language(388));
                                            this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }}>{stringUtil.language(859)}</span>
                            <span style={{width:arithUtil.pxToDp(130),height:'30px',color:'#808080',lineHeight:'30px',borderRadius:3,backgroundColor:'#DCDCDC',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                                }}>{stringUtil.language(860)}</span>
                        </div>
                    </div>
                </div>
                {/* 右边 */}
                <div style={{width:'65%',backgroundColor:'#F5F3F3',padding:arithUtil.pxToDp(5),position:'relative'}}>
                    {!this.state.wholesaleInsertVoItem.cargoId?<div style={{height:'13%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <i className="iconfontIndexCss" style={{fontSize:30,color:'#21A056'}}>&#xe643;</i>
                        <div style={{width:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}}>
                            {this.state.wholesaleCargosData.map((item,key)=>{
                                return(<div style={{display:'flex',flexDirection:'column',justifyContent:'center',marginLeft:arithUtil.pxToDp(15),cursor:'pointer'}} key={key} onClick={()=>{
                                    this.state.currCargoItem = JSON.parse(JSON.stringify(item));
                                    this.warehouseCommodityItemSearch();
                                    this.setState({currCargoItem:this.state.currCargoItem});
                                }}>
                                    <span style={{color:this.state.currCargoItem.id==item.id?'#52A056':'#A9A9A9',fontSize:16,fontWeight:'bold'}}>{item.cargoMode==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,6):''):stringUtil.subStr(item.targetName,7)}</span>
                                    <span style={{color:this.state.currCargoItem.id==item.id?'#52A056':'#C0C0C0',fontSize:10}}>{item.cargoNo}{stringUtil.language(1176)}</span>
                                </div>)
                            })}
                        </div>
                    </div>:''}
                    <div style={{height:!this.state.wholesaleInsertVoItem.cargoId?'87%':'100%',display:'flex',flexDirection:'row'}}>
                        <div style={{width:'90%',height:'100%',display:'flex',flexDirection:'column'}} >
                            <div style={{width:'100%',display:'flex',flexDirection:'row',flexGrow:1,overflow:'auto'}}>
                            <div style={{width:'100%',height:((this.state.warehouseCommodityItemData.length).div(4,0)).mul(60),display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                                {this.state.warehouseCommodityItemData.map((item,key)=>{
                                    let isHavaChooseObj=null;let isHavaChooseObjKey=-1,chooseKind=0,chooseNumber=0;
                                    for(let i=0;i<this.state.wholesaleInsertVoItem.commodities.length;i++){
                                        let chooseItem = this.state.wholesaleInsertVoItem.commodities[i];
                                        if (chooseItem.cargoCommodityId==item.cargoCommodityId) {
                                            isHavaChooseObj = this.state.wholesaleInsertVoItem.commodities[i];
                                            isHavaChooseObjKey = i;
                                            chooseKind=chooseKind+1;chooseNumber=chooseNumber+chooseItem.number;//break;
                                        }
                                    }
                                    return(<div style={{width:'32.5%',borderStyle:'solid',borderWidth:1,borderColor:'#A9A9A9',borderRadius:5,backgroundColor:'#FFF',padding:3,marginLeft:'0.5%',marginBottom:5,position:'relative'}} key={key}>
                                        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                            let currEditWarehouseCommodityItem = JSON.parse(JSON.stringify(item));
                                            for(let i=0;i<currEditWarehouseCommodityItem.costs.length;i++){
                                                currEditWarehouseCommodityItem.costs[i].moneyScal = currEditWarehouseCommodityItem.costs[i].money;
                                                currEditWarehouseCommodityItem.costs[i].money = 0;
                                            }
                                            this.state.currWholesaleCommodityVo = {
                                                cargoCommodityId:currEditWarehouseCommodityItem.cargoCommodityId,
                                                cargoId:currEditWarehouseCommodityItem.cargoId,
                                                systemCommodityId:currEditWarehouseCommodityItem.systemCommodityId,
                                                commodityId:currEditWarehouseCommodityItem.commodityId,
                                                commodityName:currEditWarehouseCommodityItem.commodityName,
                                                commodityCode:currEditWarehouseCommodityItem.commodityCode,
                                                commoditySpecs:currEditWarehouseCommodityItem.commoditySpecs,
                                                targetType:currEditWarehouseCommodityItem.targetType,
                                                targetId:currEditWarehouseCommodityItem.targetId,
                                                targetName:currEditWarehouseCommodityItem.targetName,
                                                cargoNo:currEditWarehouseCommodityItem.cargoNo,
                                                commodityUnitMaster:currEditWarehouseCommodityItem.unitMaster,
                                                mode:currEditWarehouseCommodityItem.wholesaleMode,//0{stringUtil.language(1022)}，1{stringUtil.language(1021)}
                                                number:0,
                                                totalWeight:0,
                                                skinWeight:0,
                                                weight:0,
                                                unitPrice:currEditWarehouseCommodityItem.wholesaleMode?arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice):arithUtil.unitPriceTo(arithUtil.fenToYuan(currEditWarehouseCommodityItem.weightMakePrice),0,this.state.weightMode),
                                                totalUnitPrice:arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice),
                                                numMakePrice:arithUtil.fenToYuan(currEditWarehouseCommodityItem.numMakePrice),
                                                weightMakePrice:arithUtil.unitPriceTo(arithUtil.fenToYuan(currEditWarehouseCommodityItem.weightMakePrice),0,this.state.weightMode),
                                                standard:currEditWarehouseCommodityItem.standard,
                                                commodityReduce:currEditWarehouseCommodityItem.reduce,
                                                reduceWeight:0,
                                                goodsMoney:0,
                                                costs:currEditWarehouseCommodityItem.costs,
                                            };
                                            this.state.currWholesaleCommodityVoKey = -1;
                                            // if (isHavaChooseObj!=null) {
                                            //     this.state.currWholesaleCommodityVo = JSON.parse(JSON.stringify(isHavaChooseObj));
                                            //     this.state.currWholesaleCommodityVo.totalWeight = arithUtil.keToWeight(this.state.currWholesaleCommodityVo.totalWeight,this.state.weightMode);
                                            //     this.state.currWholesaleCommodityVo.skinWeight = arithUtil.keToWeight(this.state.currWholesaleCommodityVo.skinWeight,this.state.weightMode);
                                            //     if(this.state.currWholesaleCommodityVo.mode==1){
                                            //         this.state.currWholesaleCommodityVo.unitPrice = arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.unitPrice);
                                            //     }else this.state.currWholesaleCommodityVo.unitPrice = arithUtil.unitPriceTo(arithUtil.fenToYuan(this.state.currWholesaleCommodityVo.unitPrice),0,this.state.weightMode);
                                            //     this.state.currWholesaleCommodityVoKey = isHavaChooseObjKey;
                                            // }
                                            this.setState({vegEditShow:true});
                                        }}><div style={{display:'flex',flexDirection:'column',}}>
                                                <div style={{width:50,position:'relative',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                                    <span style={{width:50,height:50,color:'#F00',fontWeight:'bold',textAlign:'center',textOverflow:'ellipsis',overflow:'hidden',backgroundColor:'rgba(255,255,255,0.2)',position:'absolute'}}>{stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}</span>
                                                    {localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showImg?'':<img style={{width:50,height:50}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>}
                                                </div>
                                                <span style={{color:'#F00',fontSize:10}}>{item.number+((item.unitMaster!=null&&item.unitMaster!='')?item.unitMaster:stringUtil.language(1173))}</span>
                                            </div>
                                            <div style={{width:0,flexGrow:1,marginLeft:3,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                                <span style={{color:'#52A056',fontSize:16,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{key+1}.{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:stringUtil.showCode()==1?(item.commodityCode?item.commodityCode:''):''}</span>
                                                <span style={{color:'#808080',fontSize:11,marginTop:2,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commoditySpecs}</span>
                                                <span style={{color:'#A9A9A9',fontSize:11,marginTop:2,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.targetName?stringUtil.subStr(item.targetName,6):item.targetType==1?stringUtil.language(426):''} {stringUtil.language(1237,item.cargoNo)}</span>
                                            </div>
                                        </div>
                                        {/* <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00',position:'absolute',right:0,top:0,paddingTop:3,paddingRight:3,paddingLeft:15,paddingBottom:15,cursor:'pointer'}} onClick={()=>{
                                            this.state.wholesaleInsertVoItem.commodities.splice(isHavaChooseObjKey,1);
                                            this.state.vegEditShow = false;
                                            this.setState({wholesaleInsertVoItem:this.state.wholesaleInsertVoItem});
                                        }}>&#xe612;</i> */}
                                        {isHavaChooseObj!=null?<div style={{height:16,backgroundColor:'rgba(0,0,0,0.3)',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',position:'absolute',right:0,bottom:0,borderBottomRightRadius:5}}>
                                            <span style={{fontSize:10,fontWeight:'bold',color:'#F00'}}>{chooseKind+stringUtil.language(1177)+','+chooseNumber+stringUtil.language(1173)}</span>
                                        </div>:''}
                                    </div>);
                                })}
                            </div>
                            </div>
                            <div style={{borderStyle:'solid',borderColor:'#52A056',borderWidth:1,backgroundColor:'#929292',paddingLeft:7,paddingRight:7,paddingTop:5,marginRight:10}}>
                                <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,backgroundColor:'#FFF',marginBottom:5}}>
                                    <i className="iconfontIndexCss" style={{fontSize:25,color:'#D3D3D3'}}>&#xe62c;</i>
                                    <input style={{height:'100%',display:'flex',flexGrow:1,padding:0}} placeholder={stringUtil.language(32)} onChange={(event)=>{
                                        this.state.keySearch = event.target.value;
                                        this.warehouseCommodityItemSearch();
                                        this.setState({keySearch:this.state.keySearch});
                                    }}/>
                                </div>
                                {/* 右边-{stringUtil.language(1092)}菜品 */}
                                {this.state.vegEditShow?<div style={{height:150}}>
                                    <i className="iconfontIndexCss" style={{fontSize:25,color:'#F00',padding:arithUtil.pxToDp(15),position:'absolute',right:'12%',bottom:110,cursor:'pointer'}} onClick={()=>{this.setState({vegEditShow:false});}}>&#xe631;</i>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                                        <span style={{color:'#000',fontSize:25,fontWeight:'bold'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(this.state.currWholesaleCommodityVo.commodityCode?'('+this.state.currWholesaleCommodityVo.commodityCode+')':''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?this.state.currWholesaleCommodityVo.commodityName:''}{this.state.currWholesaleCommodityVo.commoditySpecs?'('+this.state.currWholesaleCommodityVo.commoditySpecs+')':''}</span>
                                        <span style={{color:'#FFF',fontWeight:'bold'}}>  {this.state.currWholesaleCommodityVo.targetName?this.state.currWholesaleCommodityVo.targetName:this.state.currWholesaleCommodityVo.targetType==1?stringUtil.language(1051):''}/{this.state.currWholesaleCommodityVo.cargoNo}</span>
                                    </div>
                                    {(()=>{
                                        let numberVal = parseFloat(this.state.currWholesaleCommodityVo.number);
                                        // let totalWeightVal = parseFloat(this.state.currWholesaleCommodityVo.totalWeight);
                                        // let skinWeightVal = parseFloat(this.state.currWholesaleCommodityVo.skinWeight);
                                        // let reduceWeightVal = numberVal.mul(this.state.currWholesaleCommodityVo.commodityReduce);//克
                                        // let standardVal = this.state.currWholesaleCommodityVo.standard;//克/stringUtil.language(1173)
                                        // let weightVal = standardVal?numberVal.mul(arithUtil.keToWeight(standardVal,this.state.weightMode)):this.state.currWholesaleCommodityVo.weight,goodsMoneyVal = 0;
                                        // if (this.state.currWholesaleCommodityVo.mode==0) {
                                        //     this.state.currWholesaleCommodityVo.reduceWeight = reduceWeightVal;
                                        //     if (this.state.currEditProps=='numberUpdate'&&this.state.workerHabitInfo.defWeight==0) {
                                        //         this.state.currWholesaleCommodityVo.totalWeight = weightVal.add(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode)).add(skinWeightVal);
                                        //     }else{
                                        //         weightVal = totalWeightVal.sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                        //     }
                                        //     this.state.currEditProps = 'number';
                                        //     goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(weightVal);
                                        // }else{
                                        //     goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(numberVal);
                                        // }
                                        // this.state.currWholesaleCommodityVo.weight = weightVal;
                                        let skinWeightVal = parseFloat(this.state.currWholesaleCommodityVo.skinWeight);
                                        let reduceWeightVal = numberVal.mul(this.state.currWholesaleCommodityVo.commodityReduce);//克
                                        let standardVal = this.state.currWholesaleCommodityVo.standard,goodsMoneyVal = 0;//克/stringUtil.language(1173)
                                        if(standardVal!=null&&standardVal!=0){//有stringUtil.language(1024)情况
                                            if (this.state.currWholesaleCommodityVo.mode==0) {
                                                this.state.currWholesaleCommodityVo.reduceWeight = reduceWeightVal;
                                                if (this.state.currEditProps=='numberUpdate'&&this.state.workerHabitInfo.defWeight==0) {//0stringUtil.language(1024)，1stringUtil.language(1168)
                                                    let totalWeightVal = numberVal.mul(arithUtil.keToWeight(standardVal,this.state.weightMode));
                                                    this.state.currWholesaleCommodityVo.totalWeight = totalWeightVal;
                                                    this.state.currWholesaleCommodityVo.weight=totalWeightVal.sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                }else{
                                                    let totalWeightVal = parseFloat(this.state.currWholesaleCommodityVo.totalWeight);
                                                    this.state.currWholesaleCommodityVo.weight = totalWeightVal.sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                }
                                                this.state.currEditProps = 'number';
                                                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(this.state.currWholesaleCommodityVo.weight);
                                            }else{
                                                let totalWeightVal = numberVal.mul(arithUtil.keToWeight(standardVal,this.state.weightMode));
                                                this.state.currWholesaleCommodityVo.totalWeight = totalWeightVal;
                                                this.state.currWholesaleCommodityVo.weight=totalWeightVal.sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(numberVal);
                                            }
                                        }else{//无stringUtil.language(1024)情况
                                            if (this.state.currWholesaleCommodityVo.mode==0) {
                                                this.state.currWholesaleCommodityVo.reduceWeight = reduceWeightVal;
                                                this.state.currWholesaleCommodityVo.weight=(parseFloat(this.state.currWholesaleCommodityVo.totalWeight)).sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                this.state.currEditProps = 'number';
                                                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(this.state.currWholesaleCommodityVo.weight);
                                            }else{
                                                this.state.currWholesaleCommodityVo.weight=(parseFloat(this.state.currWholesaleCommodityVo.totalWeight)).sub(skinWeightVal).sub(arithUtil.keToWeight(reduceWeightVal,this.state.weightMode));
                                                goodsMoneyVal = (parseFloat(this.state.currWholesaleCommodityVo.unitPrice)).mul(numberVal);
                                            }
                                        }
                                        this.state.currWholesaleCommodityVo.goodsMoney = goodsMoneyVal.round(0);
                                        if(this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
                                            this.state.currWholesaleCommodityVo.totalGoodMoney=(parseFloat(this.state.currWholesaleCommodityVo.totalUnitPrice)).mul(numberVal);
                                        }else{}//{stringUtil.language(1015)}
                                    })()}
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10,color:'#FFF'}} key={this.state.currWholesaleCommodityVo.cargoCommodityId}>
                                        {this.state.currWholesaleCommodityVo.mode==0?<i className="iconfontIndexCss" style={arithUtil.stylesAdd(styles.textText,{width:'10%',color:'#D2855D',cursor:'pointer'})} onClick={()=>{
                                            this.state.currWholesaleCommodityVo.mode = 1;//{stringUtil.language(1021)}
                                            if(this.state.currWholesaleCommodityVo.numMakePrice){this.state.currWholesaleCommodityVo.unitPrice=this.state.currWholesaleCommodityVo.numMakePrice;
                                            this.refs.unitPriceVal.value=this.state.currWholesaleCommodityVo.unitPrice;}
                                            this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                        }}>&#xe6a3; {stringUtil.language(1022)}</i>:<i className="iconfontIndexCss" style={arithUtil.stylesAdd(styles.textText,{width:'10%',color:'#D2855D',cursor:'pointer'})} onClick={()=>{
                                            this.state.currWholesaleCommodityVo.mode = 0;//{stringUtil.language(1022)}
                                            if(this.state.currWholesaleCommodityVo.weightMakePrice){this.state.currWholesaleCommodityVo.unitPrice=this.state.currWholesaleCommodityVo.weightMakePrice;
                                            this.refs.unitPriceVal.value=this.state.currWholesaleCommodityVo.unitPrice;}
                                            this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                        }}>&#xe61a; {stringUtil.language(1021)}</i>}
                                        <input style={arithUtil.stylesAdd(styles.textText,{width:'15%',color:'#696969'})} type='number' defaultValue={this.state.currWholesaleCommodityVo.number==0?'':this.state.currWholesaleCommodityVo.number} placeholder={stringUtil.language(1165)} onChange={(event)=>{
                                            if(event.target.value==null || event.target.value==''){
                                                this.state.currWholesaleCommodityVo.number = 0;
                                                this.state.currEditProps = 'numberUpdate';
                                                this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                            }else if(stringUtil.numberCheck(event.target.value)){
                                                this.state.currWholesaleCommodityVo.number = event.target.value;
                                                this.state.currEditProps = 'numberUpdate';
                                                this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                            }
                                        }}/>&nbsp;{this.state.currWholesaleCommodityVo.commodityUnitMaster}，
                                        {this.state.currWholesaleCommodityVo.mode==0?<div style={{display:'flex',flexDirection:'row',alignItems:'center',color:'#FFF'}}>
                                            {stringUtil.language(1166)}&nbsp;<input style={arithUtil.stylesAdd(styles.textText,{width:'120px',color:'#696969'})} type='number' defaultValue={this.state.currWholesaleCommodityVo.totalWeight==0?'':this.state.currWholesaleCommodityVo.totalWeight} placeholder='0' onChange={(event)=>{
                                                if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                                    this.state.currWholesaleCommodityVo.totalWeight = 0;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }else if(stringUtil.numberCheck(event.target.value)){
                                                    this.state.currWholesaleCommodityVo.totalWeight = event.target.value;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }
                                            }}/>，{stringUtil.language(1167)}&nbsp;<input style={arithUtil.stylesAdd(styles.textText,{width:'80px',color:'#696969'})} type='number' defaultValue={this.state.currWholesaleCommodityVo.skinWeight==0?'':this.state.currWholesaleCommodityVo.skinWeight} placeholder='0' onChange={(event)=>{
                                                if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                                    this.state.currWholesaleCommodityVo.skinWeight = 0;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }else if(stringUtil.numberCheck(event.target.value)){
                                                    this.state.currWholesaleCommodityVo.skinWeight = event.target.value;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }
                                            }}/>，
                                        </div>:''}
                                        {this.state.currWholesaleCommodityVo.mode==1&&!this.state.currWholesaleCommodityVo.standard?<div style={{display:'flex',flexDirection:'row',alignItems:'center',color:'#FFF'}}>
                                            {stringUtil.language(1168)}&nbsp;<input id='netWeightEdit' style={arithUtil.stylesAdd(styles.textText,{width:'120px',color:'#696969'})} type='number' defaultValue={this.state.currWholesaleCommodityVo.weight==0?'':this.state.currWholesaleCommodityVo.weight} placeholder='0' onChange={(event)=>{
                                                if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                                    this.state.currWholesaleCommodityVo.weight = 0;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }else if(stringUtil.numberCheck(event.target.value)){
                                                    this.state.currWholesaleCommodityVo.weight = event.target.value;
                                                    this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                                }
                                            }}/>，
                                        </div>:''}
                                        <input ref='unitPriceVal' style={arithUtil.stylesAdd(styles.textText,{width:'13%',color:'#696969'})} type='number' defaultValue={this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?(this.state.currWholesaleCommodityVo.totalUnitPrice==0?'':this.state.currWholesaleCommodityVo.totalUnitPrice):(this.state.currWholesaleCommodityVo.unitPrice==0?'':this.state.currWholesaleCommodityVo.unitPrice)} placeholder={stringUtil.language(1050)} onChange={(event)=>{
                                            if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                                this.state.currWholesaleCommodityVo.unitPrice = 0;
                                            }else if(stringUtil.numberCheck(event.target.value)){
                                                this.state.currWholesaleCommodityVo.unitPrice = event.target.value;
                                            }
                                            this.state.currWholesaleCommodityVo.totalUnitPrice = this.state.currWholesaleCommodityVo.unitPrice;
                                            this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                        }}/>/{this.state.currWholesaleCommodityVo.mode==0?(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):this.state.currWholesaleCommodityVo.commodityUnitMaster}
                                        <input id='buyerEdit' style={arithUtil.stylesAdd(styles.textText,{width:80,color:'#696969'})} defaultValue={this.state.currWholesaleCommodityVo.buyerCode?this.state.currWholesaleCommodityVo.buyerCode:''} placeholder={stringUtil.language(1156)} onChange={(event)=>{
                                            this.state.currWholesaleCommodityVo.buyerCode=event.target.value;
                                        }}/>
                                        {localStorage.getItem("currentStallItem")&&!JSON.parse(localStorage.getItem("currentStallItem")).wholesaleConfig.showOwnerPrice?<input style={arithUtil.stylesAdd(styles.textText,{width:80,color:'#696969',marginLeft:5})} type='number' defaultValue={this.state.currWholesaleCommodityVo.ownerUnitPrice?this.state.currWholesaleCommodityVo.ownerUnitPrice:''} placeholder={stringUtil.language(1255)} onChange={(event)=>{
                                            if(isNaN(event.target.value)||event.target.value==null||event.target.value==''){
                                                this.state.currWholesaleCommodityVo.ownerUnitPrice = 0;
                                            }else if(stringUtil.numberCheck(event.target.value)){
                                                this.state.currWholesaleCommodityVo.ownerUnitPrice = event.target.value;
                                            }
                                            this.setState({currWholesaleCommodityVo:this.state.currWholesaleCommodityVo});
                                        }}/>:''}
                                        {this.state.currWholesaleCommodityVo.mode==1?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                                            <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                                localStorage.setItem("wholesalePriceType",0);//设置缓存
                                                this.refs.unitPriceVal.value=(this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?this.state.currWholesaleCommodityVo.totalUnitPrice:this.state.currWholesaleCommodityVo.unitPrice);
                                                this.setState({});
                                            }}>
                                                {localStorage.getItem('wholesalePriceType')!=1?<i className="iconfontIndexCss" style={{color:'#FF4500',fontSize:18}}>&#xe71f;</i>:
                                                <i className="iconfontIndexCss" style={{color:'#CECECE',fontSize:18}}>&#xe641;</i>}
                                                <span style={{color:localStorage.getItem('wholesalePriceType')!=1?'#FF4500':'#CECECE',fontSize:18}}> {stringUtil.language(1015)}</span>
                                            </div>
                                            <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                                localStorage.setItem("wholesalePriceType",1);//设置缓存
                                                this.refs.unitPriceVal.value=(this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?this.state.currWholesaleCommodityVo.totalUnitPrice:this.state.currWholesaleCommodityVo.unitPrice);
                                                this.setState({});
                                            }}>
                                                {localStorage.getItem('wholesalePriceType')==1?<i className="iconfontIndexCss" style={{color:'#FF4500',fontSize:18}}>&#xe71f;</i>:
                                                <i className="iconfontIndexCss" style={{color:'#CECECE',fontSize:18}}>&#xe641;</i>}
                                                <span style={{color:localStorage.getItem('wholesalePriceType')==1?'#FF4500':'#CECECE',fontSize:18}}> {stringUtil.language(1016)}</span>
                                            </div>
                                        </div>:''}
                                    </div>
                                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                                        {this.state.currWholesaleCommodityVo.mode==0||this.state.currWholesaleCommodityVo.standard?<span style={{color:'#FFF',fontWeight:'bold'}}>{this.state.currWholesaleCommodityVo.mode==0?this.state.currWholesaleCommodityVo.totalWeight+'-'+this.state.currWholesaleCommodityVo.skinWeight+'-'+arithUtil.keToWeight(this.state.currWholesaleCommodityVo.reduceWeight,this.state.weightMode)+'='+this.state.currWholesaleCommodityVo.weight+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):
                                            this.state.currWholesaleCommodityVo.number+this.state.currWholesaleCommodityVo.commodityUnitMaster+'X'+arithUtil.keToWeight(this.state.currWholesaleCommodityVo.standard,this.state.weightMode)+'='+this.state.currWholesaleCommodityVo.weight+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>:''}
                                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <span style={{color:'#FFF',fontWeight:'bold'}}>{stringUtil.language(890)}</span><span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{this.state.currWholesaleCommodityVo.mode==1&&localStorage.getItem('wholesalePriceType')==1?this.state.currWholesaleCommodityVo.totalGoodMoney:this.state.currWholesaleCommodityVo.goodsMoney}</span><span style={{color:'#FFF',fontWeight:'bold'}}>{stringUtil.currency(1)}</span>
                                        </div>
                                        <span style={{width:arithUtil.pxToDp(100),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#D2855D',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                            let tempValnum = this.state.currWholesaleCommodityVo.number;
                                                if ((tempValnum+'').length>0) {
                                                    this.state.currWholesaleCommodityVo.number = parseFloat(tempValnum);
                                                }else{
                                                    this.state.currWholesaleCommodityVo.number = 0;
                                                }
                                            let tempValtot = this.state.currWholesaleCommodityVo.totalWeight;
                                                if ((tempValtot+'').length>0) {
                                                    this.state.currWholesaleCommodityVo.totalWeight = parseFloat(tempValtot);
                                                }else{
                                                    this.state.currWholesaleCommodityVo.totalWeight = 0;
                                                }
                                                this.state.currWholesaleCommodityVo.totalWeight = arithUtil.weightToKe(this.state.currWholesaleCommodityVo.totalWeight,this.state.weightMode);
                                            let tempValski = this.state.currWholesaleCommodityVo.skinWeight;
                                                if ((tempValski+'').length>0) {
                                                    this.state.currWholesaleCommodityVo.skinWeight = parseFloat(tempValski);
                                                }else{
                                                    this.state.currWholesaleCommodityVo.skinWeight = 0;
                                                }
                                                this.state.currWholesaleCommodityVo.skinWeight = arithUtil.weightToKe(this.state.currWholesaleCommodityVo.skinWeight,this.state.weightMode);
                                                this.state.currWholesaleCommodityVo.weight = arithUtil.weightToKe(this.state.currWholesaleCommodityVo.weight,this.state.weightMode);
                                            let tempValuni = this.state.currWholesaleCommodityVo.unitPrice;
                                                if (!this.state.currWholesaleCommodityVo.supplementaryCommodity&&(tempValuni+'').length>0) {
                                                    this.state.currWholesaleCommodityVo.unitPrice = parseFloat(tempValuni);
                                                }else{
                                                    this.state.currWholesaleCommodityVo.unitPrice = 0;
                                                }
                                            let tempValtotUni = this.state.currWholesaleCommodityVo.totalUnitPrice;
                                                if (!this.state.currWholesaleCommodityVo.supplementaryCommodity&&(tempValtotUni+'').length>0) {
                                                    this.state.currWholesaleCommodityVo.totalUnitPrice = parseFloat(tempValtotUni);
                                                }else{
                                                    this.state.currWholesaleCommodityVo.totalUnitPrice = 0;
                                                }
                                            let tempValOwnerUni = this.state.currWholesaleCommodityVo.ownerUnitPrice;
                                                if (!this.state.currWholesaleCommodityVo.supplementaryCommodity&&(tempValOwnerUni+'').length>0) {
                                                    this.state.currWholesaleCommodityVo.ownerUnitPrice = parseFloat(tempValOwnerUni);
                                                }else{
                                                    this.state.currWholesaleCommodityVo.ownerUnitPrice = 0;
                                                }
                                                this.state.currWholesaleCommodityVo.goodsMoney = arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.goodsMoney).round();
                                                if(this.state.currWholesaleCommodityVo.mode==0){
                                                    this.state.currWholesaleCommodityVo.unitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.unitPrice),this.state.weightMode,0);
                                                    this.state.currWholesaleCommodityVo.ownerUnitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.ownerUnitPrice),this.state.weightMode,0);
                                                    this.state.currWholesaleCommodityVo.totalUnitPrice = 0;
                                                }else{
                                                    this.state.currWholesaleCommodityVo.unitPrice = arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.unitPrice);
                                                    this.state.currWholesaleCommodityVo.ownerUnitPrice = arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.ownerUnitPrice);
                                                    this.state.currWholesaleCommodityVo.totalUnitPrice = arithUtil.yuanToFen(this.state.currWholesaleCommodityVo.totalUnitPrice);
                                                    if(localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
                                                        this.state.currWholesaleCommodityVo.totalGoodMoney=(this.state.currWholesaleCommodityVo.totalUnitPrice).mul(this.state.currWholesaleCommodityVo.number);
                                                        let otherMoneyTotal = 0;
                                                        if(this.state.currWholesaleCommodityVo.costs==null)this.state.currWholesaleCommodityVo.costs=[];
                                                        for(let i=0;i<this.state.currWholesaleCommodityVo.costs.length;i++){
                                                            let costsItem = this.state.currWholesaleCommodityVo.costs[i];
                                                            if(costsItem.moneyScal==null)costsItem.moneyScal=0;
                                                            if (!costsItem.isEdit&&!costsItem.calMode) {//stringUtil.language(868)
                                                                costsItem.money = (this.state.currWholesaleCommodityVo.number).mul(costsItem.moneyScal).round();
                                                            }else if (!costsItem.isEdit&&costsItem.calMode==1) {//stringUtil.language(869)
                                                                costsItem.money = (arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,0)).mul(costsItem.moneyScal).roundDouble();
                                                            }else if (!costsItem.isEdit&&costsItem.calMode==2) {//stringUtil.language(396)
                                                                let otherMoneyTotal=0,scalTemp=0,goodsMoneyTemp=0;
                                                                for(let i=0;i<this.state.currWholesaleCommodityVo.costs.length;i++){
                                                                    let costsItemTemp = this.state.currWholesaleCommodityVo.costs[i];
                                                                    if(costsItemTemp.moneyScal==null)costsItemTemp.moneyScal=0;
                                                                    if (!costsItemTemp.isEdit&&!costsItemTemp.calMode) {//stringUtil.language(868)
                                                                        otherMoneyTotal=otherMoneyTotal.add((this.state.currWholesaleCommodityVo.number).mul(costsItemTemp.moneyScal).round());
                                                                    }else if (!costsItemTemp.isEdit&&costsItemTemp.calMode==1) {//stringUtil.language(869)
                                                                        otherMoneyTotal=otherMoneyTotal.add((arithUtil.keToWeight(this.state.currWholesaleCommodityVo.weight,0)).mul(costsItemTemp.moneyScal).roundDouble());
                                                                    }else if (!costsItemTemp.isEdit&&costsItemTemp.calMode==3) {
                                                                        otherMoneyTotal=otherMoneyTotal.add(parseFloat(costsItemTemp.moneyScal).round());
                                                                    }else{
                                                                        scalTemp=scalTemp+costsItem.moneyScal;
                                                                    }
                                                                }
                                                                goodsMoneyTemp=((this.state.currWholesaleCommodityVo.totalGoodMoney).sub(otherMoneyTotal)).div(scalTemp.mul(0.01).add(1));
                                                                costsItem.money = goodsMoneyTemp.mul(costsItem.moneyScal).mul(0.01).round();
                                                            }else{
                                                                costsItem.money = parseFloat(costsItem.moneyScal).round();
                                                            }
                                                            otherMoneyTotal = otherMoneyTotal.add(costsItem.money);
                                                        }
                                                        this.state.currWholesaleCommodityVo.goodsMoney=(this.state.currWholesaleCommodityVo.totalGoodMoney).sub(otherMoneyTotal);/*20220524吕总说{stringUtil.language(1069)}费只有{stringUtil.language(1115)}的，不管了，就直接用总款减{stringUtil.language(1069)}费 */
                                                        this.state.currWholesaleCommodityVo.unitPrice=(this.state.currWholesaleCommodityVo.goodsMoney).div(this.state.currWholesaleCommodityVo.number);
                                                    }else{}//{stringUtil.language(1015)}
                                                }
                                            if (this.state.currWholesaleCommodityVoKey==-1) {//-1stringUtil.language(1129)，不是-1{stringUtil.language(1092)}左边{stringUtil.language(1063)}
                                                this.state.wholesaleInsertVoItem.commodities.push(this.state.currWholesaleCommodityVo);
                                            }else{
                                                this.state.wholesaleInsertVoItem.commodities[this.state.currWholesaleCommodityVoKey] = this.state.currWholesaleCommodityVo;
                                            }
                                            this.setState({vegEditShow:false});
                                        }}>{stringUtil.language(1027)}</span>
                                    </div>
                                </div>:''}
                                {/* 右边-{stringUtil.language(1092)}菜品-结束 */}
                            </div>
                        </div>
                        <div style={{width:'10%',backgroundColor:'#145F33',borderRadius:3,display:'flex',flexDirection:'column',overflow:'auto'}}>
                            {this.state.commodityTypesAll.map((item,key)=>{
                                return(<span style={{height:40,color:this.state.currShopCommodityTypeItem.id==item.id?'#145F33':'#FFF',fontSize:16,backgroundColor:this.state.currShopCommodityTypeItem.id==item.id?'#8AEC90':'#145F33',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                    this.state.currShopCommodityTypeItem = JSON.parse(JSON.stringify(item));
                                    this.warehouseCommodityItemSearch();
                                    this.setState({currShopCommodityTypeItem:this.state.currShopCommodityTypeItem});
                                }}>{item.name}</span>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <MaskModal title={stringUtil.language(457)} visible={this.state.selectCustomerShowPop} width={arithUtil.pxToDp(300)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'7%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input style={{width:arithUtil.pxToDp(150),height:25,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} id='keyId' onChange={(event)=>{
                                this.state.keySearch = event.target.value;
                                if(this.state.keySearch=='')this.setState({customerItemDatas:this.state.customerItemAll});
                            }} placeholder={stringUtil.language(32)}/>
                            <span style={{width:arithUtil.pxToDp(40),height:'25px',color:'#FFF',lineHeight:'25px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:5,cursor:'pointer'}} onClick={()=>{
                                this.searchCustomers();
                            }}>{stringUtil.language(1034)}</span>
                        </div>
                        <div style={{width:arithUtil.pxToDp(40),height:25,color:'#FFF',borderRadius:3,backgroundColor:'#52A056',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currItem = null;
                            this.state.currDistrictA = null;
                            this.state.currDistrictB = null;
                            this.state.currDistrictC = null;
                            this.setState({addShowPopIndex:this.state.addShowPopIndex+1,customerAddShowPop:!this.state.customerAddShowPop});
                        }}>{stringUtil.language(1129)}</div>
                    </div>
                    <div style={{height:'91%',overflow:'auto'}}>
                        {(this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll).map((item,key)=>{
                            return(<div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                                if (item.warning!=2) {
                                    this.state.wholesaleInsertVoItem.customerId = item.id;
                                    this.state.wholesaleInsertVoItem.customerName = item.noteName;
                                    this.state.wholesaleInsertVoItem.customerWarning = item.warning;
                                    this.state.wholesaleInsertVoItem.customerQuota = item.quota;
                                    this.state.wholesaleInsertVoItem.customerArrears = item.arrears;
                                    this.setState({selectCustomerShowPop: false});
                                }else{ToastBox.error(stringUtil.language(50));}
                            }}>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <img style={{width:30,height:30,borderRadius:15}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                                    <div style={{marginLeft:7,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <span style={{color:item.warning!=2?'#696969':'#D3D3D3',fontWeight:item.warning!=2?'bold':'normal'}}>{item.noteName}</span>
                                        <span style={{color:'#FF8C00',fontSize:'8px'}}>{item.warning==1||item.quota!=0?(item.warning==2?stringUtil.language(458)+'/':'')+stringUtil.language(1066)+arithUtil.fenToYuan(item.quota):(item.warning==2?stringUtil.language(458):'')}</span>
                                    </div>
                                </div>
                                <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                            </div>)
                        })}
                    </div>
                </div>} closeBnTopEvents={() => {this.setState({selectCustomerShowPop: false})}}/>
            <MaskModal title={stringUtil.language(635)} visible={this.state.customerAddShowPop} width={580} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
              content={<div style={{width:'100%',height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.addShowPopIndex}>
                    <div style={{width:'100%',height:'100%',overflow:'auto'}}>
                        <CustomerAddActivityView context={this} successEvent={(result1,result2)=>{
                            this.state.currItem = result1;
                            this.state.currDistrictA = result2.currDistrictA;
                            this.state.currDistrictB = result2.currDistrictB;
                            this.state.currDistrictC = result2.currDistrictC;
                        }}/>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.currItem==null||this.state.currItem.noteName==null || this.state.currItem.noteName=='') {
                        ToastBox.error(stringUtil.language(198));return;
                    }
                    let shengStr = this.state.currDistrictA!=null?this.state.currDistrictA.name:'';
                    let shiStr = this.state.currDistrictB!=null?this.state.currDistrictB.name:'';
                    let xianStr = this.state.currDistrictC!=null?this.state.currDistrictC.name:'';
                    if (shengStr!='' || shiStr!='' || xianStr!='') {
                        if (this.state.currDistrictB!=null) {
                            if (this.state.currDistrictC!=null) {
                                this.state.currItem.adcode = this.state.currDistrictC.adcode;
                            }else{this.state.currItem.adcode = this.state.currDistrictB.adcode;}
                        }else{this.state.currItem.adcode = this.state.currDistrictA.adcode;}
                        this.state.currItem.adname = shengStr+shiStr+xianStr;
                    }
                    stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,this.state.currItem,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.state.customerAddShowPop = false;
                            this.initCustomers();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({customerAddShowPop:false})}}/>
            <MaskModal title={stringUtil.language(280)} visible={this.state.costsShowPop} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
              content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'100%',overflow:'auto'}}>
                        {this.state.currWholesaleCommodityVo.costs!=null?this.state.currWholesaleCommodityVo.costs.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} key={key}>
                                <span>{item.costClauseName}</span>
                                <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.money)} {stringUtil.currency(1)}</span>
                            </div>)
                        }):<div/>}
                    </div>
                </div>}closeBnTopEvents={() => {this.setState({ costsShowPop: false, });}}/>
        </div>)
    }
}

const styles = {
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'15%',
        fontSize:12,
        display:'flex',
        alignItems:'center',
        textAlign:'right'
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    textText:{
        width:'20%',
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#A1CEB9',
        borderWidth:1,
        borderRadius:2,
        backgroundColor:'#FFF',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center'
    },
};