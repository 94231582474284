import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

export default class TabHome extends Component{
  constructor(props){
    super(props);
    this.state={
      weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
      searchs:{
        startTime:arithUtil.getCurrDateTime(1),
      },
      balanceCosts:[],balanceCostsTotal:[],
      balanceExpends:[],balanceExpendsTotal:[],
      balanceDatas:[],
    };
  }
  componentDidMount(){
    stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_URL,null,['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        localStorage.setItem("shopConfigInfo",JSON.stringify(successResponse.data));//设置缓存
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
    this.initData();
  }
  initData(){
    stringUtil.httpRequest(urlUtil.SUMM_OWNER_DAILY_BALANCE_URL,{date:this.state.searchs.startTime},['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
          this.state.balanceCosts=[];this.state.balanceExpends=[];
          this.state.balanceCostsTotal=[];this.state.balanceExpendsTotal=[];
          this.state.balanceDatas=successResponse.data;this.state.isDiscount=0;
          for(let i=0;i<this.state.balanceDatas.length;i++){let sourceItem=this.state.balanceDatas[i];
            if(sourceItem.discount)this.state.isDiscount=1;
            for(let j=0;j<sourceItem.costs.length;j++){let isHava=0;
              for(let k=0;k<this.state.balanceCosts.length;k++){
                if(sourceItem.costs[j].costClauseId==this.state.balanceCosts[k].costClauseId){
                  this.state.balanceCostsTotal[k]=(this.state.balanceCostsTotal[k]).add(this.state.balanceCosts[k].money);
                  isHava=1;break;
                }
              }
              if(!isHava){
                this.state.balanceCosts.push(sourceItem.costs[j]);
                this.state.balanceCostsTotal.push(sourceItem.costs[j].money);
              }
            }
            for(let j=0;j<sourceItem.expends.length;j++){let isHava=0;
              for(let k=0;k<this.state.balanceExpends.length;k++){
                if(sourceItem.expends[j].ownerExpendClauseId==this.state.balanceExpends[k].ownerExpendClauseId){
                  this.state.balanceExpendsTotal[k]=(this.state.balanceExpendsTotal[k]).add(this.state.balanceExpends[k].money);
                  isHava=1;break;
                }
              }
              if(!isHava){
                this.state.balanceExpends.push(sourceItem.expends[j]);
                this.state.balanceExpendsTotal.push(sourceItem.expends[j].money);
              }
            }
          }
          console.log('数据：',this.state.balanceDatas,this.state.balanceCosts,this.state.balanceExpends)
          this.setState({balanceDatas:this.state.balanceDatas});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'row',padding:'0.5%'}}>
        <div style={{width:'79.5%',height:'100%',display:'flex',flexDirection:'column'}}>
          <div style={{height:'50%',backgroundColor:'#FFF',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <img style={{width:'95%',height:'95%'}} loading="lazy" src={require('../images/home_bg.png').default} title={stringUtil.language(84)}/>
          </div>
          <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
            <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',alignItems:'center'}}>
              <span style={{color:'#696969',fontSize:18,marginRight:20,whiteSpace:'nowrap'}}>{stringUtil.language(330)}</span>
              <DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.startTime)} onChange={(date) => {
                this.state.searchs.startTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                this.initData();
              }} />
            </div>
            {/* <div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              //
            }}><span style={{color:'#00F',borderBottomStyle:'solid',borderBottomColor:'#00F',borderBottomWidth:1,marginRight:30}}>{stringUtil.language(1271)}</span>
            </div> */}
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:3})}>{stringUtil.language(1049)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})}>{stringUtil.language(1021)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})}>{stringUtil.language(1022)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})}>{stringUtil.language(1068)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})}>{stringUtil.language(538)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})}>{stringUtil.language(544)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})}>{stringUtil.language(945)}</span>
              {this.state.isDiscount?<span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})}>{stringUtil.language(543)}</span>:''}
              {this.state.balanceCosts.map((item,key)=>{
                return(<span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})} key={key}>{item.costClauseName}</span>)
              })}
              {this.state.balanceExpends.map((item,key)=>{
                return(<span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})} key={key}>{item.ownerExpendClauseName}</span>)
              })}
              <span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2})}>{stringUtil.language(545)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'5%'})}></span>
          </div>
          {(()=>{this.state.totalInfo={number:0,weight:0,goodsMoney:0,diffGoodsMoney:0,ownerDeductGoodsMoney:0,money:0,discount:0,balance:0}})()}
          <div style={{height:0,flexGrow:1,backgroundColor:'#FFF',overflow:'auto'}}>
              {this.state.balanceDatas.map((item,key)=>{
                let moneyTotal = (item.goodsMoney).sub(item.diffGoodsMoney).sub(item.ownerDeductGoodsMoney);
                let costsMoney=0;
                for(let i=0;i<item.costs.length;i++){
                  if(item.costs[i].payee==0){
                    costsMoney = costsMoney.add(item.costs[i].money);
                  } 
                }
                let deductMoney=0;
                for(let i=0;i<item.expends.length;i++){
                  deductMoney = deductMoney.add(item.expends[i].money);
                }
                this.state.totalInfo.number=(this.state.totalInfo.number).add(item.number);
                this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                this.state.totalInfo.goodsMoney=(this.state.totalInfo.goodsMoney).add(item.goodsMoney);
                this.state.totalInfo.diffGoodsMoney=(this.state.totalInfo.diffGoodsMoney).add(item.diffGoodsMoney);
                this.state.totalInfo.ownerDeductGoodsMoney=(this.state.totalInfo.ownerDeductGoodsMoney).add(item.ownerDeductGoodsMoney);
                this.state.totalInfo.money=(this.state.totalInfo.money).add(moneyTotal);
                this.state.totalInfo.discount=(this.state.totalInfo.discount).add(item.discount);
                this.state.totalInfo.balance=(this.state.totalInfo.balance).add(moneyTotal.add(costsMoney).sub(deductMoney));
                return(<div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                  console.log('当前：',item)
                  stringUtil.httpRequest(urlUtil.SUMM_SALE_STATISTICS_RANGE_URL,{
                      startDate:this.state.searchs.startTime,
                      endDate:this.state.searchs.startTime,
                      targetType:0,
                      targetId:item.ownerId,
                  },['key','get',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          this.state.saleStatisticsDO=successResponse.data;
                          this.state.saleStatisticsDO.targetId=item.ownerId;
                          this.state.saleStatisticsDO.targetName=item.targetName;
                          if(this.state.addShowPopIndex){this.state.addShowPopIndex=this.state.addShowPopIndex+1;
                          }else{this.state.addShowPopIndex=0;}
                          this.setState({infoShowPop:true});
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }}><span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:3,textAlign:'left',justifyContent:'flex-start',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'})}>{item.targetName?item.targetName:'--'}</span>
                  <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})}>{item.number}</span>
                  <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                  <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                  <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})}>{arithUtil.fenToYuan(item.diffGoodsMoney)}</span>
                  <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})}>{arithUtil.fenToYuan(item.ownerDeductGoodsMoney)}</span>
                  <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})}>{arithUtil.fenToYuan(moneyTotal)}</span>
                  {this.state.isDiscount?<span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})}>{arithUtil.fenToYuan(item.discount)}</span>:''}
                  {this.state.balanceCosts.map((itemC,keyC)=>{let moneyTemp=0;
                    for(let i=0;i<item.costs.length;i++){
                      if(item.costs[i].costClauseId==itemC.costClauseId){moneyTemp=moneyTemp.add(item.costs[i].money);}
                    }
                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})} key={keyC}>{moneyTemp?arithUtil.fenToYuan(moneyTemp):''}</span>)
                  })}
                  {this.state.balanceExpends.map((itemE,keyE)=>{let moneyTemp=0;
                    for(let i=0;i<item.expends.length;i++){
                      if(item.expends[i].ownerExpendClauseId==itemE.ownerExpendClauseId){moneyTemp=moneyTemp.add(item.expends[i].money);}
                    }
                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})} key={keyE}>{moneyTemp?arithUtil.fenToYuan(moneyTemp):''}</span>)
                  })}
                  <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2})}>{arithUtil.fenToYuan(moneyTotal.add(costsMoney).sub(deductMoney))}</span>
                  <span style={arithUtil.stylesAdd(styles.tableTd,{width:'5%',color:'#249242'})}>&gt;&gt;</span>
                </div>)
              })}
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:3,color:'#D89573'})}>{stringUtil.language(890)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2,color:'#D89573'})}>{this.state.totalInfo.number}</span>
              <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2,color:'#D89573'})}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2,color:'#D89573'})}>{arithUtil.fenToYuan(this.state.totalInfo.money)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2,color:'#D89573'})}>{arithUtil.fenToYuan(this.state.totalInfo.goodsMoney)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2,color:'#D89573'})}>{arithUtil.fenToYuan(this.state.totalInfo.diffGoodsMoney)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2,color:'#D89573'})}>{arithUtil.fenToYuan(this.state.totalInfo.ownerDeductGoodsMoney)}</span>
              {this.state.isDiscount?<span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2,color:'#D89573'})}>{arithUtil.fenToYuan(this.state.totalInfo.discount)}</span>:''}
              {this.state.balanceCostsTotal.map((item,key)=>{
                return(<span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2,color:'#D89573'})} key={key}>{arithUtil.fenToYuan(item)}</span>)
              })}
              {this.state.balanceExpendsTotal.map((item,key)=>{
                return(<span style={arithUtil.stylesAdd(styles.tableTitle,{width:0,flexGrow:2,color:'#D89573'})} key={key}>{arithUtil.fenToYuan(item)}</span>)
              })}
              <span style={arithUtil.stylesAdd(styles.tableTd,{width:0,flexGrow:2,color:'#D89573'})}>{arithUtil.fenToYuan(this.state.totalInfo.balance)}</span>
              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'5%'})}></span>
          </div>
        </div>
        <MaskModal title={(this.state.saleStatisticsDO?'('+this.state.saleStatisticsDO.targetName+')':'')+stringUtil.language(432)} visible={this.state.infoShowPop?true:false} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
          content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column'}} key={this.state.addShowPopIndex?this.state.addShowPopIndex:0}>
            <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
              <span style={{width:'17%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1020)}</span>
              <div style={{width:'83%',display:'flex',flexDirection:'row'}}>
                  <span style={{width:'23%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                  <span style={{width:'11%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                  <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                  <span style={{width:'14%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(962)}</span>
                  <span style={{width:'22%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                  <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1041)}</span>
              </div>
            </div>
            <div style={{width:'100%',height:0,display:'flex',flexDirection:'column',flexGrow:1,overflow:'auto'}}>
              {this.state.saleStatisticsDO&&this.state.saleStatisticsDO.commodities.length?this.state.saleStatisticsDO.commodities.map((item,key)=>{
                  let totalItemInfo={number:0,weight:0,money:0};
                  if(item.detaileds==null) item.detaileds=[];
                  for(let i=0;i<item.detaileds.length;i++){
                      totalItemInfo.number = (totalItemInfo.number).add(item.detaileds[i].number);
                      totalItemInfo.weight = (totalItemInfo.weight).add(item.detaileds[i].weight);
                      item.detaileds[i].money = item.detaileds[i].goodsMoney;
                      for(let j=0;j<item.detaileds[i].costs.length;j++){
                          item.detaileds[i].money = (item.detaileds[i].money).add(item.detaileds[i].costs[j].money);
                      }
                      totalItemInfo.money = (totalItemInfo.money).add(item.detaileds[i].money);
                  }
                  return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                      <span style={{width:'17%',color:'#000',fontSize:15,paddingTop:7}}>{item.commodityName}</span>
                      <div style={{width:'83%',display:'flex',flexDirection:'column'}}>
                      <span style={{height:30,color:'#52A056',fontSize:15,fontWeight:'bold',paddingTop:7}}>{stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money)}{stringUtil.currency(1)}</span>
                      {item.detaileds.map((itemb,keyb)=>{
                          return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                              <span style={{width:'23%',color:'#808080'}}>{itemb.customerName}</span>
                              <span style={{width:'11%',color:'#808080',textAlign:'center'}}>{itemb.number}</span>
                              <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                              <span style={{width:'14%',color:'#808080',textAlign:'center'}}>{(arithUtil.keToWeight(itemb.weight,this.state.weightMode)).div(itemb.number,2)}</span>
                              <span style={{width:'22%',color:'#808080',textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                              <span style={{width:'15%',color:'#808080',textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                          </div>);
                      })}
                      </div>
                  </div>)
              }):''}
              <div style={{width:'100%',display:'flex',flexDirection:'row',flexWrap:'wrap',marginTop:20}}>
                {(()=>{this.state.otherMoneyItems = [];
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(591),money:this.state.saleStatisticsDO?this.state.saleStatisticsDO.saleTotalMoney:0});
                    this.state.otherMoneyItems.push({costClauseName:stringUtil.language(592),money:this.state.saleStatisticsDO?this.state.saleStatisticsDO.deservedMoney:0});
                })()}
                {this.state.otherMoneyItems.map((item,key)=>{
                    return(<div style={{width:'33.3%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{fontSize:15,whiteSpace:'nowrap'}}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</span>
                    </div>);
                })}
              </div>
            </div>
          </div>} closeBnTopEvents={() => {this.setState({infoShowPop:false})}}/>
        <div style={{width:'20%',overflow:'auto',marginLeft:'0.5%',height:'100%',backgroundColor:'#FFF'}}><div style={{height:35,color:'#696969',fontSize:20,marginLeft:15,display:'flex',alignItems:'center'}}>{stringUtil.language(656)}</div>
            <div style={{width:'100%',display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
              {otherUtil.authorityFindCode("0208")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersListActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1202),navigatePage:'OrdersListActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1202)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041301")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersWarehouseActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1203),navigatePage:'OrdersWarehouseActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1203)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041401")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersWarehousesActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1217),navigatePage:'OrdersWarehousesActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1217)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DateSettleActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(480),navigatePage:'DateSettleActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(480)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DateCollectExpendActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(229),navigatePage:'DateCollectExpendActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(473)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040201")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DebtCustomerActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(230),navigatePage:'DebtCustomerActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(230)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040301")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CollectPayAccountsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(284),navigatePage:'CollectPayAccountsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(284)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040401")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OwnerSettleSummaryActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(214),navigatePage:'OwnerSettleSummaryActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(657)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040501")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CustomerAccountsDetailsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(197),navigatePage:'CustomerAccountsDetailsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(658)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041501")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SupplierAccountsDetailsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(163),navigatePage:'SupplierAccountsDetailsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(342)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040601")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CustomerSaleSettleActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(200),navigatePage:'CustomerSaleSettleActivity',paramsPage:'参数内容'}
                ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(200)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040701")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(232),navigatePage:'SaleStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(659)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040801")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleProfitLossActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(233),navigatePage:'SaleProfitLossActivity',paramsPage:'参数内容'}
                ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(598)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040801")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ShopProfitLossActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(234),navigatePage:'ShopProfitLossActivity',paramsPage:'参数内容'}
                ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(622)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041601")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleProfitActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(235),navigatePage:'SaleProfitActivity',paramsPage:'参数内容'}
                ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(596)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040901")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleTimingActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(221),navigatePage:'SaleTimingActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(660)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041001")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'PurchaseStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1278),navigatePage:'PurchaseStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1278)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041001")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'PurchaseStatisticssActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(236),navigatePage:'PurchaseStatisticssActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(236)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WorkerSelfSortActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(343),navigatePage:'WorkerSelfSortActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(343)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BuyerSortActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(91),navigatePage:'BuyerSortActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(344)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041201")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OwnerSaleStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(113),navigatePage:'OwnerSaleStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(213)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041201")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OwnerSaleStatistics',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(114),navigatePage:'OwnerSaleStatistics',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(213)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041301")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleDateActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(237),navigatePage:'SaleDateActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(237)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041401")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BuyerStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(282),navigatePage:'BuyerStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(282)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleReportDailyActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(345),navigatePage:'SaleReportDailyActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(345)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleReportDailysActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(345),navigatePage:'SaleReportDailysActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1313)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("0203")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcessStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(106),navigatePage:'ProcessStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(238)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041701")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'InOutWarehouseActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(150),navigatePage:'InOutWarehouseActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(239)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041701")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'InOutWarehousesActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(150),navigatePage:'InOutWarehousesActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1275)}</span>
              </div>:''}
              <div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BusinessDailyActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1279),navigatePage:'BusinessDailyActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1279)}</span>
              </div>
              {otherUtil.authorityFindCode("041901")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'UpdateBackActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1306),navigatePage:'UpdateBackActivity',paramsPage:'参数内容'}
                ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1306)}</span>
              </div>:''}
            </div>
        </div>
      </div>)
  }
}

const styles = {
  tableTitle:{
    height:30,
    backgroundColor:'#F2F2F2',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    borderStyle:'solid',
    borderColor:'#F0F0F0',
    borderWidth: 0.5
  },
  tableTd:{
    height:30,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    borderStyle:'solid',
    borderColor:'#F0F0F0',
    borderWidth: 0.5
  },
};