import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //经营日报表
 export default class SaleReportDailysActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{date:arithUtil.getCurrDateTime(1)},
        saleReportDaily:null,
        totalInfo:{wholesaleMoney:0}
      };
    }
    componentDidMount(){
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SALE_REPORT_DAILY_URL,{
            date:this.state.searchs.date
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.saleReportDaily=successResponse.data;
                this.state.totalInfo.wholesaleMoney=0;
                for(let i=0;i<this.state.saleReportDaily.cargos.length;i++){let item=this.state.saleReportDaily.cargos[i];
                    let wholesaleMoneyCargosTotal=0;
                    for(let j=0;j<item.commodities.length;j++){let itemCom=item.commodities[j];
                        wholesaleMoneyCargosTotal=wholesaleMoneyCargosTotal.add(item.cargoMode==1?itemCom.stallsMoney:item.cargoMode==2?itemCom.workerMoney:itemCom.ownerMoney);
                    }
                    this.state.totalInfo.wholesaleMoney=(this.state.totalInfo.wholesaleMoney).add(wholesaleMoneyCargosTotal);
                }
                this.setState({saleReportDaily:this.state.saleReportDaily});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        if(!this.state.saleReportDaily){return(<div/>)}
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:60}}>{stringUtil.language(888)}</span>
                        <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.date)} onChange={(date) => {
                            this.state.searchs.date = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;-webkit-print-color-adjust: exact');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?(JSON.parse(localStorage.getItem("currentStallItem")).marketName +JSON.parse(localStorage.getItem("currentStallItem")).name):''}<label style={{fontWeight:'normal'}}>{stringUtil.language(162)}</label></span>
                        <span style={{fontSize:17}}>{this.state.searchs.date}&ensp;&ensp;&ensp;&ensp;{stringUtil.language(967)}{this.state.saleReportDaily.cargos?this.state.saleReportDaily.cargos.length:0}{stringUtil.language(1123)}</span>
                    </div>:''}
                    <div style={{height:100,display:'flex',flexDirection:'row',marginTop:5,marginBottom:5}}>
                        <div style={{width:0,flexGrow:1,height:'100%',marginRight:5,display:'flex',flexDirection:'column'}}>
                            <div style={{width:'100%',height:0,flexGrow:1,marginBottom:3,backgroundColor:'#DDD',borderStyle:'solid',borderColor:'#808080',borderWidth:0.5,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>累计前欠</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>收欠款</span><span style={{width:20,textAlign:'center'}}>+</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>销售金额</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>收现款</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>尾数折让</span><span style={{width:20,textAlign:'center'}}>=</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>累计总欠</span>
                                </div>
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.beforeArrears)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.beforeCollectMoney)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.wholesaleMoney)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.nowCollectMoney)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.discountMoney)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.recordArrears)}</span>
                                </div>
                            </div>
                            <div style={{width:'100%',height:0,flexGrow:1,marginTop:3,backgroundColor:'#DDD',borderStyle:'solid',borderColor:'#808080',borderWidth:0.5,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>销售金额</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>采购成本</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>代卖扣费</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>尾数折让</span><span style={{width:20,textAlign:'center'}}>=</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>实存金额</span>
                                </div>
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.wholesaleMoney)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.purchaseMoney)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan((this.state.saleReportDaily.ownerExpend).sub(this.state.saleReportDaily.cargoRebate).add(this.state.saleReportDaily.cargoEntrust).add(this.state.saleReportDaily.cargoDisburden))}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.cargoDiscount)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan((this.state.totalInfo.wholesaleMoney).sub(this.state.saleReportDaily.purchaseMoney).sub((this.state.saleReportDaily.ownerExpend).sub(this.state.saleReportDaily.cargoRebate).add(this.state.saleReportDaily.cargoEntrust).add(this.state.saleReportDaily.cargoDisburden)).sub(this.state.saleReportDaily.cargoDiscount))}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{width:0,flexGrow:1,height:'100%',marginLeft:5,display:'flex',flexDirection:'column',backgroundColor:'#DDD',borderStyle:'solid',borderColor:'#808080',borderWidth:0.5}}>
                            <div style={{width:'100%',height:0,flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>实收金额</span><span style={{width:20,textAlign:'center'}}>+</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>其他收款</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>垫付三轮</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>日常开支</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>采购付款</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>付员工款</span>
                                </div>
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.customerCollect)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.shopCollect)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.advanceMoney)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan((this.state.saleReportDaily.shopExpend).sub(this.state.saleReportDaily.advanceMoney))}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.supplierPay)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.workerPay)}</span>
                                </div>
                            </div>
                            <div style={{width:'100%',height:0,flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>货主车次开支</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>结付货主</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>公户收支</span><span style={{width:20,textAlign:'center'}}>+</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>上日结余</span><span style={{width:20,textAlign:'center'}}>-</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>结账上交</span><span style={{width:20,textAlign:'center'}}>=</span>
                                    <span style={{width:0,flexGrow:1,textAlign:'center'}}>资金结余</span>
                                </div>
                                {(()=>{this.state.handMoneySettleMoney=0;
                                    for(let i=0;i<this.state.saleReportDaily.handMoneySettle.length;i++){
                                        this.state.handMoneySettleMoney=(this.state.handMoneySettleMoney).add(this.state.saleReportDaily.handMoneySettle[i].money);
                                    }
                                    this.state.subCountMoneyVal=(this.state.saleReportDaily.customerCollect)
                                    .add((this.state.saleReportDaily.shopCollect))
                                    .sub(this.state.saleReportDaily.advanceMoney)
                                    .sub((this.state.saleReportDaily.shopExpend).sub(this.state.saleReportDaily.advanceMoney))
                                    .sub(this.state.saleReportDaily.supplierPay)
                                    .sub(this.state.saleReportDaily.ownerExpend)
                                    .sub(this.state.saleReportDaily.ownerPay)
                                    .sub(this.state.saleReportDaily.workerPay)
                                    .sub(this.state.handMoneySettleMoney);
                                })()}
                                <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.ownerExpend)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.ownerPay)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan((this.state.subCountMoneyVal).sub(this.state.saleReportDaily.balance))}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.saleReportDaily.beforeBalance)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.handMoneySettleMoney)}</span><span style={{width:20,textAlign:'center'}}></span>
                                    <span style={{width:0,flexGrow:1,fontSize:18,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan((this.state.saleReportDaily.balance).add(this.state.saleReportDaily.beforeBalance))}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.saleReportDaily.cargos.map((item,key)=>{
                        let cargosTotal={number:0,weight:0,goodsMoney:0,otherMoney:0,total:0,beforeNumber:0,inNumber:0,otherNumber:0,balanceNumber:0,expMoney:0};
                        return(<div style={{width:'100%',borderStyle:'solid',borderColor:'#808080',borderWidth:0.5,marginBottom:5}} key={key}>
                            <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#E9F1F7'}}>
                                <span style={{fontSize:18,fontWeight:'bold',marginLeft:20,whiteSpace:'nowrap'}}>{item.targetName?item.targetName:item.cargoMode==1?stringUtil.language(426):''}</span>
                                <span style={{fontSize:18,fontWeight:'bold',marginLeft:20,whiteSpace:'nowrap'}}>{stringUtil.language(1237,item.cargoNo)}</span>
                            </div>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',borderTopStyle:'solid',borderTopColor:'#D3D3D3',borderTopWidth:0.5}}>
                                <div style={{width:0,flexGrow:1,height:'100%',borderRightStyle:'solid',borderRightColor:'#808080',borderRightWidth:item.commodities.length>=item.expends.length?5:0}}>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#D3D3D3',borderBottomWidth:0.5,backgroundColor:'#E9F1F7'}}>
                                        <div style={{width:0,flexGrow:1.5,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>商品名称</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>数量</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>重量</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>件均价</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>重均价</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>货款</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>其他</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>小计</span></div>
                                        <div style={{height:30,borderRightStyle:'solid',borderRightColor:'#808080',borderRightWidth:'1px'}}/>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>前存</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>进货</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>销售</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>其他收发</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>结存</span></div>
                                    </div>
                                    {item.commodities.map((itemCom,keyCom)=>{
                                        cargosTotal.number=(cargosTotal.number).add(itemCom.number);
                                        cargosTotal.weight=(cargosTotal.weight).add(itemCom.weight);
                                        cargosTotal.goodsMoney=(cargosTotal.goodsMoney).add((itemCom.goodsMoney).sub(itemCom.diffGoodsMoney).sub(itemCom.ownerDeductGoodsMoney));
                                        cargosTotal.total=(cargosTotal.total).add(item.cargoMode==1?itemCom.stallsMoney:item.cargoMode==2?itemCom.workerMoney:itemCom.ownerMoney);
                                        let otherMoney=0;
                                        for(let i=0;i<itemCom.costs.length;i++){
                                            otherMoney=otherMoney.add(itemCom.costs[i].money);
                                        }
                                        cargosTotal.otherMoney=(cargosTotal.otherMoney).add((item.cargoMode==1?itemCom.stallsMoney:item.cargoMode==2?itemCom.workerMoney:itemCom.ownerMoney).sub((itemCom.goodsMoney).sub(itemCom.diffGoodsMoney).sub(itemCom.ownerDeductGoodsMoney)));
                                        cargosTotal.beforeNumber=(cargosTotal.beforeNumber).add(itemCom.beforeNumber);
                                        cargosTotal.inNumber=(cargosTotal.inNumber).add(itemCom.inNumber);
                                        cargosTotal.otherNumber=(cargosTotal.otherNumber).add(itemCom.otherNumber);
                                        let balanceNumber=(itemCom.beforeNumber).add(itemCom.inNumber).sub(itemCom.number).add(itemCom.otherNumber);
                                        cargosTotal.balanceNumber=(cargosTotal.balanceNumber).add(balanceNumber);
                                        return(<div style={{display:'flex',flexDirection:'row',alignItems:'center'}} key={keyCom}>
                                            <div style={{width:0,flexGrow:1.5,height:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',marginLeft:20}}>{keyCom+1}.{itemCom.commodityName}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{itemCom.number?itemCom.number:''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{itemCom.weight?arithUtil.keToWeight(itemCom.weight,this.state.weightMode):''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{itemCom.jianJunJia?arithUtil.fenToYuan(itemCom.jianJunJia):''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{itemCom.zhongJunJia?arithUtil.unitPriceTo(arithUtil.fenToYuan(itemCom.zhongJunJia),0,this.state.weightMode):''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{(itemCom.goodsMoney).sub(itemCom.diffGoodsMoney).sub(itemCom.ownerDeductGoodsMoney)?arithUtil.fenToYuan((itemCom.goodsMoney).sub(itemCom.diffGoodsMoney).sub(itemCom.ownerDeductGoodsMoney)):''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{(item.cargoMode==1?itemCom.stallsMoney:item.cargoMode==2?itemCom.workerMoney:itemCom.ownerMoney).sub((itemCom.goodsMoney).sub(itemCom.diffGoodsMoney).sub(itemCom.ownerDeductGoodsMoney))?arithUtil.fenToYuan((item.cargoMode==1?itemCom.stallsMoney:item.cargoMode==2?itemCom.workerMoney:itemCom.ownerMoney).sub((itemCom.goodsMoney).sub(itemCom.diffGoodsMoney).sub(itemCom.ownerDeductGoodsMoney))):''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{(item.cargoMode==1?itemCom.stallsMoney:item.cargoMode==2?itemCom.workerMoney:itemCom.ownerMoney)?arithUtil.fenToYuan(item.cargoMode==1?itemCom.stallsMoney:item.cargoMode==2?itemCom.workerMoney:itemCom.ownerMoney):''}</span></div>
                                            <div style={{height:20,borderRightStyle:'solid',borderRightColor:'#808080',borderRightWidth:'1px'}}/>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{itemCom.beforeNumber?itemCom.beforeNumber:''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{itemCom.inNumber?itemCom.inNumber:''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{itemCom.number?itemCom.number:''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{itemCom.otherNumber?itemCom.otherNumber:''}</span></div>
                                            <div style={{width:0,flexGrow:1,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{balanceNumber?balanceNumber:''}</span></div>
                                        </div>)
                                    })}
                                </div>
                                <div style={{height:'100%',borderLeftStyle:'solid',borderLeftColor:'#808080',borderLeftWidth:item.commodities.length<item.expends.length?0.5:0}}>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#D3D3D3',borderBottomWidth:0.5,backgroundColor:'#E9F1F7'}}>
                                        <div style={{width:200,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>费用名称</span></div>
                                        <div style={{width:100,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>金额</span></div>
                                    </div>
                                    {(()=>{
                                        if(item.rebateMoney){
                                            item.expends.push({
                                                ownerExpendClauseName:'回款金额',
                                                money:(item.rebateMoney).mul(-1)
                                            });
                                        }
                                        if(item.entrustMoney){
                                            item.expends.push({
                                                ownerExpendClauseName:'代卖费',
                                                money:item.entrustMoney
                                            });
                                        }
                                        if(item.disburdenMoney){
                                            item.expends.push({
                                                ownerExpendClauseName:'卸货费',
                                                money:item.disburdenMoney
                                            });
                                        }
                                    })()}
                                    {item.expends.map((itemExp,keyExp)=>{
                                        cargosTotal.expMoney=(cargosTotal.expMoney).add(itemExp.money);
                                        return(<div style={{display:'flex',flexDirection:'row',alignItems:'center'}} key={keyExp}>
                                            <div style={{width:200,height:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{width:'100%',fontWeight:'bold',textAlign:'center',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{itemExp.ownerExpendClauseName}</span></div>
                                            <div style={{width:100,height:20,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontWeight:'bold'}}>{arithUtil.fenToYuan(itemExp.money)}</span></div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',borderTopStyle:'solid',borderTopColor:'#D3D3D3',borderTopWidth:0.5}}>
                                <div style={{width:0,flexGrow:1,height:'100%',borderRightStyle:'solid',borderRightColor:'#808080',borderRightWidth:item.commodities.length>=item.expends.length?5:0}}>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <div style={{width:0,flexGrow:1.5,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>合计</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{cargosTotal.number}</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{arithUtil.keToWeight(cargosTotal.weight,this.state.weightMode)}</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{arithUtil.fenToYuan(cargosTotal.goodsMoney)}</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{arithUtil.fenToYuan(cargosTotal.otherMoney)}</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{arithUtil.fenToYuan(cargosTotal.total)}</span></div>
                                        <div style={{height:30,borderRightStyle:'solid',borderRightColor:'#808080',borderRightWidth:'1px'}}/>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{cargosTotal.beforeNumber}</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{cargosTotal.inNumber}</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{cargosTotal.number}</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{cargosTotal.otherNumber}</span></div>
                                        <div style={{width:0,flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{cargosTotal.balanceNumber}</span></div>
                                    </div>
                                </div>
                                <div style={{height:'100%',borderLeftStyle:'solid',borderLeftColor:'#808080',borderLeftWidth:item.commodities.length<item.expends.length?0.5:0}}>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <div style={{width:200,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>合计</span></div>
                                        <div style={{width:100,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{arithUtil.fenToYuan(cargosTotal.expMoney)}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    }
};