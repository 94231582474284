import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

export default class TabSettingUseSetting extends Component{
  constructor(props){
    super(props);
    this.state={
      wholesalePrintShowAll:[{tag:'qianshu',name:stringUtil.language(793),isChoose:false},{tag:'erweima',name:stringUtil.language(367),isChoose:false}],
      printTitleShowPop:false,
      cargoSelfLineItemAll:[],
      addShowPop:false,
      editShowPop:false,
      delShowPop:false,
      currCargoSelfLineItem:{},
      cargoSelfLineItemName:'',
      shopPrintInfo:{},
      workerHabitInfo:{},
      useSetting:{everyCopyNum:1},
      warehousesAll:[],
      workerWarehouseDO:null,
    };
  }
  componentDidMount(){
      this.initCargoSelfLineData();
      stringUtil.httpRequest(urlUtil.BASIC_SHOP_PRINT_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.setState({shopPrintInfo:successResponse.data});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      this.initHabit();
      if(localStorage.getItem("useSetting"))this.setState({useSetting:JSON.parse(localStorage.getItem("useSetting"))});
      stringUtil.httpRequest(urlUtil.OAUTH_WORKER_WAREHOUSE_URL+'/'+(!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).shopRole?'0':(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).workerId),null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({workerWarehouseDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({warehousesAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initCargoSelfLineData(){
      stringUtil.httpRequest(urlUtil.BASIC_CARGO_SELF_LINE_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.setState({cargoSelfLineItemAll:successResponse.data});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initHabit(){
    stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_URL,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            localStorage.setItem("shopConfigInfo",JSON.stringify(successResponse.data));this.setState({});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
    stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.setState({workerHabitInfo:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'row',padding:'0.5%',position:'relative'}}>
          <div style={{position:'absolute',left:0,top:0,right:0,bottom:0,borderStyle:'solid',borderColor:'#F5F5F5',borderWidth: 1,overflow:'auto'}}>
              <div style={{height:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC'}}>
                  <span>&nbsp;&nbsp;&nbsp;{stringUtil.language(794)}</span>
                  <i className="iconfontIndexCss" style={{fontSize:25,color:'#D2855D',cursor:'pointer'}} onClick={()=>{
                      this.state.cargoSelfLineItemName = '';
                      this.setState({addShowPop:!this.state.addShowPop});
                  }}>&#xe601;</i>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                  {this.state.cargoSelfLineItemAll.map((item,key)=>{
                      return(<div style={{width:'31%',height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderStyle:'solid',borderColor:'#D3D3D3',borderWidth: 1,borderRadius:arithUtil.pxToDp(2),margin:arithUtil.pxToDp(2),paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}} key={key}>
                          <span style={{color:'#808080',cursor:'pointer'}} onClick={()=>{
                                this.state.currCargoSelfLineItem = JSON.parse(JSON.stringify(item));
                                this.setState({
                                    currCargoSelfLineItem:this.state.currCargoSelfLineItem,
                                    editShowPop:!this.state.editShowPop
                                });
                            }}>{'（'+(key+1)+'）'+item.name}</span>
                          <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00',cursor:'pointer'}} onClick={()=>{
                              this.state.currCargoSelfLineItem = JSON.parse(JSON.stringify(item));
                              this.setState({delShowPop:!this.state.delShowPop});
                          }}>&#xe612;</i>
                      </div>)
                  })}
              </div>
              <div style={{width:'100%',height:arithUtil.pxToDp(35),borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC',display:'flex',alignItems:'center'}}>&nbsp;&nbsp;&nbsp;{stringUtil.language(795)}</div>
                <div style={{paddingLeft:arithUtil.pxToDp(20)}}>
                {/* <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                    <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(1122)}{stringUtil.language(1152)}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.workerHabitInfo.saleMode = 0;
                            stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,{
                                saleMode:this.state.workerHabitInfo.saleMode,//0stringUtil.language(1114)，1stringUtil.language(1115)
                                defWeight:this.state.workerHabitInfo.defWeight//0stringUtil.language(1024)，1stringUtil.language(1168)
                            },['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success('stringUtil.language(388)');
                                    this.initHabit();
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.workerHabitInfo.saleMode==null||this.state.workerHabitInfo.saleMode==0?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;默认stringUtil.language(1114)
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(40),cursor:'pointer'}} onClick={()=>{
                            this.state.workerHabitInfo.saleMode = 1;
                            stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,{
                                saleMode:this.state.workerHabitInfo.saleMode,//0stringUtil.language(1114)，1stringUtil.language(1115)
                                defWeight:this.state.workerHabitInfo.defWeight//0stringUtil.language(1024)，1stringUtil.language(1168)
                            },['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success('stringUtil.language(388)');
                                    this.initHabit();
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.workerHabitInfo.saleMode==1?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;默认stringUtil.language(1115)
                        </div>
                    </div>
                </div> */}
                {localStorage.getItem("currentStallItem")==null||JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                    <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(796)}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.BASIC_WORKER_WHOLESALE_WAY_URL,{wholesaleWay:0},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    localStorage.setItem("wholesaleLocalInfo",JSON.stringify({wholesaleWay:0}));//设置缓存 0传统{stringUtil.language(796)}，1零{stringUtil.language(1176)}售卖方式，2{stringUtil.language(1084)}售卖方式
                                    this.setState({});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:localStorage.getItem("wholesaleLocalInfo")==null||JSON.parse(localStorage.getItem("wholesaleLocalInfo")).wholesaleWay!=2?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;{stringUtil.language(797)}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(40),cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.BASIC_WORKER_WHOLESALE_WAY_URL,{wholesaleWay:2},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    localStorage.setItem("wholesaleLocalInfo",JSON.stringify({wholesaleWay:2}));//设置缓存 0传统{stringUtil.language(796)}，1零{stringUtil.language(1176)}售卖方式，2{stringUtil.language(1084)}售卖方式
                                    this.setState({});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:localStorage.getItem("wholesaleLocalInfo")&&JSON.parse(localStorage.getItem("wholesaleLocalInfo")).wholesaleWay==2?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;{stringUtil.language(798)}
                        </div>
                    </div>
                </div>:''}
                <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                    <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(799)}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.workerHabitInfo.defWeight = 0;
                            stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,{defWeight:this.state.workerHabitInfo.defWeight/*0stringUtil.language(1024)，1stringUtil.language(1168)*/},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    this.initHabit();
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.workerHabitInfo.defWeight==null||this.state.workerHabitInfo.defWeight==0?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;{stringUtil.language(800)}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(40),cursor:'pointer'}} onClick={()=>{
                            this.state.workerHabitInfo.defWeight = 1;
                            stringUtil.httpRequest(urlUtil.BASIC_WORKER_HABIT_URL,{defWeight:this.state.workerHabitInfo.defWeight/*0stringUtil.language(1024)，1stringUtil.language(1168)*/},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    this.initHabit();
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.workerHabitInfo.defWeight==1?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;{stringUtil.language(368)}
                        </div>
                    </div>
                </div>
                <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                    <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(801)}</span>
                    {JSON.parse(localStorage.getItem("shopConfigInfo"))!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==0?<div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_URL,{weightUnit:JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit,isRound:1,carry:JSON.parse(localStorage.getItem("shopConfigInfo")).carry},['key','put',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                this.initHabit();ToastBox.success(stringUtil.language(388));
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}>{stringUtil.language(369)}</div>:<div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_URL,{weightUnit:JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit,isRound:0,carry:JSON.parse(localStorage.getItem("shopConfigInfo")).carry},['key','put',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                this.initHabit();ToastBox.success(stringUtil.language(388));
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}>{stringUtil.language(370)}</div>}
                </div>
                <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                    <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(802)}</span>
                    {JSON.parse(localStorage.getItem("shopConfigInfo"))!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isDiff!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isDiff==0?<div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_DIFF_URL,{isDiff:1},['key','put',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                this.initHabit();ToastBox.success(stringUtil.language(388));
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}>{stringUtil.language(803)}</div>:<div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_DIFF_URL,{isDiff:0},['key','put',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                this.initHabit();ToastBox.success(stringUtil.language(388));
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}>{stringUtil.language(804)}</div>}
                </div>
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:10}}>&#xe654;</i>
                    <span style={{width:120,color:'#808080'}}>{stringUtil.language(1020)+stringUtil.language(1156)}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_SHOW_CODE_URL,{showCode:0},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    let shopInfoItem=JSON.parse(localStorage.getItem("currentStallItem"));if(!shopInfoItem)shopInfoItem={};
                                    shopInfoItem.showCode=0;localStorage.setItem("currentStallItem",JSON.stringify(shopInfoItem));//设置缓存
                                    ToastBox.success(stringUtil.language(814));this.setState({});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:!localStorage.getItem("currentStallItem")||!JSON.parse(localStorage.getItem("currentStallItem")).showCode?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;{stringUtil.language(1215)+stringUtil.language(1020)}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:40,cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_SHOW_CODE_URL,{showCode:1},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    let shopInfoItem=JSON.parse(localStorage.getItem("currentStallItem"));if(!shopInfoItem)shopInfoItem={};
                                    shopInfoItem.showCode=1;localStorage.setItem("currentStallItem",JSON.stringify(shopInfoItem));//设置缓存
                                    ToastBox.success(stringUtil.language(814));this.setState({});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showCode==1?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;{stringUtil.language(1215)+stringUtil.language(1156)}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:40,cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_SHOW_CODE_URL,{showCode:2},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    let shopInfoItem=JSON.parse(localStorage.getItem("currentStallItem"));if(!shopInfoItem)shopInfoItem={};
                                    shopInfoItem.showCode=2;localStorage.setItem("currentStallItem",JSON.stringify(shopInfoItem));//设置缓存
                                    ToastBox.success(stringUtil.language(814));this.setState({});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showCode==2?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;{stringUtil.language(1215)+stringUtil.language(1020)+stringUtil.language(1156)}
                        </div>
                    </div>
                </div>
              </div>
              <div style={{width:'100%',height:arithUtil.pxToDp(35),borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC',display:'flex',alignItems:'center'}}>&nbsp;&nbsp;&nbsp;{stringUtil.language(805)}</div>
              <div style={{paddingLeft:arithUtil.pxToDp(20)}}>
                  <div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                      <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(806)}</span>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                          this.state.useSetting.everyCopyNum = 0;
                          localStorage.setItem("useSetting",JSON.stringify(this.state.useSetting));//设置缓存
                          this.setState({useSetting:this.state.useSetting});
                      }}>
                          <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.useSetting.everyCopyNum==0?'#D2855D':'#D3D3D3'}}>&#xe614;</i> 0 {stringUtil.language(1199)}，
                      </div>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(10),cursor:'pointer'}} onClick={()=>{
                          this.state.useSetting.everyCopyNum = 1;
                          localStorage.setItem("useSetting",JSON.stringify(this.state.useSetting));//设置缓存
                          this.setState({useSetting:this.state.useSetting});
                      }}>
                          <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.useSetting.everyCopyNum==1?'#D2855D':'#D3D3D3'}}>&#xe614;</i> 1 {stringUtil.language(1199)}，
                      </div>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(10),cursor:'pointer'}} onClick={()=>{
                          this.state.useSetting.everyCopyNum = 2;
                          localStorage.setItem("useSetting",JSON.stringify(this.state.useSetting));//设置缓存
                          this.setState({useSetting:this.state.useSetting});
                      }}>
                          <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.useSetting.everyCopyNum==2?'#D2855D':'#D3D3D3'}}>&#xe614;</i> 2 {stringUtil.language(1199)}，
                      </div>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(10),cursor:'pointer'}} onClick={()=>{
                          this.state.useSetting.everyCopyNum = 3;
                          localStorage.setItem("useSetting",JSON.stringify(this.state.useSetting));//设置缓存
                          this.setState({useSetting:this.state.useSetting});
                      }}>
                          <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.useSetting.everyCopyNum==3?'#D2855D':'#D3D3D3'}}>&#xe614;</i> 3 {stringUtil.language(1199)}
                      </div>
                  </div>
                  {localStorage.getItem("currentStallItem")==null||JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      if (otherUtil.authorityFindCode("010401")!=null) {
                          this.state.printTitleValue = this.state.shopPrintInfo.printTitle;
                          this.setState({printTitleShowPop:!this.state.printTitleShowPop});
                      }
                  }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                      <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(807)}</span><span>{this.state.shopPrintInfo.printTitle}</span>
                  </div>:''}
                  {localStorage.getItem("currentStallItem")==null||JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      if (otherUtil.authorityFindCode("010401")!=null) {
                          this.state.printRemarksValue = this.state.shopPrintInfo.wholesaleText;
                          this.setState({printRemarksShowPop:!this.state.printRemarksShowPop});
                      }
                  }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                      <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(808)}</span><span>{this.state.shopPrintInfo.wholesaleText}</span>
                  </div>:''}
                  <div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      stringUtil.httpRequest(urlUtil.BASIC_SHOP_PRINT_URL,{ownerCargo:this.state.shopPrintInfo&&this.state.shopPrintInfo.ownerCargo?0:1},['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.state.shopPrintInfo.ownerCargo=this.state.shopPrintInfo&&this.state.shopPrintInfo.ownerCargo?0:1;
                            let shopInfoItem=JSON.parse(localStorage.getItem("currentStallItem"));
                            if(!shopInfoItem)shopInfoItem={};shopInfoItem.ownerCargo=this.state.shopPrintInfo.ownerCargo;
                            localStorage.setItem("currentStallItem", JSON.stringify(shopInfoItem));//设置缓存
                            ToastBox.success(stringUtil.language(388));
                            this.initHabit();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                      <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(519)}</span><span>{this.state.shopPrintInfo&&this.state.shopPrintInfo.ownerCargo?stringUtil.language(371):stringUtil.language(181)}</span>
                  </div>
                  <div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      stringUtil.httpRequest(urlUtil.BASIC_SHOP_PRINT_URL,{ownerCargoOther:this.state.shopPrintInfo&&this.state.shopPrintInfo.ownerCargoOther?0:1},['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.state.shopPrintInfo.ownerCargoOther = this.state.shopPrintInfo&&this.state.shopPrintInfo.ownerCargoOther?0:1;
                            let shopInfoItem=JSON.parse(localStorage.getItem("currentStallItem"));
                            if(!shopInfoItem)shopInfoItem={};shopInfoItem.ownerCargoOther=this.state.shopPrintInfo.ownerCargoOther;
                            localStorage.setItem("currentStallItem", JSON.stringify(shopInfoItem));//设置缓存
                            ToastBox.success(stringUtil.language(388));
                            this.initHabit();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                      <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(809)}</span><span>{this.state.shopPrintInfo&&this.state.shopPrintInfo.ownerCargoOther?stringUtil.language(371):stringUtil.language(181)}</span>
                  </div>
                  {localStorage.getItem("currentStallItem")==null||JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_ISSHOWOTHER_URL,{isShowOther:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).isShowOther==1?2:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).isShowOther==2?0:1},['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            let shopInfoItem=JSON.parse(localStorage.getItem("currentStallItem"));if(!shopInfoItem)shopInfoItem={};
                            shopInfoItem.isShowOther=localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).isShowOther==1?2:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).isShowOther==2?0:1;
                            localStorage.setItem("currentStallItem",JSON.stringify(shopInfoItem));//设置缓存
                            ToastBox.success(stringUtil.language(388));this.setState({});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                      <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(810)}</span><span>{localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).isShowOther==1?stringUtil.language(128):localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).isShowOther==2?stringUtil.language(129):stringUtil.language(130)}</span>
                  </div>:''}
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                    <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(811)}</span>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if(localStorage.getItem("printShareInfo")==null)localStorage.setItem("printShareInfo",JSON.stringify({wholesale:{}}));
                        else if(JSON.parse(localStorage.getItem("printShareInfo")).wholesale==null){
                            let tempVal=JSON.parse(localStorage.getItem("printShareInfo"));tempVal.wholesale={};
                            localStorage.setItem("printShareInfo",JSON.stringify(tempVal));
                        }
                        if(localStorage.getItem("printShareInfo")!=null&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale!=null&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1){
                            let tempVal=JSON.parse(localStorage.getItem("printShareInfo"));tempVal.wholesale.arrearsCustomer=0;
                            localStorage.setItem("printShareInfo",JSON.stringify(tempVal));
                        }else{
                            let tempVal=JSON.parse(localStorage.getItem("printShareInfo"));tempVal.wholesale.arrearsCustomer=1;
                            localStorage.setItem("printShareInfo",JSON.stringify(tempVal));
                        }this.setState({});
                    }}>{localStorage.getItem("printShareInfo")!=null&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale!=null&&JSON.parse(localStorage.getItem("printShareInfo")).wholesale.arrearsCustomer==1?stringUtil.language(1003):stringUtil.language(1159)}</div>
                </div>
                {localStorage.getItem("currentStallItem")==null||JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:10}}>&#xe654;</i>
                    <span style={{width:120,color:'#808080'}}>{stringUtil.language(1265)}</span>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_PRINT_UPDATE_WHOLESALE_URL,{printUpdateWholesale:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).printUpdateWholesale?0:1},['key','put',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                let shopInfoItem=JSON.parse(localStorage.getItem("currentStallItem"));if(!shopInfoItem)shopInfoItem={};
                                shopInfoItem.printUpdateWholesale=localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).printUpdateWholesale?0:1;
                                localStorage.setItem("currentStallItem",JSON.stringify(shopInfoItem));//设置缓存
                                ToastBox.success(stringUtil.language(814));this.setState({});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                    }}>{localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).printUpdateWholesale?stringUtil.language(1045):stringUtil.language(1266)}</div>
                </div>:''}
              </div>
              <div style={{width:'100%',height:35,borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC',display:'flex',alignItems:'center'}}>&nbsp;&nbsp;&nbsp;{stringUtil.language(812)}</div>
              <div style={{paddingLeft:arithUtil.pxToDp(20)}}>
                  {!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).shopRole||
                    ((localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).shopRole&&
                    (localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).editWarehouse)?<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                      <span style={{width:120,color:'#808080'}}>{stringUtil.language(649)}</span>
                      <select style={{width:200,height:30,fontSize:14,borderWidth:0}} onChange={(event)=>{
                            stringUtil.httpRequest(urlUtil.OAUTH_WORKER_WAREHOUSE_URL+'/'+(!(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).shopRole?'0':(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}).workerId),{warehouseId:JSON.parse(event.target.value).id},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    if(this.state.workerWarehouseDO==null){this.state.workerWarehouseDO={
                                        workerId:0,warehouseId:JSON.parse(event.target.value).id,warehouseName:JSON.parse(event.target.value).name
                                    }}else{
                                        this.state.workerWarehouseDO.warehouseId=JSON.parse(event.target.value).id
                                        this.state.workerWarehouseDO.warehouseName=JSON.parse(event.target.value).name;
                                    }
                                    let selectItem=localStorage.getItem("currentWarehouseItem")?JSON.parse(localStorage.getItem("currentWarehouseItem")):{};
                                    selectItem.id=JSON.parse(event.target.value).id;
                                    selectItem.name=JSON.parse(event.target.value).name;
                                    localStorage.setItem("currentWarehouseItem", JSON.stringify(selectItem));
                                    window.location.reload();
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                        }}><option style={{display:'none'}}>{stringUtil.language(975)}</option>
                        {this.state.warehousesAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} selected={this.state.workerWarehouseDO&&this.state.workerWarehouseDO.warehouseId==item.id?'selected':''} key={key}>{stringUtil.subStr(item.name,15)}</option>)
                        })}
                        </select>
                  </div>:<span style={{color:'#FFF',fontSize:17}}>{this.state.workerWarehouseDO?this.state.workerWarehouseDO.warehouseName:stringUtil.language(1536)}</span>}
                    {localStorage.getItem("currentStallItem")==null||JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                        <span style={{width:arithUtil.pxToDp(120),color:'#808080'}}>{stringUtil.language(813)}</span>
                        <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            if (otherUtil.authorityFindCode("010101")!=null) {
                                stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_URL,{weightUnit:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?0:1,isRound:JSON.parse(localStorage.getItem("shopConfigInfo")).isRound,carry:JSON.parse(localStorage.getItem("shopConfigInfo")).carry},['key','put',1],(successResponse)=>{
                                    if (successResponse.status==200) {
                                        this.initHabit();ToastBox.success(stringUtil.language(814)+'！');
                                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                            }
                        }}>{localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?stringUtil.language(1172):stringUtil.language(1023)}</div>
                    </div>:''}
                    {localStorage.getItem("currentStallItem")==null||JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:10}}>&#xe654;</i>
                        <span style={{width:120,color:'#808080'}}>{stringUtil.language(815)}</span>
                        <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_SHARE_URL,{isShare:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).isShare?0:1},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    this.initHabit();ToastBox.success(stringUtil.language(814));
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                        }}>{localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).isShare?stringUtil.language(257):stringUtil.language(258)}</div>
                    </div>:''}
                    <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:10}}>&#xe654;</i>
                        <span style={{width:120,color:'#808080'}}>{stringUtil.language(1227)}</span>
                        <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            if(localStorage.getItem("appLanguage")&&parseInt(localStorage.getItem("appLanguage"))){localStorage.setItem("appLanguage",0);
                            }else{localStorage.setItem("appLanguage",1);}
                            window.location.reload();
                        }}>{localStorage.getItem("appLanguage")&&parseInt(localStorage.getItem("appLanguage"))?stringUtil.language(1228):stringUtil.language(1229)}</div>
                    </div>
                    <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:10}}>&#xe654;</i>
                        <span style={{width:120,color:'#808080'}}>{stringUtil.language(1236)}</span>
                        <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_SHOW_IMG_URL,{showImg:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showImg?0:1},['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    let shopInfoItem=JSON.parse(localStorage.getItem("currentStallItem"));if(!shopInfoItem)shopInfoItem={};
                                    shopInfoItem.showImg=localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showImg?0:1;
                                    localStorage.setItem("currentStallItem",JSON.stringify(shopInfoItem));//设置缓存
                                    ToastBox.success(stringUtil.language(814));this.setState({});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                        }}>{localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showImg?stringUtil.language(1216):stringUtil.language(1215)}</div>
                    </div>
              </div>
              <MaskModal visible={this.state.printRemarksShowPop} title={stringUtil.language(808)} height={arithUtil.pxToDp(200)}
                  content={<input style={arithUtil.stylesAdd(styles.modalInputText,{width:250})} placeholder={stringUtil.language(182)+'..'} defaultValue={this.state.printRemarksValue?this.state.printRemarksValue.trim():''} key={this.state.printRemarksValue} onChange={(event)=>{
                      this.state.printRemarksValue = event.target.value?event.target.value:' ';
                  }}/>}
                  okBnEvents={(event) => {
                      this.setState({printRemarksShowPop: false});
                      stringUtil.httpRequest(urlUtil.BASIC_SHOP_PRINT_URL,{wholesaleText:this.state.printRemarksValue},['key','put',1],(successResponse)=>{
                          if (successResponse.status==200) {
                              this.state.shopPrintInfo.wholesaleText = this.state.printRemarksValue;
                              ToastBox.success(stringUtil.language(388));
                              this.setState({shopPrintInfo:this.state.shopPrintInfo});
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }} closeBnEvents={() => {this.setState({printRemarksShowPop: false})}}/>
              <MaskModal visible={this.state.printTitleShowPop} title={stringUtil.language(807)} height={arithUtil.pxToDp(200)}
                  content={<input style={arithUtil.stylesAdd(styles.modalInputText,{width:250})} placeholder={stringUtil.language(183)+'..'} defaultValue={this.state.printTitleValue} key={this.state.printTitleValue} onChange={(event)=>{
                      this.state.printTitleValue = event.target.value;
                  }}/>}
                  okBnEvents={(event) => {
                      this.setState({printTitleShowPop: false});
                      stringUtil.httpRequest(urlUtil.BASIC_SHOP_PRINT_URL,{printTitle:this.state.printTitleValue},['key','put',1],(successResponse)=>{
                          if (successResponse.status==200) {
                              this.state.shopPrintInfo.printTitle = this.state.printTitleValue;
                              ToastBox.success(stringUtil.language(388));
                              this.setState({shopPrintInfo:this.state.shopPrintInfo});
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }} closeBnEvents={() => {this.setState({printTitleShowPop: false})}}/>
          </div>
          <MaskModal visible={this.state.addShowPop} title={stringUtil.language(259)} width={arithUtil.pxToDp(320)} height={arithUtil.ProportionTo(document.body.clientHeight,0.3)}
              content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                  <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                      {stringUtil.language(1089)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} placeholder={stringUtil.language(49)+'..'} onChange={(event)=>{
                          this.state.cargoSelfLineItemName = event.target.value;
                      }}/>
                  </div>
              </div>}
          okBnEvents={(event) => {
              if (this.state.cargoSelfLineItemName=='') {
                  ToastBox.error(stringUtil.language(49)+'！');return;
              }
              this.setState({addShowPop: false});
              stringUtil.httpRequest(urlUtil.BASIC_CARGO_SELF_LINE_URL,{name:this.state.cargoSelfLineItemName},['key','post',1],(successResponse)=>{
                  if (successResponse.status==200) {
                      ToastBox.success(stringUtil.language(388));
                      this.initCargoSelfLineData();
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
              },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
          }} closeBnEvents={() => {this.setState({addShowPop: false})}}/>
          <MaskModal visible={this.state.editShowPop} title={stringUtil.language(260)} width={arithUtil.pxToDp(320)} height={arithUtil.ProportionTo(document.body.clientHeight,0.3)}
              content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.currCargoSelfLineItem.id}>
                      <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                          {stringUtil.language(1089)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} defaultValue={this.state.currCargoSelfLineItem.name} placeholder={stringUtil.language(49)+'..'} onChange={(event)=>{
                              this.state.currCargoSelfLineItem.name = event.target.value;
                          }}/>
                      </div>
                  </div>}
          okBnEvents={(event) => {
              if (this.state.currCargoSelfLineItem.name==null || this.state.currCargoSelfLineItem.name=='') {
                  ToastBox.error(stringUtil.language(49)+'！');return;
              }
              this.setState({editShowPop: false});
              stringUtil.httpRequest(urlUtil.BASIC_CARGO_SELF_LINE_URL+'/'+this.state.currCargoSelfLineItem.id,{name:this.state.currCargoSelfLineItem.name},['key','put',1],(successResponse)=>{
                  if (successResponse.status==200) {
                      ToastBox.success(stringUtil.language(388));
                      this.initCargoSelfLineData();
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
              },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
          }} closeBnEvents={() => {this.setState({editShowPop: false})}}/>
          <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {stringUtil.language(28)}？
              </div>}
          okBnEvents={(event) => {
              this.setState({delShowPop: false});
              stringUtil.httpRequest(urlUtil.BASIC_CARGO_SELF_LINE_URL+'/'+this.state.currCargoSelfLineItem.id,null,['key','delete',1],(successResponse)=>{
                  if (successResponse.status==200) {
                      ToastBox.success(stringUtil.language(388));
                      this.initCargoSelfLineData();
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
              },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
          }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
      </div>)
  }
}

const styles = {
  inputText:{
      height:arithUtil.pxToDp(30),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5)
  },
  modalInputText:{
      height:arithUtil.pxToDp(45),
      marginLeft:arithUtil.pxToDp(10),
      marginRight:arithUtil.pxToDp(10),
      marginTop:arithUtil.pxToDp(30),
      paddingLeft:arithUtil.pxToDp(10),
      borderLeftWidth:0,
      borderTopWidth:0,
      borderRightWidth:0,
      borderBottomStyle:'solid',
      borderBottomWidth:1,
      borderBottomColor:'#d0d0d0',
  },
};