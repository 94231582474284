import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import * as printUtil from '../utils/printUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput.js';
import CustomerCollectInfoView from './CustomerCollectInfoView.js';

export default class TabWholesale extends Component{
  constructor(props){
    super(props);
    this.state={
      weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
      searchs:{
        state:1,//-1stringUtil.language(1097)，0stringUtil.language(1145)，1stringUtil.language(1037)
        collectionFinish:-1,//-1stringUtil.language(1097)，0stringUtil.language(1087)，1stringUtil.language(1141)
        warehouseId:localStorage.getItem("currentWarehouseItem")?(JSON.parse(localStorage.getItem("currentWarehouseItem"))).id:-1,
        docNum:'',
        customerId:-1,
        customerName:'',
        buyerId:-1,
        money:'',
        targetType:-1,
        targetId:-1,
        cargoNo:'',
        workerId:-1,//stringUtil.language(970)工，-1stringUtil.language(1097)
        operaterId:-1,//stringUtil.language(987)工，-1stringUtil.language(1097)
        startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
        endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
      },
      warehousesAll:[],
      personsAll:[],
      wholesaleSearchDtoObj:{},
      infoShowPop:false,
      delShowPop:false,
      collectionShowPop:false,
      totalNum:'',//总记录数
      currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
      goValue:'',
      totalPage:'',//总{stringUtil.language(1199)}数
      wholesaleItemData: [],             //列表数据
      currWholesaleInfoItem:{},
      advanceMoneyShowPop:false,
      totalNumAdvance:'',//总记录数
      currentPageAdvance: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
      goValueAdvance:'',
      totalPageAdvance:'',//总{stringUtil.language(1199)}数
      advancesAll:[],
      accountsAll:[],
      customerCollectDiscountDO:[],
      visibleCollection:false,
      customerCollectInsertItem:{time:arithUtil.getCurrDateTime(),collects:[],collectMoney:0,discountMoney:0},
      wholesaleCommoditys:[],
      buyersAll:[],
    };
    if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
  }
  componentDidMount(){
    // if(localStorage.getItem("wholesalesSearchs")){
    //   this.state.searchs.startTime=JSON.parse(localStorage.getItem("wholesalesSearchs")).startTime;
    //   this.state.searchs.endTime=JSON.parse(localStorage.getItem("wholesalesSearchs")).endTime;
    // }
    this.getDataList();
    stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
      state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
      cargoMode:-1,
      currPage:0,
      pageSize:1000
    },['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        this.setState({wholesaleCargosData:successResponse.data.items});
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        this.state.warehousesAll = successResponse.data;
        this.state.warehousesAll.unshift({id:-1,name:stringUtil.language(453)});
        this.setState({warehousesAll:this.state.warehousesAll});
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL,null,['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
          this.setState({buyersAll:successResponse.data});
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
          let ownersAllTemp = successResponse.data;
          this.state.ownersAll = [];
          this.state.ownersAll.unshift({targetType:-1,targetId:-1,noteName:stringUtil.language(517)});
          for(let i=0;i<ownersAllTemp.length;i++){
              this.state.ownersAll.push({targetType:0,targetId:ownersAllTemp[i].id,noteName:ownersAllTemp[i].noteName});
          }
          this.state.ownersAll.push({targetType:1,targetId:-1,noteName:stringUtil.language(426)});
          stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
              if (successResponse.status==200) {
                  let workersAll = successResponse.data;
                  for(let i=0;i<workersAll.length;i++){
                      this.state.ownersAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                      this.state.personsAll.push({workerType:2,workerId:workersAll[i].id,noteName:workersAll[i].noteName});
                  }
                  this.state.personsAll.unshift({workerType:2,workerId:0,noteName:stringUtil.language(431)});
                  this.state.personsAll.unshift({workerType:-1,workerId:-1,noteName:stringUtil.language(346)});
                  this.setState({ownersAll:this.state.ownersAll});
              }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
          },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    stringUtil.httpRequest(urlUtil.ACCOUNT_URL,{state:1},['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.setState({accountsAll:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    if(global.isCloseThis==1){
      global.isCloseThis=null;
      this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleAddActivity',mainStacks:[
        {title:stringUtil.language(1128),navigatePage:''},
        {title:stringUtil.language(816),navigatePage:'WholesaleAddActivity',paramsPage:stringUtil.language(816)+'参数'}
      ]}});
    }else if(global.isCloseThis==2){
      global.isCloseThis=null;
      this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleAddsActivity',mainStacks:[
        {title:stringUtil.language(1128),navigatePage:''},
        {title:stringUtil.language(816),navigatePage:'WholesaleAddsActivity',paramsPage:stringUtil.language(816)+'参数'}
      ]}});
    }else if(global.isCloseThis==3){
      global.isCloseThis=null;
      this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleAddssActivity',mainStacks:[
        {title:stringUtil.language(1128),navigatePage:''},
        {title:stringUtil.language(816),navigatePage:'WholesaleAddssActivity',paramsPage:stringUtil.language(816)+'参数'}
      ]}});
    }
  }
  //{stringUtil.language(1113)}{stringUtil.language(1038)}{stringUtil.language(1189)}后刷新此方法
  getDataList(){
    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_SEARCH_URL,{
      state:this.state.searchs.state,
      docNum:this.state.searchs.docNum,
      customerId:this.state.searchs.customerId,
      buyerId:this.state.searchs.buyerId,
      customerName:this.state.searchs.customerName,
      money:this.state.searchs.money,
      warehouseId:this.state.searchs.warehouseId,
      collectionFinish:this.state.searchs.collectionFinish,
      targetType:this.state.searchs.targetType,
      targetId:this.state.searchs.targetId,
      cargoNo:this.state.searchs.cargoNo,
      workerId:this.state.searchs.workerId,
      operaterId:this.state.searchs.operaterId,
      startTime:arithUtil.settleSub(this.state.searchs.startTime),
      endTime:arithUtil.settleSub(this.state.searchs.endTime),
      currPage:this.state.currentPage-1,
      pageSize:20
    },['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        this.state.wholesaleSearchDtoObj = successResponse.data;
        this.state.totalNum = this.state.wholesaleSearchDtoObj.count;
        this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
        this.setState({wholesaleItemData:this.state.wholesaleSearchDtoObj.items});
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  goPrevClick(){//上一步
      var _this = this;
      let cur = this.state.currentPage;
      if(cur > 1) _this.pageClick( cur - 1);
  }
  goNext(){//下一步
      var _this = this;
      let cur = _this.state.currentPage;
      if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
  }
  goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
    var _this= this;
    _this.setState({goValue : e.target.value})
    var value = e.target.value;
    if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
    else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
    else _this.pageClick(value);
  }
  pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
      let _this = this;
      if(pageNum != _this.state.currentPage){
          _this.state.currentPage = pageNum
      }
      this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
  }
  //sourch：{stringUtil.language(1165)}、{stringUtil.language(1022)}、{stringUtil.language(1068)}
  getModeMoney(sourch,ruleMoney,calMode){
    let resultMoney = ruleMoney;
    if (calMode==0) {//stringUtil.language(1021)
      resultMoney = sourch.mul(ruleMoney);
    }else if (calMode==1) {//{stringUtil.language(1022)}
      resultMoney = ruleMoney.mul(arithUtil.keToWeight(sourch,0));
    }else if (calMode==2) {//stringUtil.language(1041)
      resultMoney = sourch.mul(ruleMoney).mul(0.01);
    }
    return resultMoney;
  }
  getAdvance(){
    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_ADVANCE_URL,{
      state:this.state.searchs.state,
      customerId:this.state.searchs.customerId,
      customerName:this.state.searchs.customerName,
      buyerId:this.state.searchs.buyerId,
      warehouseId:this.state.searchs.warehouseId,
      collectionFinish:this.state.searchs.collectionFinish,
      targetType:this.state.searchs.targetType,
      targetId:this.state.searchs.targetId,
      cargoNo:this.state.searchs.cargoNo,
      workerId:this.state.searchs.workerId,
      operaterId:this.state.searchs.operaterId,
      startTime:arithUtil.settleSub(this.state.searchs.startTime),
      endTime:arithUtil.settleSub(this.state.searchs.endTime),
      currPage:this.state.currentPageAdvance-1,
      pageSize:20
    },['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
          let advances = successResponse.data;
          this.state.totalNumAdvance = advances.count;
          this.state.totalPageAdvance = Math.ceil( this.state.totalNumAdvance / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
          this.state.advanceMoneyShowPop=true;
          this.setState({advancesAll:advances.items});
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  goPrevClickAdvance(){//上一步
      var _this = this;
      let cur = this.state.currentPageAdvance;
      if(cur > 1) _this.pageClickAdvance( cur - 1);
  }
  goNextAdvance(){//下一步
      var _this = this;
      let cur = _this.state.currentPageAdvance;
      if(cur < _this.state.totalPageAdvance) _this.pageClickAdvance(cur + 1);
  }
  goSwitchChangeAdvance(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
    var _this= this;
    _this.setState({goValueAdvance : e.target.value})
    var value = e.target.value;
    if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
    else if(parseInt(value) > parseInt(_this.state.totalPageAdvance)) ToastBox.error(stringUtil.language(139));
    else _this.pageClickAdvance(value);
  }
  pageClickAdvance(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
      let _this = this;
      if(pageNum != _this.state.currentPageAdvance){
          _this.state.currentPageAdvance = pageNum
      }
      this.getAdvance();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
  }
  collectTotal(){
      this.state.customerCollectInsertItem.collectMoney = 0;//stringUtil.language(894)
      this.state.customerCollectInsertItem.discountMoney = 0;//总stringUtil.language(1042)
      if (this.state.customerCollectInsertItem.collects.length>0) {
          for(let i=0;i<this.state.customerCollectInsertItem.collects.length;i++){
              let collectDetailedTemp = this.state.customerCollectInsertItem.collects[i];
              if (collectDetailedTemp.collectMoney!=0) {
                  this.state.customerCollectInsertItem.collectMoney = (this.state.customerCollectInsertItem.collectMoney).add(collectDetailedTemp.collectMoney);
              }
              if (collectDetailedTemp.discountMoney!=0) {
                  this.state.customerCollectInsertItem.discountMoney = (this.state.customerCollectInsertItem.discountMoney).add(collectDetailedTemp.discountMoney);
              }
          }
      //   this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
      }
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
        <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',backgroundColor:'#E5E5E5',paddingLeft:10,paddingRight:10}}>
          <div style={{width:'17%',height:'80%',backgroundColor:this.state.searchs.state==0?'#FFEB3B':'#FFF',borderRadius:5,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              this.state.searchs.state = 0;
              this.state.searchs.collectionFinish = -1;
              this.getDataList();
            }}><span style={{fontSize:20,fontWeight:'bold'}}>{stringUtil.language(372)}</span>
          </div>
          <div style={{width:'17%',height:'80%',backgroundColor:this.state.searchs.state==1?'#FFEB3B':'#FFF',borderRadius:5,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              this.state.searchs.state = 1;
              this.state.searchs.collectionFinish = -1;
              this.getDataList();
            }}><span style={{fontSize:20,fontWeight:'bold'}}>{stringUtil.language(373)}</span>
          </div>
          <select style={{width:'12%',height:'80%',color:'#00F',borderWidth:0,borderBottomStyle:'solid',borderBottomColor:'#00F',borderBottomWidth:1,backgroundColor:'transparent',fontSize:16}} onChange={(event)=>{
              this.state.searchs.buyerId = JSON.parse(event.target.value).id;
              this.getDataList();
          }}><option value={JSON.stringify({id:-1,name:stringUtil.language(281)})}>{stringUtil.language(281)}</option>
              {this.state.buyersAll.map((item,key)=>{
                return(<option selected={this.state.searchs.buyerId==item.id?'selected':''} value={JSON.stringify(item)} key={key}>{item.name}</option>)
              })}
          </select>
          <select style={{width:'12%',height:'80%',color:'#00F',borderWidth:0,borderBottomStyle:'solid',borderBottomColor:'#00F',borderBottomWidth:1,backgroundColor:'transparent',fontSize:16}} onChange={(event)=>{
              this.state.searchs.workerId = JSON.parse(event.target.value).workerId;
              this.getDataList();
          }}><option value={JSON.stringify({workerType:-1,workerId:-1,noteName:stringUtil.language(346)})}>{stringUtil.language(346)}</option>
              {this.state.personsAll.map((item,key)=>{
                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
              })}
          </select>
          <select style={{width:'12%',height:'80%',color:'#00F',borderWidth:0,borderBottomStyle:'solid',borderBottomColor:'#00F',borderBottomWidth:1,backgroundColor:'transparent',fontSize:16}} onChange={(event)=>{
              this.state.searchs.operaterId = JSON.parse(event.target.value).workerId;
              this.getDataList();
          }}><option value={JSON.stringify({workerType:-1,workerId:-1,noteName:stringUtil.language(374)})}>{stringUtil.language(374)}</option>
              {this.state.personsAll.map((item,key)=>{
                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
              })}
          </select>
        </div>
        <div style={{height:35,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',backgroundColor:'#E5E5E5',paddingLeft:10,paddingRight:10}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
              <div style={styles.time}>
                  <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                      this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(new Date(date.valueOf()),'Y-M-D'),0);
                      localStorage.setItem("wholesalesSearchs",JSON.stringify(this.state.searchs));//设置缓存
                      this.getDataList();
                  }} />
                  <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
              </div> {stringUtil.language(1174)} <div style={styles.time}>
                  <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                      this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(new Date(date.valueOf()),'Y-M-D'));
                      localStorage.setItem("wholesalesSearchs",JSON.stringify(this.state.searchs));//设置缓存
                      this.getDataList();
                  }} />
                  <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
              </div>
          </div>
          <div style={{width:130,height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
            <SearchInput onChangeEvents={(value)=>{
              this.state.searchs.docNum = value;
              this.getDataList();
            }} placeholder={stringUtil.language(262)+'..'}/>
          </div>
          <div style={{width:150,height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
            <SearchInput onChangeEvents={(value)=>{
              this.state.searchs.customerName = value;
              this.getDataList();
            }} placeholder={stringUtil.language(261)+'..'}/>
          </div>
          <div style={{width:80,height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
            <SearchInput styles={{fontSize:12}} onChangeEvents={(value)=>{
              try {
                this.state.searchs.money = arithUtil.yuanToFen(parseFloat(value))+'';
              } catch (error) {
                this.state.searchs.money = null;
              }
              this.getDataList();
            }} placeholder={stringUtil.language(817)+'..'}/>
          </div>
          <select style={{width:120,height:25,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
              this.state.searchs.targetType = JSON.parse(event.target.value).targetType;
              this.state.searchs.targetId = JSON.parse(event.target.value).targetId;
              this.state.searchs.targetName = JSON.parse(event.target.value).noteName;
              this.getDataList();
          }}>{(this.state.ownersAll?this.state.ownersAll:[]).map((item,key)=>{
                  return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
              })}
          </select>
          <div style={{width:100,height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
            <SearchInput styles={{fontSize:12}} onChangeEvents={(value)=>{
              this.state.searchs.cargoNo = value;
              this.getDataList();
            }} placeholder={stringUtil.language(375)+'..'}/>
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              this.state.searchs.collectionFinish = -1;
              this.getDataList();
            }}>
              {this.state.searchs.collectionFinish==-1?<i className="iconfontIndexCss" style={{color:'#FF8C00',fontSize:15}}>&#xe71f;{stringUtil.language(1097)}</i>:
              <i className="iconfontIndexCss" style={{color:'#696969',fontSize:15}}>&#xe641;{stringUtil.language(1097)}</i>}
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              this.state.searchs.collectionFinish = 1;
              this.getDataList();
            }}>
              {this.state.searchs.collectionFinish==1?<i className="iconfontIndexCss" style={{color:'#FF8C00',fontSize:15,paddingLeft:10}}>&#xe71f;{stringUtil.language(1141)}</i>:
              <i className="iconfontIndexCss" style={{color:'#696969',fontSize:15,paddingLeft:10}}>&#xe641;{stringUtil.language(1141)}</i>}
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
              this.state.searchs.collectionFinish = 0;
              this.getDataList();
            }}>
              {this.state.searchs.collectionFinish==0?<i className="iconfontIndexCss" style={{color:'#FF8C00',fontSize:15,paddingLeft:10}}>&#xe71f;{stringUtil.language(1087)}</i>:
              <i className="iconfontIndexCss" style={{color:'#696969',fontSize:15,paddingLeft:10}}>&#xe641;{stringUtil.language(1087)}</i>}
            </div>
          </div>
        </div>
        <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
            {(()=>{this.state.isAllSelect=1;
                for(let key=0;key<this.state.wholesaleItemData.length;key++){let item=this.state.wholesaleItemData[key];
                    if(!item.isChoiceChenpinglong)this.state.isAllSelect=0;
                }
            })()}
            {this.state.searchs.state==0&&this.state.isAllSelect?<i className="iconfontIndexCss" style={{width:'5%',fontSize:18,color:'#52A056',textAlign:'center',cursor:'pointer'}} onClick={()=>{//stringUtil.language(1036)==>不stringUtil.language(1036)
                for(let key=0;key<this.state.wholesaleItemData.length;key++){let item=this.state.wholesaleItemData[key];
                    this.state.wholesaleItemData[key].isChoiceChenpinglong=0;
                }
                this.setState({wholesaleItemData:this.state.wholesaleItemData});
            }}>&#xe698;</i>:this.state.searchs.state==0?<i className="iconfontIndexCss" style={{width:'5%',fontSize:18,color:'#A9A9A9',textAlign:'center',cursor:'pointer'}} onClick={()=>{//不stringUtil.language(1036)==>stringUtil.language(1036)
                for(let key=0;key<this.state.wholesaleItemData.length;key++){let item=this.state.wholesaleItemData[key];
                    this.state.wholesaleItemData[key].isChoiceChenpinglong=1;
                }
                this.setState({wholesaleItemData:this.state.wholesaleItemData});
            }}>&#xe6a7;</i>:''}
            <span style={{width:this.state.searchs.state==0?'14%':'13%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1039)}</span>
            <span style={{width:this.state.searchs.state==0?'12%':'11%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1040)}</span>
            <span style={{width:this.state.searchs.state==0?'14%':'13%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1048)}</span>
            <span style={{width:this.state.searchs.state==0?'7%':'6%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1122)}</span>
            <span style={{width:this.state.searchs.state==0?'7%':'6%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1109)}</span>
            <span style={{width:this.state.searchs.state==0?'5%':'6%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1161)}</span>
            <span style={{width:this.state.searchs.state==0?'9%':'8%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1041)}</span>
            <span style={{width:this.state.searchs.state==0?'9%':'8%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1087)}</span>
            {this.state.searchs.state!=0?<span style={{width:'7%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1065)}</span>:''}
            <span style={{width:this.state.searchs.state==0?'18%':'22%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1026)}</span>
        </div>
        <div style={{position:'absolute',left:0,top:110,right:0,bottom:50,backgroundColor:'#FFF',overflow:'auto'}}>
            {this.state.wholesaleItemData.map((item,key)=>{
                let collectedMoneyObj = {collectMoney:0,discountMoney:0};
                let wholesaleMoneysList = item.wholesaleMoneys;
                for(let i=0;i<wholesaleMoneysList.length;i++){
                    let wholesaleCollectDetailedList = wholesaleMoneysList[i].wholesaleCollects;
                    for(let j=0;j<wholesaleCollectDetailedList.length;j++){
                        let wholesaleCollectDetailedItem = wholesaleCollectDetailedList[j];
                        collectedMoneyObj.collectMoney = (collectedMoneyObj.collectMoney).add(wholesaleCollectDetailedItem.collectMoney);
                        collectedMoneyObj.discountMoney = (collectedMoneyObj.discountMoney).add(wholesaleCollectDetailedItem.discountMoney);
                    }
                }
                let noCollectMoney = (item.money).sub(collectedMoneyObj.collectMoney).sub(collectedMoneyObj.discountMoney);
                item.noCollectMoney = noCollectMoney;
                let colorVal='#696969';if(item.money<=0)colorVal='#F00';
                return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:!this.state.searchs.state&&item.isPrint?'rgba(255,165,0,0.1)':item.collectionFinish==0?'#FFF':'#E3F2FF'}} key={key}>
                    {this.state.searchs.state==0&&item.isChoiceChenpinglong?<i className="iconfontIndexCss" style={{width:'5%',fontSize:18,color:'#52A056',textAlign:'center',cursor:'pointer'}} onClick={()=>{//选==>不选
                      this.state.wholesaleItemData[key].isChoiceChenpinglong=0;
                      this.setState({wholesaleItemData:this.state.wholesaleItemData});
                    }}>&#xe698;</i>:this.state.searchs.state==0?<i className="iconfontIndexCss" style={{width:'5%',fontSize:18,color:'#A9A9A9',textAlign:'center',cursor:'pointer'}} onClick={()=>{//不选==>选
                      this.state.wholesaleItemData[key].isChoiceChenpinglong=1;
                      this.setState({wholesaleItemData:this.state.wholesaleItemData});
                    }}>&#xe6a7;</i>:''}
                    <span style={{width:item.state==0?'14%':'13%',color:colorVal,fontSize:16}}>&nbsp;&nbsp;{arithUtil.formatToTimeStr(new Date(item.createTime),'Y-M-D h:m')}</span>
                    {this.state.searchs.targetType!=-1&&item.cargoId?<span style={{width:item.state==0?'12%':'11%',color:colorVal,fontSize:16,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+item.targetName:''):item.targetName} {item.wholesaleNo}{stringUtil.language(1189)}</span>:
                      <span style={{width:item.state==0?'12%':'11%',color:colorVal,fontSize:16,textOverflow:'clip',whiteSpace:'nowrap',overflow:'hidden'}}>{item.wholesaleNo}</span>}
                    <span style={{width:item.state==0?'14%':'13%',color:colorVal,fontSize:16,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.customerName}{item.customerAlias?'('+item.customerAlias+')':''}</span>
                    <span style={{width:item.state==0?'7%':'6%',color:colorVal,fontSize:16,textAlign:'center'}}>{item.workerId==0?stringUtil.language(431):item.workerName}</span>
                    <span style={{width:item.state==0?'7%':'6%',color:colorVal,fontSize:16,textAlign:'center'}}>{item.operaterId==0?stringUtil.language(431):(item.operaterName?item.operaterName:'')}</span>
                    <span style={{width:item.state==0?'5%':'6%',color:colorVal,fontSize:16,textAlign:'center'}}>{item.advanceMoney?arithUtil.fenToYuan(item.advanceMoney):''}</span>
                    <span style={{width:item.state==0?'9%':'8%',color:colorVal,fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                    <span style={{width:item.state==0?'9%':'8%',color:colorVal,fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(noCollectMoney)}</span>
                    {item.state!=0&&item.collectionFinish==0?<div style={{width:'7%',height:30,backgroundColor:'#22B14C',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      if(!item.money){ToastBox.error(stringUtil.language(347));return;}
                      if(otherUtil.authorityFindCode("030102")!=null){
                        this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(item));
                        this.state.customerCollectInsertItem = {time:arithUtil.getCurrDateTime(),collects:[],collectMoney:0,discountMoney:0};
                        if(this.state.currWholesaleInfoItem.wholesaleMoneys!=null&&this.state.currWholesaleInfoItem.wholesaleMoneys.length>0){
                            for(let i=0;i<this.state.currWholesaleInfoItem.wholesaleMoneys.length;i++){
                              let collectedMoneyObj = {collectMoney:0,discountMoney:0};
                              let wholesaleMoneysList = this.state.currWholesaleInfoItem.wholesaleMoneys[i];
                              for(let j=0;j<wholesaleMoneysList.wholesaleCollects.length;j++){
                                collectedMoneyObj.collectMoney = (collectedMoneyObj.collectMoney).add(wholesaleMoneysList.wholesaleCollects[j].collectMoney);
                                collectedMoneyObj.discountMoney = (collectedMoneyObj.discountMoney).add(wholesaleMoneysList.wholesaleCollects[j].discountMoney);
                              }
                              let noCollectMoney = (wholesaleMoneysList.arrears).sub(collectedMoneyObj.collectMoney).sub(collectedMoneyObj.discountMoney);//stringUtil.language(1087)
                              this.state.currWholesaleInfoItem.wholesaleMoneys[i].noCollectMoney = noCollectMoney;
                              this.state.customerCollectInsertItem.collects.push({
                                collectMode:1,
                                wholesaleId:this.state.currWholesaleInfoItem.id,
                                targetType:wholesaleMoneysList.targetType,
                                targetId:wholesaleMoneysList.targetId,
                                discountMoney:0,
                                collectMoney:noCollectMoney,//stringUtil.language(1087)
                                targetName:wholesaleMoneysList.targetName,
                              });
                            }
                            this.collectTotal();
                        }
                        this.setState({visibleCollection:!this.state.visibleCollection});
                      }else{
                        this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(item));
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_COLLECT_URL+'/'+this.state.currWholesaleInfoItem.id,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            this.state.currWholesaleInfoItem.wholesaleCollectDOs = successResponse.data;
                            this.setState({collectionShowPop:!this.state.collectionShowPop});
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                      }
                    }}><span style={{color:'#FFF'}}>{stringUtil.language(1038)}</span></div>:item.state!=0&&item.collectionFinish!=0?<div style={{width:'7%',height:30,color:'#52A056',fontSize:16,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(item));
                      stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_COLLECT_URL+'/'+this.state.currWholesaleInfoItem.id,null,['key','get',1],(successResponse)=>{
                        if (successResponse.status==200) {
                          this.state.currWholesaleInfoItem.wholesaleCollectDOs = successResponse.data;
                          this.setState({collectionShowPop:!this.state.collectionShowPop});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}>{stringUtil.language(1004)}</div>:''}
                    {item.state==0?<div style={{width:item.state==0?'18%':'22%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                      <span style={{color:'#d2855d',textAlign:'center',fontSize:16,fontWeight:'bold',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+item.id,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(successResponse.data));
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleSaveActivity',mainStacks:[
                              {title:stringUtil.language(1128),navigatePage:''},
                              {title:stringUtil.language(376),navigatePage:'WholesaleSaveActivity',paramsPage:{currWholesaleInfoItem:this.state.currWholesaleInfoItem}}
                            ]}});
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                      }}>{stringUtil.language(1005)}</span>
                      <span style={{color:'#d2855d',textAlign:'center',fontSize:16,fontWeight:'bold',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+item.id,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(successResponse.data));
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleSavesActivity',mainStacks:[
                              {title:stringUtil.language(1128),navigatePage:''},
                              {title:stringUtil.language(376),navigatePage:'WholesaleSavesActivity',paramsPage:{currWholesaleInfoItem:this.state.currWholesaleInfoItem}}
                            ]}});
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                      }}>{stringUtil.language(1006)}</span>
                      <span style={{color:'#52A056',fontSize:16,textAlign:'center',borderLeftWidth:1,borderLeftColor:'#52A056',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+item.id,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            let printWholesaleInfo = successResponse.data;
                            if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||item.customerType!=1) printWholesaleInfo.billMoney=0;
                            printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                      }}>{stringUtil.language(1045)}</span>
                      <span style={{borderLeftWidth:1,borderLeftColor:'#52A056',color:'#F00',fontSize:16,textAlign:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(item));
                        this.setState({ delShowPop: !this.state.delShowPop, });
                      }}>{stringUtil.language(1113)}</span>
                    </div>:<div style={{width:item.state==0?'18%':'22%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                      <span style={{color:'#52A056',textAlign:'center',fontSize:16,fontWeight:'normal',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+item.id,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(successResponse.data));
                            this.setState({infoShowPop:!this.state.infoShowPop});
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                      }}>{stringUtil.language(819)}</span>
                      {otherUtil.authorityFindCode("020403")!=null?<span style={{color:'#52A056',textAlign:'center',fontSize:16,fontWeight:'normal',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+item.id,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(successResponse.data));
                            let isHaveCollectChenpinglong=0;
                            if(this.state.currWholesaleInfoItem.wholesaleMoneys&&this.state.currWholesaleInfoItem.wholesaleMoneys.length>0){
                                for (let i = 0; i < this.state.currWholesaleInfoItem.wholesaleMoneys.length; i++) {
                                    if(this.state.currWholesaleInfoItem.wholesaleMoneys[i].wholesaleCollects&&this.state.currWholesaleInfoItem.wholesaleMoneys[i].wholesaleCollects.length>0){
                                        isHaveCollectChenpinglong=1;break;
                                    }
                                }
                            }
                            if(isHaveCollectChenpinglong)this.state.currWholesaleInfoItem.isHaveCollectChenpinglong=isHaveCollectChenpinglong;
                            if(this.state.currWholesaleInfoItem.isHaveCollectChenpinglong){this.setState({delShowPop:true});
                            }else{this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleUpdateActivity',mainStacks:[
                              {title:stringUtil.language(1128),navigatePage:''},
                              {title:stringUtil.language(820),navigatePage:'WholesaleUpdateActivity',paramsPage:{wholesaleInfoItemInfo:this.state.currWholesaleInfoItem}}
                            ]}});}
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                      }}>{stringUtil.language(1160)}</span>:''}
                      {otherUtil.authorityFindCode("020404")!=null?<span style={{color:'#F00',textAlign:'center',fontSize:16,fontWeight:'normal',cursor:'pointer'}} onClick={()=>{
                        this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(item));
                        let isHaveCollectChenpinglong=0;
                        if(this.state.currWholesaleInfoItem.wholesaleMoneys&&this.state.currWholesaleInfoItem.wholesaleMoneys.length>0){
                            for (let i = 0; i < this.state.currWholesaleInfoItem.wholesaleMoneys.length; i++) {
                                if(this.state.currWholesaleInfoItem.wholesaleMoneys[i].wholesaleCollects&&this.state.currWholesaleInfoItem.wholesaleMoneys[i].wholesaleCollects.length>0){
                                    isHaveCollectChenpinglong=1;break;
                                }
                            }
                        }
                        if(isHaveCollectChenpinglong)this.state.currWholesaleInfoItem.isHaveCollectChenpinglong=isHaveCollectChenpinglong;
                        this.setState({delShowPop:true});
                      }}>{stringUtil.language(1113)}</span>:''}
                      <span style={{color:'#52A056',fontSize:16,textAlign:'center',borderLeftWidth:1,borderLeftColor:'#52A056',cursor:'pointer'}} onClick={()=>{
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+item.id,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            let printWholesaleInfo = successResponse.data;
                            if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||item.customerType!=1) printWholesaleInfo.billMoney=0;
                            printUtil.toComputerPrint(otherUtil.wholesaleNull(printWholesaleInfo));
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                      }}>{stringUtil.language(1045)}</span>
                      {/* {item.collectionFinish==0?<span style={{width:'33.3%',color:'#52A056',fontSize:16,textAlign:'center',borderLeftWidth:1,borderLeftColor:'#52A056',cursor:'pointer'}} onClick={()=>{
                        this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(item));
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_COLLECT_URL+'/'+this.state.currWholesaleInfoItem.id,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            this.state.currWholesaleInfoItem.wholesaleCollectDOs = successResponse.data;
                            this.setState({collectionShowPop:!this.state.collectionShowPop});
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                      }}>{stringUtil.language(1009)}</span>:<i className="iconfontIndexCss" style={{width:'33.3%',color:'#FF7F50',textAlign:'center',fontSize:30,borderLeftWidth:1,borderLeftColor:'#52A056',cursor:'pointer'}} onClick={()=>{
                        this.state.currWholesaleInfoItem = JSON.parse(JSON.stringify(item));
                        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_COLLECT_URL+'/'+this.state.currWholesaleInfoItem.id,null,['key','get',1],(successResponse)=>{
                          if (successResponse.status==200) {
                            this.state.currWholesaleInfoItem.wholesaleCollectDOs = successResponse.data;
                            this.setState({collectionShowPop:!this.state.collectionShowPop});
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                      }}>&#xe609;</i>} */}
                    </div>}
                </div>);
            })}
        </div>
        <div style={{height:50,position:'absolute',bottom:0,width:'98%',display:'flex',flexDirection:'row',justifyContent:this.state.totalPage>1?'space-between':'flex-end',alignItems:'center'}}>
            {this.state.totalPage>1?<Pagination total={this.state.totalNum}
                current={this.state.currentPage}
                totalPage={this.state.totalPage}
                goValue={this.state.goValue}
                pageClick={this.pageClick.bind(this)}
                goPrev={this.goPrevClick.bind(this)}
                goNext={this.goNext.bind(this)}
                switchChange={this.goSwitchChange.bind(this)}/>:''}
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
              <div style={{display:'flex',flexDirection:'row'}}>
                <span style={{color:'#F00'}}>{stringUtil.language(1142)}：{this.state.totalNum}{stringUtil.language(1189)}，</span>
                <span style={{color:'#F00',borderBottomColor:'#F00',borderBottomStyle:'solid',borderBottomWidth:1,cursor:'pointer'}} onClick={()=>{
                  if(this.state.wholesaleSearchDtoObj.advanceMoney!=0){
                    this.state.currentPageAdvance=1;
                    this.getAdvance();
                  }
                }}>{stringUtil.language(1161)}：{arithUtil.fenToYuan(this.state.wholesaleSearchDtoObj.advanceMoney)}{stringUtil.currency(1)}</span>
                <span style={{color:'#F00',marginRight:otherUtil.authorityFindCode("020402")!=null?15:0}}>，总额：{arithUtil.fenToYuan(this.state.wholesaleSearchDtoObj.money)}{stringUtil.currency(1)}，{stringUtil.language(1141)}：{arithUtil.fenToYuan(this.state.wholesaleSearchDtoObj.collectMoney)}{stringUtil.currency(1)}，{stringUtil.language(879)}{arithUtil.fenToYuan(this.state.wholesaleSearchDtoObj.discountMoney)}{stringUtil.currency(1)}，{stringUtil.language(1087)}：{arithUtil.fenToYuan(this.state.wholesaleSearchDtoObj.money-this.state.wholesaleSearchDtoObj.collectMoney-this.state.wholesaleSearchDtoObj.discountMoney)}{stringUtil.currency(1)}</span>
                <span style={{color:'#F00',borderBottomColor:'#F00',borderBottomStyle:'solid',borderBottomWidth:1,cursor:'pointer'}} onClick={()=>{
                  stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_DISCOUNT_URL,{
                    targetType:this.state.searchs.targetType,
                    targetId:this.state.searchs.targetId,
                    startTime:arithUtil.settleSub(this.state.searchs.startTime),
                    endTime:arithUtil.settleSub(this.state.searchs.endTime)
                  },['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                      this.state.discountMoneyShowPop=true;
                      this.setState({customerCollectDiscountDO:successResponse.data});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }}>{stringUtil.language(879)}{arithUtil.fenToYuan(this.state.wholesaleSearchDtoObj.discountMoney)}{stringUtil.currency(1)}</span>
                <span style={{color:'#F00',marginRight:otherUtil.authorityFindCode("020402")!=null?15:0}}>，{stringUtil.language(1087)}：{arithUtil.fenToYuan(this.state.wholesaleSearchDtoObj.money-this.state.wholesaleSearchDtoObj.collectMoney-this.state.wholesaleSearchDtoObj.discountMoney)}{stringUtil.currency(1)}</span>
              </div>
              {/* {this.state.searchs.state==0&&otherUtil.authorityFindCode("020402")!=null?<span style={arithUtil.stylesAdd(styles.myButtonSty,{width:100,fontSize:16,backgroundColor:'#2B8D53',marginRight:10})} onClick={()=>{
                // let choiceIds=[];
                // for(let i=0;i<this.state.wholesaleItemData.length;i++){
                //   if(this.state.wholesaleItemData[i].isChoiceChenpinglong==1)choiceIds.push(this.state.wholesaleItemData[i].id);
                // }
                // if(choiceIds.length==0){ToastBox.error('请选择{stringUtil.language(1189)}据');return;}
                stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_SUPPLEMENT_URL,null,['key','get',1],(successResponse)=>{
                  if (successResponse.status==200) {
                    this.setState({allWhoEditShowPop:!this.state.allWhoEditShowPop,allWhoEditIndex:(this.state.allWhoEditIndex?this.state.allWhoEditIndex:0)+1,wholesaleCommoditys:successResponse.data});
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }}>{stringUtil.language(824)}</span>:<div/>} */}
              {this.state.searchs.state==0&&otherUtil.authorityFindCode("020402")!=null?<span style={arithUtil.stylesAdd(styles.myButtonSty,{width:100,fontSize:16,backgroundColor:'#2B8D53',marginRight:10})} onClick={()=>{
                let choiceIds=[];
                for(let i=0;i<this.state.wholesaleItemData.length;i++){
                  if(this.state.wholesaleItemData[i].isChoiceChenpinglong==1)choiceIds.push(this.state.wholesaleItemData[i].id);
                }
                if(choiceIds.length==0){ToastBox.error(stringUtil.language(131));return;}
                stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_SAVE_UPDATE_MULTIPLE_URL,choiceIds,['json','put',1],(successResponse)=>{
                  if (successResponse.status==200) {
                    let wholesales=successResponse.data;
                    this.getDataList();
                    ToastBox.success(stringUtil.language(388)+wholesales.correctNum+stringUtil.language(1189)+'，'+stringUtil.language(1162)+wholesales.errNum+stringUtil.language(1189));
                    if(wholesales.ids.length>0)stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_LIST_URL,wholesales.ids,['json','post',1],(successResponse)=>{
                      if (successResponse.status==200) {
                        let wholesales=successResponse.data;
                        for(let i=0;i<wholesales.length;i++){
                          if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||wholesales[i].customerType!=1) wholesales[i].billMoney=0;
                          printUtil.toComputerPrint(otherUtil.wholesaleNull(wholesales[i]));
                        }
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }}>{stringUtil.language(821)}</span>:<div/>}
              {otherUtil.authorityFindCode("020402")!=null?<span style={arithUtil.stylesAdd(styles.myButtonSty,{width:100,fontSize:16,backgroundColor:'#2B8D53',marginRight:10})} onClick={()=>{
                if(localStorage.getItem("wholesaleLocalInfo")&&JSON.parse(localStorage.getItem("wholesaleLocalInfo")).wholesaleWay==2){//stringUtil.language(798) 20220914吕stringUtil.language(1027)放在这里，不放在stringUtil.language(1084)列表{stringUtil.language(1199)}面
                  if(this.state.wholesaleCargosData&&this.state.wholesaleCargosData.length>1){
                    this.setState({selectDiglogTagCargos: !this.state.selectDiglogTagCargos});
                  }else if(this.state.wholesaleCargosData&&this.state.wholesaleCargosData.length==1){
                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleAddCargoActivity',mainStacks:[
                      {title:stringUtil.language(1128),navigatePage:''},
                      {title:stringUtil.language(798),navigatePage:'WholesaleAddCargoActivity',paramsPage:{cargoInfoWholesale:JSON.stringify({id:this.state.wholesaleCargosData[0].id})}}
                    ]}});
                  }else{ToastBox.error(stringUtil.language(377)+'！');}
                }else{//stringUtil.language(797)
                  this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleAddActivity',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(816),navigatePage:'WholesaleAddActivity',paramsPage:stringUtil.language(816)+'参数'}
                  ]}});
                }
              }}>{stringUtil.language(822)}</span>:<div/>}
              {otherUtil.authorityFindCode("020402")!=null?<span style={arithUtil.stylesAdd(styles.myButtonSty,{width:100,fontSize:16,backgroundColor:'#2B8D53',marginRight:10})} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleAddsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(816),navigatePage:'WholesaleAddsActivity',paramsPage:stringUtil.language(816)+'参数'}
                ]}});
              }}>{stringUtil.language(823)}</span>:<div/>}
              {otherUtil.authorityFindCode("020402")!=null?<span style={arithUtil.stylesAdd(styles.myButtonSty,{width:100,fontSize:16,backgroundColor:'#2B8D53'})} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleAddssActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(816),navigatePage:'WholesaleAddssActivity',paramsPage:stringUtil.language(816)+'参数'}
                ]}});
              }}>{stringUtil.language(1270)}</span>:<div/>}
            </div>
        </div>
        <MaskModal title={stringUtil.language(824)} visible={this.state.allWhoEditShowPop?true:false} width={700} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
            content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}} key={this.state.allWhoEditIndex?this.state.allWhoEditIndex:0}>
                <div style={{height:'5%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'25%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1048)}</span>
                    <span style={{width:'25%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1020)}</span>
                    <span style={{width:'10%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                    <span style={{width:'19%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                    <span style={{width:'21%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                </div>
                <div style={{height:'95%',overflow:'auto'}}>
                  {this.state.wholesaleCommoditys?this.state.wholesaleCommoditys.map((item,key)=>{
                    return(<div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                      <div style={{width:'25%',display:'flex',flexDirection:'column'}}>
                          <span style={{fontSize:14,fontWeight:'bold',paddingLeft:5}}>{item.customerName}</span>
                          <span style={{color:'#FFF',fontSize:10}}> </span>
                      </div>
                      <div style={{width:'25%',display:'flex',flexDirection:'column'}}>
                          <span style={{fontSize:14,fontWeight:'bold'}}>{item.commodityName}</span>
                          <span style={{color:'#C0C0C0',fontSize:10}}>{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,6):''):stringUtil.subStr(item.targetName,5)} {stringUtil.language(1175)}{item.cargoNo}{stringUtil.language(1176)}</span>
                      </div>
                      <div style={{width:'10%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                          {/* <span style={{color:'#696969',fontSize:13,fontWeight:'bold'}}>{(item.commodityUnitScale>1?((item.number).div(item.commodityUnitScale,2))+item.commodityUnitSlave:'')+item.number+item.commodityUnitMaster}</span> */}
                          <span style={{color:'#696969',fontSize:13,fontWeight:'bold'}}>{item.number+item.commodityUnitMaster}</span>
                          <span style={{color:'#FFF',fontSize:10}}> </span>
                      </div>
                      <input style={{width:'19%',height:'35px',lineHeight:'35px',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' placeholder={stringUtil.language(1022)+'('+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))+')'} defaultValue={item.totalWeight?arithUtil.keToWeight(item.totalWeight,this.state.weightMode):''} onChange={(event)=>{
                          this.state.wholesaleCommoditys[key].totalWeight = arithUtil.weightToKe(event.target.value!=''?parseFloat(event.target.value+''):0,this.state.weightMode);
                          let numberVal = parseFloat(this.state.wholesaleCommoditys[key].number),goodsMoneyVal = 0;
                          let skinWeightVal = parseFloat(this.state.wholesaleCommoditys[key].skinWeight);
                          let reduceWeightVal = numberVal.mul(this.state.wholesaleCommoditys[key].commodityReduce);//克
                          this.state.wholesaleCommoditys[key].weight=(this.state.wholesaleCommoditys[key].totalWeight).sub(skinWeightVal).sub(reduceWeightVal);
                          if (this.state.wholesaleCommoditys[key].mode==0) {
                              goodsMoneyVal = (parseFloat(this.state.wholesaleCommoditys[key].unitPrice)).mul(arithUtil.keToWeight(this.state.wholesaleCommoditys[key].weight,0));
                          }else{goodsMoneyVal = (parseFloat(this.state.wholesaleCommoditys[key].unitPrice)).mul(numberVal);}
                          this.state.wholesaleCommoditys[key].goodsMoney = goodsMoneyVal.round(0);
                          if(this.state.wholesaleCommoditys[key].mode==1&&localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
                              this.state.wholesaleCommoditys[key].totalGoodMoney=(parseFloat(this.state.wholesaleCommoditys[key].totalUnitPrice)).mul(numberVal).round(0);
                          }else{}//{stringUtil.language(1015)}
                      }}/>
                      {/* <input style={{width:'21%',height:'35px',lineHeight:'35px',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' placeholder={item.mode?(item.commodityUnitScale>1?item.commodityUnitSlave:item.commodityUnitMaster)+'{stringUtil.language(1050)}':'{stringUtil.language(1196)}{stringUtil.language(1050)}'} defaultValue={item.unitPrice?(item.mode?(item.commodityUnitScale>1?arithUtil.fenToYuan(arithUtil.unitPriceToNum(item.unitPrice,item.commodityUnitScale,0,1),2).round(0,1):arithUtil.fenToYuan(item.unitPrice,2)):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)):''} onChange={(event)=>{ */}
                      <input style={{width:'21%',height:'35px',lineHeight:'35px',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' placeholder={item.mode?item.commodityUnitMaster+stringUtil.language(1050):stringUtil.language(1196)+stringUtil.language(1050)} defaultValue={item.unitPrice?(item.mode?arithUtil.fenToYuan(item.unitPrice,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)):''} onChange={(event)=>{
                          let numberVal = parseFloat(this.state.wholesaleCommoditys[key].number),goodsMoneyVal = 0;
                          if(this.state.wholesaleCommoditys[key].mode==0){
                              this.state.wholesaleCommoditys[key].unitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value!=''?parseFloat(event.target.value+''):0),this.state.weightMode,0);
                              this.state.wholesaleCommoditys[key].totalUnitPrice = 0;
                              goodsMoneyVal = (this.state.wholesaleCommoditys[key].unitPrice).mul(arithUtil.keToWeight(this.state.wholesaleCommoditys[key].weight,0));
                          }else{
                              this.state.wholesaleCommoditys[key].unitPrice = arithUtil.yuanToFen(event.target.value!=''?parseFloat(event.target.value+''):0);
                              this.state.wholesaleCommoditys[key].totalUnitPrice = arithUtil.yuanToFen(event.target.value!=''?parseFloat(event.target.value+''):0);
                              goodsMoneyVal = (this.state.wholesaleCommoditys[key].unitPrice).mul(numberVal);
                          }
                          this.state.wholesaleCommoditys[key].goodsMoney = goodsMoneyVal.round(0);
                          if(this.state.wholesaleCommoditys[key].mode==1&&localStorage.getItem('wholesalePriceType')==1){//stringUtil.language(1016)
                              this.state.wholesaleCommoditys[key].totalGoodMoney=(this.state.wholesaleCommoditys[key].totalUnitPrice).mul(numberVal).round(0);
                          }else{}//{stringUtil.language(1015)}
                      }}/>
                    </div>);
                  }):''}
                </div>
            </div>} okBnTitle={stringUtil.language(1027)} okBnColor='#2B8D53' closeBnTitle={stringUtil.language(1145)} closeBnColor='#D2855D'
            okBnEvents={(event) => {
              let wholesaleCommoditys=[];
              for(let i=0;i<this.state.wholesaleCommoditys.length;i++){
                  if(this.state.wholesaleCommoditys[i].unitPrice){
                      if(this.state.wholesaleCommoditys[i].mode&&!this.state.wholesaleCommoditys[i].number)continue;//stringUtil.language(1115)，必须要有{stringUtil.language(1165)}
                      if(!this.state.wholesaleCommoditys[i].mode&&!this.state.wholesaleCommoditys[i].weight)continue;//stringUtil.language(1114)，必须要有{stringUtil.language(1168)}
                      this.state.wholesaleCommoditys[i].costs=[];
                      if(this.state.wholesaleCommoditys[i].commodityCosts==null)this.state.wholesaleCommoditys[i].commodityCosts=[];
                      for(let j=0;j<this.state.wholesaleCommoditys[i].commodityCosts.length;j++){
                          let costsItem = this.state.wholesaleCommoditys[i].commodityCosts[j],commodityCostVal=JSON.parse(JSON.stringify(this.state.wholesaleCommoditys[i].commodityCosts[j]));
                          if(costsItem.money==null)costsItem.money=0;commodityCostVal.money=0;
                          if (costsItem.calMode==0) {//stringUtil.language(868)
                              commodityCostVal.money = (this.state.wholesaleCommoditys[i].number).mul(costsItem.money).round();
                          }else if (costsItem.calMode==1) {//stringUtil.language(869)
                              commodityCostVal.money = (arithUtil.keToWeight(this.state.wholesaleCommoditys[i].weight,0)).mul(costsItem.money).roundDouble();
                          }else if (costsItem.calMode==2) {//stringUtil.language(396)
                              commodityCostVal.money = (this.state.wholesaleCommoditys[i].goodsMoney).mul(costsItem.money).mul(0.01).round();
                          }else{
                              commodityCostVal.money = parseFloat(costsItem.money).round();
                          }
                          if(commodityCostVal.money)this.state.wholesaleCommoditys[i].costs.push(commodityCostVal);
                      }
                      wholesaleCommoditys.push(this.state.wholesaleCommoditys[i]);
                  }
              }
              let wholesaleSupplementVO={commodities:[],state:1};
              for(let i=0;i<wholesaleCommoditys.length;i++){
                  let commodityTemp = {id:wholesaleCommoditys[i].id,costs:[]};
                  commodityTemp.supplementaryCommodity = wholesaleCommoditys[i].supplementaryCommodity?1:0;
                  commodityTemp.buyerCode = wholesaleCommoditys[i].buyerCode;//global.currentStallItem.buyerCode
                  commodityTemp.cargoId = wholesaleCommoditys[i].cargoId;
                  commodityTemp.targetType = wholesaleCommoditys[i].targetType;
                  commodityTemp.targetId = wholesaleCommoditys[i].targetId;
                  commodityTemp.commodityId = wholesaleCommoditys[i].commodityId;
                  commodityTemp.cargoCommodityId = wholesaleCommoditys[i].cargoCommodityId;
                  commodityTemp.standard = wholesaleCommoditys[i].standard;
                  commodityTemp.number = wholesaleCommoditys[i].number;
                  commodityTemp.totalWeight = wholesaleCommoditys[i].totalWeight;
                  commodityTemp.skinWeight = wholesaleCommoditys[i].skinWeight;
                  commodityTemp.reduceWeight = wholesaleCommoditys[i].reduceWeight;
                  commodityTemp.weight = wholesaleCommoditys[i].weight;
                  commodityTemp.unitPrice = wholesaleCommoditys[i].unitPrice;
                  commodityTemp.totalUnitPrice = wholesaleCommoditys[i].totalUnitPrice;
                  commodityTemp.mode = wholesaleCommoditys[i].mode;
                  commodityTemp.goodsMoney = wholesaleCommoditys[i].goodsMoney;
                  commodityTemp.describe = wholesaleCommoditys[i].describe?wholesaleCommoditys[i].describe:'';
                  if (wholesaleCommoditys[i].costs==null)wholesaleCommoditys[i].costs=[];
                  for(let j=0;j<wholesaleCommoditys[i].costs.length;j++){
                      let costsSource = JSON.parse(JSON.stringify(wholesaleCommoditys[i].costs[j]));
                      commodityTemp.costs.push({
                          costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                          money:costsSource.money!=null?costsSource.money:0,
                      });
                  }
                  wholesaleSupplementVO.commodities.push(commodityTemp);
              }
              stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_SUPPLEMENT_URL,wholesaleSupplementVO,['json','put',1],(successResponse)=>{
                  if (successResponse.status==200) {
                      if(successResponse.data.length){stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_LIST_URL,successResponse.data,['json','post',1],(successResponse)=>{
                          if (successResponse.status==200) {
                              let wholesales=successResponse.data;
                              for(let i=0;i<wholesales.length;i++){
                                  if((global.shopConfigInfo!=null&&global.shopConfigInfo.isRound==1)||wholesales[i].customerType!=1) wholesales[i].billMoney=0;
                                  printUtil.toComputerPrint(otherUtil.wholesaleNull(wholesales[i]));
                              }
                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});}
                      this.state.allWhoEditShowPop=false;
                      ToastBox.success(stringUtil.language(388));
                      this.getDataList();
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
              },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {
              let wholesaleCommoditys=[];
              for(let i=0;i<this.state.wholesaleCommoditys.length;i++){
                  this.state.wholesaleCommoditys[i].costs=[];
                  if(this.state.wholesaleCommoditys[i].commodityCosts==null)this.state.wholesaleCommoditys[i].commodityCosts=[];
                  for(let j=0;j<this.state.wholesaleCommoditys[i].commodityCosts.length;j++){
                      let costsItem = this.state.wholesaleCommoditys[i].commodityCosts[j],commodityCostVal=JSON.parse(JSON.stringify(this.state.wholesaleCommoditys[i].commodityCosts[j]));
                      if(costsItem.money==null)costsItem.money=0;commodityCostVal.money=0;
                      if (costsItem.calMode==0) {//stringUtil.language(868)
                          commodityCostVal.money = (this.state.wholesaleCommoditys[i].number).mul(costsItem.money).round();
                      }else if (costsItem.calMode==1) {//stringUtil.language(869)
                          commodityCostVal.money = (arithUtil.keToWeight(this.state.wholesaleCommoditys[i].weight,0)).mul(costsItem.money).roundDouble();
                      }else if (costsItem.calMode==2) {//stringUtil.language(396)
                          commodityCostVal.money = (this.state.wholesaleCommoditys[i].goodsMoney).mul(costsItem.money).mul(0.01).round();
                      }else{
                          commodityCostVal.money = parseFloat(costsItem.money).round();
                      }
                      if(commodityCostVal.money)this.state.wholesaleCommoditys[i].costs.push(commodityCostVal);
                  }
                  wholesaleCommoditys.push(this.state.wholesaleCommoditys[i]);
              }
              let wholesaleSupplementVO={commodities:[],state:0};
              for(let i=0;i<wholesaleCommoditys.length;i++){
                  let commodityTemp = {id:wholesaleCommoditys[i].id,costs:[]};
                  commodityTemp.supplementaryCommodity = wholesaleCommoditys[i].supplementaryCommodity?1:0;
                  commodityTemp.buyerCode = wholesaleCommoditys[i].buyerCode;
                  commodityTemp.cargoId = wholesaleCommoditys[i].cargoId;
                  commodityTemp.targetType = wholesaleCommoditys[i].targetType;
                  commodityTemp.targetId = wholesaleCommoditys[i].targetId;
                  commodityTemp.commodityId = wholesaleCommoditys[i].commodityId;
                  commodityTemp.cargoCommodityId = wholesaleCommoditys[i].cargoCommodityId;
                  commodityTemp.standard = wholesaleCommoditys[i].standard;
                  commodityTemp.number = wholesaleCommoditys[i].number;
                  commodityTemp.totalWeight = wholesaleCommoditys[i].totalWeight;
                  commodityTemp.skinWeight = wholesaleCommoditys[i].skinWeight;
                  commodityTemp.reduceWeight = wholesaleCommoditys[i].reduceWeight;
                  commodityTemp.weight = wholesaleCommoditys[i].weight;
                  commodityTemp.unitPrice = wholesaleCommoditys[i].unitPrice;
                  commodityTemp.totalUnitPrice = wholesaleCommoditys[i].totalUnitPrice;
                  commodityTemp.mode = wholesaleCommoditys[i].mode;
                  commodityTemp.goodsMoney = wholesaleCommoditys[i].goodsMoney;
                  commodityTemp.describe = wholesaleCommoditys[i].describe?wholesaleCommoditys[i].describe:'';
                  if (wholesaleCommoditys[i].costs==null)wholesaleCommoditys[i].costs=[];
                  for(let j=0;j<wholesaleCommoditys[i].costs.length;j++){
                      let costsSource = JSON.parse(JSON.stringify(wholesaleCommoditys[i].costs[j]));
                      commodityTemp.costs.push({
                          costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                          money:costsSource.money!=null?costsSource.money:0,
                      });
                  }
                  wholesaleSupplementVO.commodities.push(commodityTemp);
              }
              stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_SUPPLEMENT_URL,wholesaleSupplementVO,['json','put',1],(successResponse)=>{
                  if (successResponse.status==200) {
                    this.state.allWhoEditShowPop=false;
                    ToastBox.success(stringUtil.language(562));
                    this.getDataList();
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
              },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnTopEvents={()=>{this.setState({allWhoEditShowPop:false});}}/>
        <MaskModal title={stringUtil.language(184)} visible={this.state.selectDiglogTagCargos} width={350} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
            content={<div style={{width:'100%',height:'100%',paddingLeft:10,paddingRight:10,overflow:'auto'}}>
                    {this.state.wholesaleCargosData?this.state.wholesaleCargosData.map((item,key)=>{
                      return(<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                        this.setState({selectDiglogTagCargos:false},()=>{
                          this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleAddCargoActivity',mainStacks:[
                            {title:stringUtil.language(1128),navigatePage:''},
                            {title:stringUtil.language(798),navigatePage:'WholesaleAddCargoActivity',paramsPage:{cargoInfoWholesale:JSON.stringify({id:item.id})}}
                          ]}});
                        });
                      }}><span>{item.cargoMode==1?stringUtil.language(426)+(item.targetName?'_'+item.targetName:''):item.targetName} {stringUtil.language(1175)}{item.cargoNo}{stringUtil.language(1176)}</span></div>)
                    }):''}
                </div>}
            closeBnTopEvents={() => {this.setState({ selectDiglogTagCargos: false, });}}/>
        <MaskModal title={stringUtil.language(348)} visible={this.state.visibleCollection} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
            content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}} key={this.state.currWholesaleInfoItem.id}>
                <div style={{width:'100%',height:'15%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                    <span style={{fontSize:16,paddingLeft:10}}>{stringUtil.language(903)}{this.state.currWholesaleInfoItem.customerName+stringUtil.subStr(this.state.currWholesaleInfoItem.customerAlias?this.state.currWholesaleInfoItem.customerAlias:'',5)}</span>
                    <div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:60,color:'#A9A9A9'}}>{stringUtil.language(904)}</span>
                            <DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.customerCollectInsertItem.time)} onChange={(date) => {
                                this.state.customerCollectInsertItem.time = arithUtil.formatToTimeStr(date,'Y-M-D')+arithUtil.formatToTimeStr(new Date((this.state.currWholesaleInfoItem.finishTime).add(30000)),' h:m:s');
                                this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
                            }} />
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:16}}>{stringUtil.language(880)}</span>
                            <span style={{fontSize:25,color:'#F00',fontWeight:'bold'}}>{this.state.currWholesaleInfoItem.noCollectMoney!=null?arithUtil.fenToYuan(this.state.currWholesaleInfoItem.noCollectMoney):0}{stringUtil.currency(1)}</span>
                        </div>
                    </div>
                </div>
                <span style={{fontSize:12,color:'#D3D3D3',textAlignVertical:'center'}}>  ● {stringUtil.language(17)}</span>
                <div style={{height:'55%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                    {this.state.currWholesaleInfoItem.wholesaleMoneys!=null?this.state.currWholesaleInfoItem.wholesaleMoneys.map((item,key)=>{
                        return(<div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} key={key}>
                            <span style={{color:'#52A056',fontWeight:'bold'}}>&nbsp;&nbsp;{item.targetType==1?stringUtil.language(426):stringUtil.subStr(item.targetName,7)}</span>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>¥ {arithUtil.fenToYuan(item.noCollectMoney)}</span>&nbsp;&nbsp;&nbsp;
                                <span>{stringUtil.language(905)}</span>
                                <input ref={'collectMoneyVal'+key} style={{width:90,height:'35px',lineHeight:'35px',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' placeholder='0' defaultValue={this.state.customerCollectInsertItem.collects[key]!=null&&this.state.customerCollectInsertItem.collects[key].collectMoney?arithUtil.fenToYuan(this.state.customerCollectInsertItem.collects[key].collectMoney):''} onChange={(event)=>{
                                    this.state.customerCollectInsertItem.collects[key].collectMoney = event.target.value!=''?arithUtil.yuanToFen(event.target.value):0;
                                    this.collectTotal();
                                }}/><span>{stringUtil.currency(1)}，</span>
                                <span>{stringUtil.language(879)}</span>
                                <input style={{width:90,height:'35px',lineHeight:'35px',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' placeholder='0' defaultValue={this.state.customerCollectInsertItem.collects[key]!=null&&this.state.customerCollectInsertItem.collects[key].discountMoney?arithUtil.fenToYuan(this.state.customerCollectInsertItem.collects[key].discountMoney):''} onChange={(event)=>{
                                    this.state.customerCollectInsertItem.collects[key].discountMoney = event.target.value!=''?arithUtil.yuanToFen(event.target.value):0;
                                    let collectAddDiscount = (this.state.customerCollectInsertItem.collects[key].collectMoney).add(this.state.customerCollectInsertItem.collects[key].discountMoney);
                                    if(collectAddDiscount>item.noCollectMoney){
                                        this.state.customerCollectInsertItem.collects[key].collectMoney = (item.noCollectMoney).sub(this.state.customerCollectInsertItem.collects[key].discountMoney);
                                        this.refs['collectMoneyVal'+key].value=arithUtil.fenToYuan(this.state.customerCollectInsertItem.collects[key].collectMoney);
                                    }
                                    this.collectTotal();
                                }}/><span>{stringUtil.currency(1)}&nbsp;&nbsp;</span>
                            </div>
                        </div>)
                    }):<div/>}
                </div>
                <div style={{height:'30%'}}>
                    {/* <span style={{height:arithUtil.pxToDp(30),textAlignVertical:'center',color:'#D2855D',fontWeight:'bold'}}>总计：{stringUtil.language(1080)} {arithUtil.fenToYuan(this.state.customerCollectInsertItem.collectMoney)} {stringUtil.currency(1)}{stringUtil.language(906)} {arithUtil.fenToYuan(this.state.customerCollectInsertItem.discountMoney)} {stringUtil.currency(1)}</span> */}
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{width:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}}>{/* 水平滚动 */}
                            {this.state.accountsAll.map((item,key)=>{
                                let accountIndexKey = this.state.customerCollectInsertItem.accountIndex;
                                return(<div style={{width:65,height:65,borderStyle:'solid',borderWidth:1,borderColor:'#DCDCDC',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                    this.state.customerCollectInsertItem.accountId = item.id;
                                    this.state.customerCollectInsertItem.accountIndex = key;
                                    this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
                                }}>{item.type==0?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#F4B015':'#A9A9A9'}}>&#xe632;</i>:
                                    item.type==1?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#3BCA72':'#A9A9A9'}}>&#xe605;</i>:
                                    item.type==2?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#5A9EF7':'#A9A9A9'}}>&#xe65f;</i>:
                                    <i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#CC4B46':'#A9A9A9'}}>&#xe607;</i>}
                                    <span style={{color:(accountIndexKey!=null&&accountIndexKey==key)?'#696969':'#A9A9A9'}}>{item.name}</span>
                                </div>)
                            })}
                        </div>
                        <span style={{width:'70px',height:'70px',color:'#FFF',lineHeight:'70px',borderRadius:3,backgroundColor:'#FF8C00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:10}} onClick={()=>{
                            if (this.state.currWholesaleInfoItem.noCollectMoney&&this.state.customerCollectInsertItem.collectMoney==0&&this.state.customerCollectInsertItem.discountMoney==0) {
                                ToastBox.error(stringUtil.language(96));return;
                            }
                            if (this.state.customerCollectInsertItem.accountId==null) {
                                ToastBox.error(stringUtil.language(95));return;
                            }
                            let customerCollectInsertVo = {collects:[]};
                            customerCollectInsertVo.time = arithUtil.timeToNumber(this.state.customerCollectInsertItem.time);
                            customerCollectInsertVo.customerId = this.state.currWholesaleInfoItem.customerId;
                            customerCollectInsertVo.accountId = this.state.customerCollectInsertItem.accountId;
                            customerCollectInsertVo.discountMoney = this.state.customerCollectInsertItem.discountMoney;
                            customerCollectInsertVo.collectMoney = this.state.customerCollectInsertItem.collectMoney;
                            customerCollectInsertVo.describe = this.state.customerCollectInsertItem.describe!=null?this.state.customerCollectInsertItem.describe:'';
                            for(let i=0;i<this.state.customerCollectInsertItem.collects.length;i++){
                                let temp = this.state.customerCollectInsertItem.collects[i];
                                if ((temp.collectMoney!=null&&!isNaN(temp.collectMoney)&&temp.collectMoney!=0) || (temp.discountMoney!=null&&!isNaN(temp.discountMoney)&&temp.discountMoney!=0)) {
                                    let collectDetailedTemp = {
                                        collectMode:1,//0.stringUtil.language(913) 1stringUtil.language(1185)stringUtil.language(865)
                                        wholesaleId:temp.wholesaleId,
                                        targetType:temp.targetType,
                                        targetId:temp.targetId,
                                        discountMoney:temp.discountMoney,
                                        collectMoney:temp.collectMoney
                                    };
                                    customerCollectInsertVo.collects.push(collectDetailedTemp);
                                }
                            }
                            this.setState({visibleCollection:false});
                            stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL,customerCollectInsertVo,['json','post',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    this.getDataList();
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>{stringUtil.language(859)}</span>
                    </div>
                </div>
            </div>}closeBnTopEvents={()=>{this.setState({visibleCollection:false});}}/>
        <MaskModal title={stringUtil.language(1007)} visible={this.state.discountMoneyShowPop?true:false} width={arithUtil.pxToDp(700)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
            content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column'}} id='printArea'>
              <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                  <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}{stringUtil.language(543)}</span>
                  <span style={{fontSize:17}}>{arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D')}{stringUtil.language(1174)}{arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D')}，{this.state.searchs.targetId==-1?stringUtil.language(517):this.state.searchs.targetName}</span>
              </div>
              <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'21%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(671)}</span>
                <span style={{width:'26%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1039)}</span>
                <span style={{width:'20%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1048)}</span>
                <span style={{width:'20%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1049)}</span>
                <span style={{width:'13%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1042)}</span>
              </div>
              <div style={{display:'flex',flexDirection:'column',overflow:'auto'}}>
                {(()=>{this.state.totalInfoDicount={discountMoney:0}})()}
                {this.state.customerCollectDiscountDO.map((item,key)=>{
                  this.state.totalInfoDicount.discountMoney=(this.state.totalInfoDicount.discountMoney).add(item.discountMoney);
                  return(<div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                    stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL+'/'+item.customerCollectId,null,['key','get',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          this.setState({currCustomerCollectItem:successResponse.data,collectInfoShowPop:!this.state.collectInfoShowPop});
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }}><span style={{width:'21%',fontSize:13,paddingLeft:10}}>KD.{item.customerCollectNo}</span>
                    <span style={{width:'26%',fontSize:13}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m')}</span>
                    <span style={{width:'20%',fontSize:11}}>{item.customerName}</span>
                    <span style={{width:'20%',fontSize:11}}>{item.targetName?item.targetName:item.targetType==1?stringUtil.language(426):''}</span>
                    <span style={{width:'13%',fontSize:13,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(item.discountMoney)}</span>
                  </div>);
                })}
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{width:'87%'}}/>
                  <span style={{width:'13%',color:'#D2855D',fontSize:13,textAlign:'center'}}>{stringUtil.language(890)}{arithUtil.fenToYuan(this.state.totalInfoDicount.discountMoney)}</span>
                </div>
              </div>
            </div>} okBnTitle={stringUtil.language(1045)} okBnEvents={()=>{
              const printArea = document.getElementById('printArea');
              const iframe = document.createElement('IFRAME');
              let doc = null;
              iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
              document.body.appendChild(iframe);
              doc = iframe.contentWindow.document;
              doc.write(printArea.innerHTML);
              doc.close();
              iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始{stringUtil.language(1045)}
              iframe.contentWindow.print();
              if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
            }} closeBnEvents={() => {this.setState({discountMoneyShowPop:false})}}/>
        <MaskModal title={stringUtil.language(1008)} visible={this.state.advanceMoneyShowPop} width={arithUtil.pxToDp(600)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
            content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column'}}>
              <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'25%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(917)}</span>
                <span style={{width:'30%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1048)}</span>
                <span style={{width:'30%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1039)}</span>
                <span style={{width:'15%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
              </div>
              <div style={{display:'flex',flexGrow:1,flexDirection:'column',overflow:'auto'}}>
                  {this.state.advancesAll.map((item,key)=>{
                      return(<div style={{height:45,display:'flex',flexDirection:'column',justifyContent:'center'}} key={key}>
                        <div style={{display:'flex',flexDirection:'row'}}>
                          <span style={{width:'25%',fontSize:13,paddingLeft:5}}>KD.{item.wholesaleNo}</span>
                          <span style={{width:'30%',fontSize:13,fontWeight:'bold'}}>{stringUtil.subStr(item.customerName,7)}</span>
                          <span style={{width:'30%',fontSize:11,textAlign:'center'}}>{arithUtil.formatToTimeStr(new Date(item.createTime),'Y-M-D h:m')}</span>
                          <span style={{width:'15%',fontSize:13,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(item.advanceMoney)}</span>
                        </div>
                        <span style={{color:'#FF8C00',fontSize:10,paddingLeft:5}}>{item.describe?item.describe:''}</span>
                      </div>);
                  })}
              </div>
              {this.state.totalPageAdvance>1?<div style={{height:50}}>
                  <Pagination total={this.state.totalNumAdvance}
                      current={this.state.currentPageAdvance}
                      totalPage={this.state.totalPageAdvance}
                      goValue={this.state.goValueAdvance}
                      pageClick={this.pageClickAdvance.bind(this)}
                      goPrev={this.goPrevClickAdvance.bind(this)}
                      goNext={this.goNextAdvance.bind(this)}
                      switchChange={this.goSwitchChangeAdvance.bind(this)}/>
              </div>:''}
            </div>} closeBnTopEvents={() => {this.setState({advanceMoneyShowPop:false})}}/>
        <MaskModal title={stringUtil.language(917)} visible={this.state.infoShowPop} width={750} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
            content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
            <div style={{width:'100%',height:'8%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <span style={{fontWeight:'bold'}}>{stringUtil.language(903)}{this.state.currWholesaleInfoItem.customerName+(this.state.currWholesaleInfoItem.customerAlias?this.state.currWholesaleInfoItem.customerAlias:'')}</span>
                <span style={{color:'#A9A9A9',fontSize:12}}>{arithUtil.formatToTimeStr(new Date(this.state.currWholesaleInfoItem.createTime),'Y-M-D h:m:s')}</span>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <span></span>
                <span style={{color:'#F00',fontWeight:'bold'}}>NO.{this.state.currWholesaleInfoItem.wholesaleNo}</span>
              </div>
            </div>
            <div style={{height:'5%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'28%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(518)}</span>
                <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1165)}</span>
                <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                <span style={{width:'12%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1068)}</span>
                <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1069)}</span>
            </div>
            <div style={{width:'100%',height:'65%',overflow:'auto'}}>
              {(()=>{this.state.totalWholesaleInfo={totalNumber:0,totalWeight:0,totalGoodMoney:0,totalTotalOtherMoney:0};})()}
              {this.state.currWholesaleInfoItem.commodities!=null?this.state.currWholesaleInfoItem.commodities.map((item,key)=>{
                let totalOtherMoney = 0;
                for(let i=0;i<item.costs.length;i++){
                  let costsItem = item.costs[i].money;
                  totalOtherMoney = totalOtherMoney.add(costsItem);
                }
                this.state.totalWholesaleInfo.totalNumber = (this.state.totalWholesaleInfo.totalNumber).add(item.number);
                this.state.totalWholesaleInfo.totalWeight = (this.state.totalWholesaleInfo.totalWeight).add(item.weight);
                this.state.totalWholesaleInfo.totalGoodMoney = (this.state.totalWholesaleInfo.totalGoodMoney).add(item.goodsMoney);
                this.state.totalWholesaleInfo.totalTotalOtherMoney = (this.state.totalWholesaleInfo.totalTotalOtherMoney).add(totalOtherMoney);
                return(<div style={{height:45,display:'flex',flexDirection:'column',justifyContent:'center'}} key={key}>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <span style={{width:'28%',fontSize:16,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commodityCode?'('+item.commodityCode+')':''}{item.commodityName}</span>
                      <span style={{width:'15%',fontSize:16,textAlign:'center'}}>{item.number}</span>
                      <span style={{width:'15%',textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                      <span style={{width:'12%',fontSize:16,textAlign:'center'}}>{(item.mode==0)?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(item.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                      <span style={{width:'15%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                      <span style={{width:'15%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(totalOtherMoney)}</span>
                  </div>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{width:'35%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                      {item.commoditySpecs?<span style={{color:'#295A2C',fontSize:10,fontWeight:'bold',marginRight:15}}>{item.commoditySpecs}</span>:''}
                      <span style={{width:0,flexGrow:1,color:'#52A056',fontSize:10,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.targetName?item.targetName:item.targetType==1?stringUtil.language(426):''} {stringUtil.language(1237,item.cargoNo)}</span>
                    </div>
                    {item.mode==0?<span style={{width:'23%',color:'#52A056',fontSize:10,textAlign:'right'}}>{arithUtil.keToWeight(item.totalWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.skinWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.reduceWeight,this.state.weightMode)}</span>:
                    <span style={{width:'23%',color:'#52A056',fontSize:10,textAlign:'right'}}>{item.number+'X'+arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>}
                    <div style={{width:'42%',display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                      <span style={{fontSize:10,color:'#52a056'}}>{item.describe}</span>
                      {!item.mode&&item.ownerUnitPrice?<span style={{color:'#F00',fontSize:10,marginLeft:5}}>{stringUtil.language(1255)}:{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.ownerUnitPrice,2),0,this.state.weightMode)}</span>:''}
                      {item.mode&&item.ownerUnitPrice?<span style={{color:'#F00',fontSize:10,marginLeft:5}}>{stringUtil.language(1255)}:{arithUtil.fenToYuan(item.ownerUnitPrice,2)+'/'+(item.mode==2?item.commodityUnitSlave:item.commodityUnitMaster)}</span>:''}
                      <span style={{color:'#52A056',fontSize:10,marginLeft:5}}>{item.buyerName?item.buyerName:''}</span>
                    </div>
                  </div>
                </div>)
              }):<div/>}
            </div>
            <div style={{width:'100%',height:'22%',paddingBottom:5}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                <span style={{width:'28%',color:'#D2855D',fontSize:16,textAlign:'center'}}>{stringUtil.language(890)}</span>
                <span style={{width:'15%',color:'#D2855D',fontSize:16,textAlign:'center'}}>{this.state.totalWholesaleInfo.totalNumber}</span>
                <span style={{width:'15%',color:'#D2855D',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalWholesaleInfo.totalWeight,this.state.weightMode)}</span>
                <span style={{width:'12%',color:'#D2855D',fontSize:16,textAlign:'center'}}></span>
                <span style={{width:'15%',color:'#D2855D',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalWholesaleInfo.totalGoodMoney)}</span>
                <span style={{width:'15%',color:'#D2855D',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalWholesaleInfo.totalTotalOtherMoney)}</span>
              </div>
              <div style={{width:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'}}>{/* 水平滚动 */}
                  {this.state.currWholesaleInfoItem.costs!=null?this.state.currWholesaleInfoItem.costs.map((item,key)=>{
                      return(<span style={{fontSize:12,marginLeft:key!=0?arithUtil.pxToDp(30):0}} key={key}>{item.costClauseName}：{arithUtil.fenToYuan(item.money)}</span>)
                  }):<div/>}
                  <span style={{fontSize:12,marginLeft:this.state.currWholesaleInfoItem.costs!=null&&this.state.currWholesaleInfoItem.costs.length>0?arithUtil.pxToDp(30):0}}>{stringUtil.language(1161)}：{arithUtil.fenToYuan(this.state.currWholesaleInfoItem.advanceMoney)}</span>
              </div>
              {(()=>{this.state.yakuangxinxi={number:0,money:0};
                if(this.state.currWholesaleInfoItem.commodities)for(let i=0;i<this.state.currWholesaleInfoItem.commodities.length;i++){
                  if(this.state.currWholesaleInfoItem.commodities[i].systemCommodityId==-1){
                    this.state.yakuangxinxi.number=this.state.yakuangxinxi.number+this.state.currWholesaleInfoItem.commodities[i].number;
                    this.state.yakuangxinxi.money=(this.state.yakuangxinxi.money).add(this.state.currWholesaleInfoItem.commodities[i].goodsMoney);
                  }
                }
              })()}
              {this.state.yakuangxinxi.number?<span style={{fontSize:14}}>{stringUtil.language(1157)}:{this.state.yakuangxinxi.number}个,{arithUtil.fenToYuan(this.state.yakuangxinxi.money)}{stringUtil.currency(1)}</span>:''}
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <span style={{fontSize:12}}>{stringUtil.language(1109)}:{this.state.currWholesaleInfoItem.operaterId==0?stringUtil.language(431):(this.state.currWholesaleInfoItem.operaterName?this.state.currWholesaleInfoItem.operaterName:'')}，{stringUtil.language(1122)}:{this.state.currWholesaleInfoItem.workerId==0?stringUtil.language(431):this.state.currWholesaleInfoItem.workerName}，{stringUtil.language(858)}{this.state.currWholesaleInfoItem.describe}</span>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <span style={{fontSize:12}}>总额：</span>
                  <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currWholesaleInfoItem.money)}</span>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                  <span style={arithUtil.stylesAdd(styles.myButtonSty,{width:90,height:25,color:'#52A056',fontSize:12,backgroundColor:'transparent',borderRadius:12.5,borderWidth:1,borderColor:'#52A056',marginRight:10,borderStyle:'dashed'})} onClick={()=>{
                      this.setState({infoShowPop:false},()=>{
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WholesaleOutActivity',mainStacks:[
                          {title:stringUtil.language(1128),navigatePage:''},
                          {title:stringUtil.language(1045),navigatePage:'WholesaleOutActivity',paramsPage:{wholesaleInfoItemInfo:this.state.currWholesaleInfoItem}}
                        ]}});
                      });
                    }}><i className="iconfontIndexCss" style={{fontSize:12,color:'#52A056'}}>&#xe867;</i> {stringUtil.language(1045)}</span>
                  {otherUtil.authorityFindCode("020402")!=null?<span style={arithUtil.stylesAdd(styles.myButtonSty,{width:90,height:25,color:'#52A056',fontSize:12,backgroundColor:'transparent',borderRadius:12.5,borderWidth:1,borderColor:'#52A056',borderStyle:'dashed'})} onClick={()=>{
                      this.setState({infoShowPop:false},()=>{
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BackAddWholesaleActivity',mainStacks:[
                          {title:stringUtil.language(1128),navigatePage:''},
                          {title:stringUtil.language(825),navigatePage:'BackAddWholesaleActivity',paramsPage:{wholesaleInfoItemInfo:this.state.currWholesaleInfoItem}}
                        ]}});
                      });
                    }}><i className="iconfontIndexCss" style={{fontSize:12,color:'#52A056'}}>&#xe656;</i> {stringUtil.language(1163)}</span>:<div/>}
              </div>
            </div>
        </div>}closeBnTopEvents={() => {this.setState({ infoShowPop: false});}}/>
        <MaskModal title={stringUtil.language(1009)+'（NO.'+this.state.currWholesaleInfoItem.wholesaleNo+'）'} visible={this.state.collectionShowPop} width={arithUtil.pxToDp(750)} height={arithUtil.ProportionTo(document.body.clientHeight,0.7)}
            content={<div style={{width:'100%',height:'100%'}}>
            <div style={{width:'100%',height:'15%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',backgroundColor:'#E3FAE3'}}>
              <span style={{fontSize:16,fontWeight:'bold'}}>&nbsp;&nbsp;&nbsp;{stringUtil.language(903)}{this.state.currWholesaleInfoItem.customerName}</span>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                {(()=>{
                    this.state.wCollectTotalInfo = {wCollectTotal:0,wDiscountTotal:0,wNoCollectTotal:0};
                    if(this.state.currWholesaleInfoItem.wholesaleCollectDOs!=null&&this.state.currWholesaleInfoItem.wholesaleCollectDOs.length>0){
                        for(let i=0;i<this.state.currWholesaleInfoItem.wholesaleCollectDOs.length;i++){
                            this.state.wCollectTotalInfo.wCollectTotal = (this.state.wCollectTotalInfo.wCollectTotal).add(this.state.currWholesaleInfoItem.wholesaleCollectDOs[i].collectMoney);
                            this.state.wCollectTotalInfo.wDiscountTotal = (this.state.wCollectTotalInfo.wDiscountTotal).add(this.state.currWholesaleInfoItem.wholesaleCollectDOs[i].discountMoney);
                        }
                    }
                    this.state.wCollectTotalInfo.wNoCollectTotal = this.state.currWholesaleInfoItem.money-this.state.wCollectTotalInfo.wCollectTotal-this.state.wCollectTotalInfo.wDiscountTotal;
                })()}
                <span>{stringUtil.language(1080)} </span><span style={{color:'#52A056',fontSize:30,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wCollectTotalInfo.wCollectTotal)}</span>
                <span>{stringUtil.language(906)} </span><span style={{color:'#D2855D',fontSize:30,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wCollectTotalInfo.wDiscountTotal)}</span>
                <span>，{stringUtil.language(1143)} </span><span style={{color:'#F00',fontSize:30,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wCollectTotalInfo.wNoCollectTotal)}&nbsp;&nbsp;&nbsp;</span>
              </div>
            </div>
            <div style={{height:'85%',overflow:'auto'}}>
              {this.state.currWholesaleInfoItem.wholesaleCollectDOs!=null?this.state.currWholesaleInfoItem.wholesaleCollectDOs.map((item,key)=>{
                return(<div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',paddingRight:10,borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,cursor:'pointer'}} key={key} onClick={()=>{
                  stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL+'/'+item.customerCollectId,null,['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        this.setState({currCustomerCollectItem:successResponse.data,collectInfoShowPop:!this.state.collectInfoShowPop});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }}><span style={{width:'15%',fontSize:14,textAlign:'center'}}>Sk.{item.customerCollectNo}</span>
                  <span style={{width:'23%',fontSize:14,textAlign:'center'}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                  <span style={{width:'22%',fontSize:16}}>{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,10):''):stringUtil.subStr(item.targetName,10)}</span>
                  <span style={{width:'18%',fontSize:16}}>{item.accountName}</span>
                  <div style={{width:'22%',display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                    <span>{stringUtil.language(1080)} </span><span style={{color:'#52A056',fontWeight:'bold'}}>{arithUtil.fenToYuan(item.collectMoney)}</span>
                    <span>{stringUtil.language(906)} </span><span style={{color:'#D2855D',fontWeight:'bold'}}>{arithUtil.fenToYuan(item.discountMoney)}</span>
                  </div>
                </div>)
              }):<div/>}
            </div>
        </div>}closeBnTopEvents={() => {this.setState({ collectionShowPop: false, });}}/>
        {this.state.currCustomerCollectItem?<MaskModal title={stringUtil.language(697)} visible={this.state.collectInfoShowPop} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.7)}
                content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column'}}>
                <div style={{paddingLeft:10,paddingRight:10}}>
                    <div style={{width:'100%',height:195,position:'absolute',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><i className="iconfontIndexCss" style={{fontSize:150,color:'rgba(169,169,169,0.3)'}}>&#xe609;</i></div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(669)}：</span><span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.currCustomerCollectItem.time),'Y-M-D h:m:s')}</span>
                    </div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(670)}：</span><span>{this.state.currCustomerCollectItem.workerId==0?stringUtil.language(431):this.state.currCustomerCollectItem.workerName}</span>
                    </div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(671)}：</span><span style={{color:'#F00'}}>SK.{this.state.currCustomerCollectItem.customerCollectNo}</span>
                    </div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(672)}：</span><span>{this.state.currCustomerCollectItem.customerName}</span>
                    </div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(673)}：</span><span>{this.state.currCustomerCollectItem.accountName}</span>
                    </div>
                    <div style={{width:'100%',height:45,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(674)}：</span><div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.currCustomerCollectItem.collectMoney).add(this.state.currCustomerCollectItem.discountMoney))}</span><span>{stringUtil.currency(1)}</span>
                            </div>
                            <span style={{color:'#A9A9A9',fontSize:10}}>{stringUtil.language(1080)}{arithUtil.fenToYuan(this.state.currCustomerCollectItem.collectMoney)}{stringUtil.currency(1)}{stringUtil.language(906)}{arithUtil.fenToYuan(this.state.currCustomerCollectItem.discountMoney)}{stringUtil.currency(1)}</span>
                        </div>
                    </div>
                </div>
                <div style={{height:30,backgroundColor:'#7CF5B9',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'25%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(471)}</span>
                    <span style={{width:'37%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1049)}</span>
                    <span style={{width:'21%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1080)}</span>
                    <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1079)}</span>
                </div>
                <div style={{width:'100%',display:'flex',flexGrow:1,flexDirection:'column',overflow:'auto'}}>
                    {this.state.currCustomerCollectItem.collects!=null?this.state.currCustomerCollectItem.collects.map((item,key)=>{
                        return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'25%',color:item.collectMode==0?'#007ACC':'#696969',fontSize:12,textAlign:'center'}}>{item.collectMode==0?stringUtil.language(913):'No.'+item.wholesaleNo}</span>
                            <span style={{width:'37%',fontSize:12}}>{item.targetType==1?stringUtil.language(426):item.targetName}</span>
                            <span style={{width:'21%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.collectMoney)}</span>
                            <span style={{width:'17%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.discountMoney)}</span>
                        </div>)
                    }):<div/>}
                </div>
                <div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span style={{fontSize:12,paddingLeft:5}}>{stringUtil.language(858)}{this.state.currCustomerCollectItem.describe}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {otherUtil.authorityFindCode("030104")!=null?<span style={{width:95,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#F00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({collectInfoShowPop:false,delCollectShowPop:!this.state.delCollectShowPop});
                        }}><i className="iconfontIndexCss" style={{fontSize:15,color:'#FFF'}}>&#xe612;</i> {stringUtil.language(1113)}</span>:''}
                    </div>
                </div>
            </div>} closeBnTopEvents={() => {this.setState({ collectInfoShowPop: false, });}}/>:<div/>}
        <MaskModal visible={this.state.delCollectShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                {stringUtil.language(73)}
            </div>}
            okBnEvents={(event) => {
                this.state.collectionShowPop=false;
                this.state.delCollectShowPop=false;
                stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL+'/'+this.state.currCustomerCollectItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.getDataList();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delCollectShowPop: false})}}/>
        <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {this.state.currWholesaleInfoItem.isHaveCollectChenpinglong?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span>{stringUtil.language(20)}</span>
                            <span>{stringUtil.language(21)}</span>
                        </div>:<span>{stringUtil.language(81)}</span>}
                </div>}
            okBnEvents={(event) => {
                this.state.delShowPop=false;this.state.infoShowPop=false;
                if(this.state.currWholesaleInfoItem.isHaveCollectChenpinglong){
                    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_COLLECT_URL+'/'+this.state.currWholesaleInfoItem.id,null,['key','get',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.state.currWholesaleInfoItem.wholesaleCollectDOs = successResponse.data;
                            this.setState({collectionShowPop:true});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }else{
                    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+this.state.currWholesaleInfoItem.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.getDataList();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }
            }} okBnTitle={this.state.currWholesaleInfoItem.isHaveCollectChenpinglong?stringUtil.language(1009):stringUtil.language(1027)} okBnColor={this.state.currWholesaleInfoItem.isHaveCollectChenpinglong?'#52A056':'#ed554e'}
            closeBnEvents={() => {this.setState({delShowPop: false})}}/>
      </div>)
  }
}

const styles = {
  myButtonSty:{
    width:arithUtil.pxToDp(120),
    height:'30px',
    color:'#FFF',
    lineHeight:'30px',
    borderRadius:3,
    backgroundColor:'#52A056',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer'
  },
  time:{
    height:25,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    borderStyle:'solid',
    borderWidth:1,
    borderColor:'#A9A9A9',
    borderRadius:3,
    paddingLeft:3,
    paddingRight:3
  },
};